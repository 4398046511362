import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TextEncoder } from 'text-encoding';

import { AppModule } from './app/app.module';
import { RemoteLoggingService } from './app/core/services/remote-logging.service';
import { MaybeLoadLocaleProvidersFromQuerymap } from './app/core/services/language-detection.service';

/** load configuration and startup the application */
function loadConfig() {
  const http = new XMLHttpRequest();
  http.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      startApp(JSON.parse(this.responseText));
    }
  };

  http.open('GET', 'assets/environments/environment-config.' + window.location.hostname.split('.')[0] + '.json', true);
  http.send();
}

function IECheck(): boolean {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const ie11 = ua.indexOf('Trident');
  return msie > 0 || ie11 > 0;
}

if (typeof (window as any).TextEncoder === 'undefined') {
  (window as any).TextEncoder = TextEncoder;
}

/** start the application, including locale providers if possible */
function startApp(config) {
  if (!IECheck()) {
    if (config && config.environment !== 'developer') {
      enableProdMode();
    }
  const injector = Injector.create({
    providers: [{ deps: [ Injector ], provide: RemoteLoggingService, useClass: RemoteLoggingService }]
  });
  const logger = injector.get(RemoteLoggingService);

  platformBrowserDynamic([{
     provide: 'appConfig',
     useValue: config },
    ]).bootstrapModule(AppModule, {
      // Enable ?lang={LOCAL_ID} to override browser config, otherwise autodetect
      // providers: MaybeLoadLocaleProvidersFromQuerymap(logger)
    })
    .catch(err => logger.logError(err));
  }
}

/** @ignore */
loadConfig();
