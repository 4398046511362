import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { ProjectAlphaModule } from '../public/project-alpha/project-alpha.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HotelLeadsFormComponent } from './components/hotel-leads-form/hotel-leads-form.component';
import { HotelLeadsListComponent } from './components/hotel-leads-list/hotel-leads-list.component';
import { HotelLeadsRoutingModule } from './hotel-leads-routing.module';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [
    HotelLeadsFormComponent,
    HotelLeadsListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ProjectAlphaModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    HotelLeadsRoutingModule
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ]
})
export class HotelLeadsModule { }
