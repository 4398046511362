import { Injectable } from '@angular/core';

const DataLayerEvents: { eventName: string, eventData: any }[] = [
  {
    eventName: 'call-summarizer-generate-email',
    eventData: {
      event: 'event_customevent',
      mainCategory: 'call_summarizer',
      mainCategoryValue: 'generate_email',
      /* eslint-disable camelcase */
      event_name: 'generate_email'
    }
  },
  {
    eventName: 'call-summarizer-copy-clipboard',
    eventData: {
      event: 'event_customevent',
      mainCategory: 'call_summarizer',
      mainCategoryValue: 'copy_clipboard',
      /* eslint-disable camelcase */
      event_name: 'copy_clipboard'
    }
  }
]

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  
  constructor() { }

  triggerGAEvent(eventName: string) {
    if ((<any>window).dataLayer) {
      (<any>window).dataLayer.push(DataLayerEvents.find(event => event.eventName === eventName).eventData);
    }
    return DataLayerEvents.find(event => event.eventName === eventName)
  }
}
