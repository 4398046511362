<div id="app-mini-springboard" *ngIf="applications && applications.length > 0">
  <span id="heading">
    <span>
      <mat-icon id="heading-icon">apps</mat-icon>
      <span>Springboard</span>
    </span>
    <mat-icon class="toggle" *ngIf="springboardIsOpen" aria-hidden="false" (click)="collapseSpringboard()" (keydown.enter)="collapseSpringboard()">expand_less</mat-icon>
    <mat-icon class="toggle" *ngIf="!springboardIsOpen" aria-hidden="false" (click)="expandSpringboard()" (keydown.enter)="expandSpringboard()">expand_more</mat-icon>
  </span>
  <div id="app-container" [ngClass]="springboardIsOpen ? 'open' : ''">
    <app-mini-springboard-app *ngFor="let application of applications" [application]="application"
      (appSelected)="appSelectedHandler($event)"> </app-mini-springboard-app>
    <div id="loading" *ngIf="loading">
      <div class="la-ball-spin-clockwise-fade">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>