import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../public/project-alpha/authGuard';
import { ProjectAlphaComponent } from '../public/project-alpha/project-alpha.component';
import { EntitlementManagerDashboardComponent } from './components/entitlement-manager-dashboard/entitlement-manager-dashboard.component';

const routes: Routes = [
  {
    path: 'project-alpha',
    component: ProjectAlphaComponent,
    children: [
      {
        path: 'entitlement-manager',
        component: EntitlementManagerDashboardComponent,
        data: { capabilities: ['MOB - Entitlement Manager'] },
        canActivate: [AuthGuard]
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class EntitlementManagerRoutingModule { }
