import { Directive, Input, OnInit, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionsService } from '../services/permissions.service';

@Directive({
  selector: '[appCanAccess]'
})
export class CanAccessDirective implements OnInit {

  @Input() appCanAccess: any;

  constructor(private readonly templateRef: TemplateRef<any>, private readonly elementRef: ElementRef,
    private readonly viewContainer: ViewContainerRef,
    private readonly workflowEvents: PermissionsService) {
  }

  ngOnInit(): void {
    this.applyPermission(this.appCanAccess);
  }

  private applyPermission(value: string | string[]): void {
    this.workflowEvents.checkAuthorization(value)
      .then(authorized => {
        if (authorized) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

}
