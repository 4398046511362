/** MPI-91 - used by party-shared.service.ts */
import { Injectable } from '@angular/core';
import { Candidate, CandidateDuplicateParam, CandidateProfiles } from '../models/candidate';
import { BaseClientService } from './base-client.service';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RemoteLoggingService } from './remote-logging.service';
import { APIResponse, APIResponseDuplicate } from '../models/response.model';
import { LoggerService } from './logger.service';
import { urlType } from '../models/urlType';

@Injectable({
  providedIn: 'root'
})
export class CandidateProfilesService {
  /**
   * base constructor
   * @param baseClientService baseclient service
   * @param logSvc remote logging service
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService
  ) { }

  /**observable for subject */
  selectedValue$ = new Subject<string>();
  totalCount = new Subject<any>();
  totalCount$: Observable<any> = this.totalCount.asObservable();
  testSubject = new Subject<any>();
  testSubject$: Observable<any> = this.testSubject.asObservable();
  newCandidate: Candidate;

  /**variable to store the total cost after tax assistance is included */
  totalSum: number;

  /**
   * Return the candidate json list and loop to display in the table
   * @param queryString querystring values
   */
  getCandidateProfiles(queryString: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/candidate?${queryString}`)
      .pipe(
        map(r => r),
        catchError(err => {
          this.customLogger.error('Failed to get candidate profiles', err);
          const emptyArray: CandidateProfiles = null;
          return of(emptyArray);
        })
      );
  }

  getRoleCapabilities(partyId: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/getUserRoleCapabilities/${partyId}?return=rolesAndCaps&level=UI`, '', urlType.accessmgmt)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logger('', 'Failed to get candidate profiles', err);
          const emptyObj = {};
          return of(emptyObj);
        })
      );
  }

  /**
   * use to subscribe to selectedValue$
   */
  getMessage(): Observable<any> {
    return this.selectedValue$.asObservable();
  }
  /**
   * used to add a candidate
   * @param request candidate whose details are to be added
   */
  createCandidate(request: Candidate): Observable<APIResponse> {
    return this.baseClientService.post<APIResponse>('/candidate', request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        this.customLogger.error('Failed to add candidate details', err);
        return of(empty);
      })
    );
  }
  /**
   * used to update candidate details
   * @param request candidate whose details are to be updated
   */
  updateCandidate(request: Candidate): Observable<APIResponse> {
    return this.baseClientService
      .put<APIResponse>('/candidate', request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          return this.catchBlock('Failed to add candidate details', err, 'string');
        })
      );
  }

  /**
   * used to send email invite to candidate
   * @param request candidate to whom invite is to be sent
   */
  sendInviteToCandidate(request: Candidate): Observable<APIResponse> {
    return this.baseClientService
      .post<APIResponse>(`/candidate/email`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          return this.catchBlock('sending Invite to Candidate Failed', err, 'string');

        })
      );
  }
  /**
   * used to withdraw selected candidates
   * @param request array of candidates to be withdrawn
   */
  withdrawCandidates(clientContactId: string, request: string[]): Observable<string> {
    return this.baseClientService
      .put<string>(`/candidate/${clientContactId}`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: string = null;
          this.customLogger.error('Failed to withdraw candidate', err);
          return of(empty);
        })
      );
  }
  /**
   * used to resend email invite
   * @param request candidate for whom email is to be resent
   */
  reSendInviteToCandidate(request: Candidate): Observable<APIResponse> {
    return this.baseClientService
      .post<APIResponse>('/candidate/email/resend', request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          return this.catchBlock('Resending Invite to Candidate Failed', err, 'string');

        })
      );
  }

  duplicateCandidate(candidate: CandidateDuplicateParam): Observable<APIResponseDuplicate> {
    return this.baseClientService
      .post<APIResponseDuplicate>('/candidate/duplicate', candidate)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: APIResponseDuplicate = null;
          this.customLogger.error('Candidate with duplicate move-order request found', err);
          return of(empty);
        })
      );
  }

  getEncryptedCandidateId(candidateId: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/candidate/getEncryptedPartyId?partyId=${candidateId}`)
      .pipe(
        map(r => r),
        catchError(err => {
          this.customLogger.error('Failed to get candidate profiles', err);
          const emptyArray: CandidateProfiles = null;
          return of(emptyArray);
        })
      );
  }
  catchBlock(message: string, error: any, dataType: string) {
    let empty: any = null;
    switch (dataType) {
      case 'string': {
        empty = null;
        break;
      }
      case 'object': {
        empty = {};
        break;
      }
      case 'array': {
        empty = [];
        break;
      }
    }
    this.customLogger.error(message, error);
    return of(empty);
  }
}

