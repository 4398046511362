import { Component, OnInit, OnDestroy, Inject, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { RoleCapabilityService } from 'src/app/rolecaps/services/role-capability.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { CAP_SEARCH_TITLE, GET_CAPABILITY_DEFINITIONS_ERROR, OPERATION_OPTIONS } from 'src/app/rolecaps/models/constants';
import { CapabilityDefinition } from 'src/app/rolecaps/models/definition.model';
import { RoleCapability } from 'src/app/rolecaps/models/rolecapability.model';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-rolecaps-cap-search',
  templateUrl: './rolecaps-cap-search.component.html',
  styleUrls: ['./rolecaps-cap-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RolecapsCapSearchComponent implements OnInit, OnDestroy {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-cap-search';

  /** Current Role Capability */
  editRoleCap: RoleCapability;

  /** Component title */
  title = CAP_SEARCH_TITLE;

  /** capabilityTitle */
  capabilityTitle = '';

  /** Table data */
  ELEMENT_DATA: Array<CapabilityDefinition> = [];

  /** Found Capabilities datasource */
  filteredDataSource: MatTableDataSource<CapabilityDefinition>;

  /** Selected Capabilities datasource */
  selectedDataSource: MatTableDataSource<CapabilityDefinition>;

  /** Displayed table columns */
  displayedColumns: string[] = [
    // 'remove',
    'capabilityName',
    'description',
    'operation',
    'permission',
    'actions'
  ];

  /** Initial table sort by column */
  sortBy = 'capabilityName';

  /** Initial table sort direction */
  sortDir = 'asc';

  /** To sort the 'filtered table' columns */
  @ViewChild('filteredSort', { static: false }) set filteredSortOrder(filteredSort: MatSort) {
    if (filteredSort) {
      this.filteredDataSource.sort = filteredSort;
    }
  }

  /** To sort the 'selected table' columns */
  @ViewChild('selectedSort', { static: false }) set selectedSortOrder(selectedSort: MatSort) {
    if (selectedSort) {
      this.selectedDataSource.sort = selectedSort;
    }
  }

  /** Available Capabilities */
  availableCaps: CapabilityDefinition[] = [];

  /** Filtered Capabilities */
  filteredCaps: CapabilityDefinition[] = [];

  /** Selected Capabilities */
  selectedCaps: CapabilityDefinition[] = [];

  /** Selected Capabilities Count */
  selectedCapsCount = 0;

  /** Capability Permission options */
  permissionOptions = ['allow', 'deny'];

  /** Capability Operation options */
  operationOptions = OPERATION_OPTIONS;

  /** Capability options */
  capOptions: string[] = [];

  /** Filtered Capability options */
  filteredCapOptions: Observable<string[]>;

  /** Capability Search Input */
  search = new FormControl('');

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Processing */
  processing = false;

  /** Injecting dependencies */
  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialogRef: MatDialogRef<RolecapsCapSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data && this.data.editRoleCap) {
      this.editRoleCap = data.editRoleCap;
      this.capabilityTitle = this.editRoleCap.roleName;
    }
  }

  /** Init */
  ngOnInit() {
    this.loadCaps();
    this.filteredDataSource = new MatTableDataSource(this.filteredCaps);
    this.filteredDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.selectedDataSource = new MatTableDataSource(this.selectedCaps);
    this.selectedDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  /** Load Capability definitions */
  loadCaps() {
    this.processing = true;
    this.subscription.add(this.roleCapabilityService.getCapabilityDefinitions().subscribe({
      next: response => {
        if (response && response.status === 200) {
          this.availableCaps = response.body;
          if (this.editRoleCap && this.editRoleCap.capabilities) {
            this.availableCaps = this.availableCaps.filter(item =>
              this.editRoleCap.capabilities.find(cap => cap.name === item.capabilityName) === undefined
            );
          }
          this.capOptions = this.availableCaps.map((cap: CapabilityDefinition) => cap.capabilityName).sort();
          this.filteredCapOptions = this.search.valueChanges.pipe(startWith(''), map(value => this.filterCapOptions(value || '')));
        } else {
          this.notificationsService.flashNotification('danger', GET_CAPABILITY_DEFINITIONS_ERROR);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get capability definitions', err);
        this.notificationsService.flashNotification('danger', GET_CAPABILITY_DEFINITIONS_ERROR);
      },
      complete: () => {
        this.processing = false;
      }
    }));
  }

  /**
   * Sorting accessor
   * @param item Capability
   * @param property string
   */
  sortingDataAccessor(item: CapabilityDefinition, property: string) {
    if (item[property]) {
      return item[property].toLocaleLowerCase();
    } else {
      return '';
    }
  }

  /** Filter Available Capabilities */
  filterCaps(value: string): CapabilityDefinition[] {
    const filterValue = value.toLowerCase();
    return this.availableCaps.filter(cap => cap.capabilityName.toLowerCase().includes(filterValue));
  }

  /** Filter Capability Options */
  filterCapOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.capOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  /** Filter available capability options by search term */
  searchCaps() {
    const filterValue = this.search.value.toLowerCase();
    this.filteredCaps = this.availableCaps.filter(cap => filterValue !== '' && cap.capabilityName.toLowerCase().includes(filterValue));
    this.filteredDataSource.data = this.filteredCaps;
  }

  searchKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.searchCaps();
    }
  }

  resetSearch() {
    this.search.setValue('');
  }

  /** Update row permission value */
  onPermissionChanges(event: MatSelectChange, element: CapabilityDefinition) {
    element['permission'] = event.value;
  }

  /** Update row operation value */
  onOperationChanges(event: MatSelectChange, element: CapabilityDefinition) {
    element['operation'] = event.value;
  }

  /** Add capability to selected section */
  addCapToSelected(element) {
    this.selectedCaps.push(element);
    this.selectedCapsCount = this.selectedCaps.length;
    this.availableCaps.splice(this.availableCaps.findIndex(cap => cap.capabilityName === element.capabilityName), 1);
    this.filteredCaps.splice(this.filteredCaps.findIndex(cap => cap.capabilityName === element.capabilityName), 1);
    this.filteredDataSource.data = this.filteredCaps;
    this.selectedDataSource.data = this.selectedCaps;
  }

  /** Remove capability from selected section */
  removeCapFromSelected(element) {
    this.selectedCaps.splice(this.selectedCaps.findIndex(cap => cap.capabilityName === element.capabilityName), 1);
    this.selectedCapsCount = this.selectedCaps.length;
    delete element.operation;
    delete element.permission;
    this.availableCaps.push(element);
    const searchValue = this.search.value.toLowerCase();
    if (searchValue !== '' && element.capabilityName.toLowerCase().indexOf(searchValue) > -1) {
      this.filteredCaps.push(element);
    }
    this.filteredDataSource.data = this.filteredCaps;
    this.selectedDataSource.data = this.selectedCaps;
  }

  /** Update the Role Capability with newly added capabilities and close the dialog  */
  updateRoleCap() {
    const transformedCaps = this.selectedCaps.map(({ ...item }: any) => {
      item['name'] = item.capabilityName;
      delete item._id;
      delete item.application;
      delete item.capabilityName;
      delete item.createdAt;
      delete item.createdBy;
      delete item.definitionType;
      delete item.updatedAt;
      delete item.updatedBy;
      delete item.__v;
      return item;
    });
    this.editRoleCap.capabilities.push(...transformedCaps);
    this.dialogRef.close(true);
  }

  /** Closes the dialog without changes */
  cancel() {
    this.dialogRef.close(false);
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
