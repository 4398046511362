import { Component, OnInit } from '@angular/core';
import {LoggerService} from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';

/** Base component for Settings Component */
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  /**
   * Initializes the value
   */
  constructor(private readonly Logger: LoggerService,
  private readonly loggedInUserService: UserContextService
  ) { }

  /**
   * It is for Initializing during Page Load
   */
  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.userId.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-SETTINGS', 'SETTINGS');
    });

  }
  onNoClick() {}
  save() {}
}
