import { Component, HostBinding, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { RoleCapabilityService } from '../../../services/role-capability.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { RolecapsAddEditRoleComponent } from '../rolecaps-add-edit-role/rolecaps-add-edit-role.component';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { RoleDefinition } from 'src/app/rolecaps/models/definition.model';
import { from, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';
import { GET_ROLE_DEFINITIONS_ERROR, REMOVE_ROLES_DIALOG_TITLE, REMOVE_ROLES_DIALOG_LINES, GET_DOES_ROLE_ASSOCIATION_ERROR,
         GET_ROLE_ASSOCIATION_ERROR, CONCURRENT_REQUESTS, REMOVE_ROLE_ERROR, REMOVE_ROLE_SUCCESS } from 'src/app/rolecaps/models/constants';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-rolecaps-roles-list',
  templateUrl: './rolecaps-roles-list.component.html',
  styleUrls: ['./rolecaps-roles-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RolecapsRolesListComponent implements OnInit, AfterContentChecked {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-roles-list';

  /** To check for readonly access */
  disable = false;

  /** Processing */
  processing = false;
  
  /** Property to indicate if the remove checkbox has selected  */
  disableRemoveCheckBoxes = false;

  /** Table data */
  ELEMENT_DATA: Array<RoleDefinition> = [];

  /** Table datasource */
  dataSource: MatTableDataSource<RoleDefinition> = new MatTableDataSource(this.ELEMENT_DATA);

  /** Displayed table columns */
  displayedColumns: string[] = [
    'remove',
    'friendlyName',
    'roleName',
    'dependencies',
    'bypassAssociation',
    'actions'
  ];

  /** Table Pagination */
  pageInfo = {
    itemCount: 0,
    currentPage: 0,
    perPage: 5,
    perPageOptions: [5, 10, 15]
  };

  /** Initial table sort by column */
  sortBy = 'friendlyName';

  /** Initial table sort direction */
  sortDir = 'asc';

  /** Search filter value */
  filterText = '';

  /** Selected Roles */
  selection = new SelectionModel<RoleDefinition>(true, []);

  /** Service subscription  */
  private readonly subscription: Subscription = new Subscription();

  /** Dialog Ref */
  dialogRef: MatDialogRef<any>;

  /** Injecting dependencies */
  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** To initialize the component */
  ngOnInit() {
    this.loadRoles();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  /** Load role data */
  loadRoles() {
    this.processing = true;
    this.roleCapabilityService.getRoleDefinitions().subscribe({
      next:response => {
        if (response && response.status === 204) {
          this.ELEMENT_DATA = [];
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        } else if (response && response.status === 200 && response.body) {
          this.ELEMENT_DATA = response.body;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        } else {
          this.notificationsService.flashNotification('danger', GET_ROLE_DEFINITIONS_ERROR);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get Roles', err);
        this.notificationsService.flashNotification('danger', GET_ROLE_DEFINITIONS_ERROR);
      },
      complete: () => {
        this.dataSource.paginator = this.paginator;
        this.pageInfo.itemCount = this.ELEMENT_DATA.length;
        this.dataSource.filterPredicate = this.customFilterPredicate;
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.processing = false;
      }
    });
  }

  /**
   * Table filtration
   * @param filterValue string
   */
  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.dataSource.filter = filterValue.trim();
    if (filterValue.trim().length === 0) {
      this.selection.clear();
      this.disableRemoveCheckBoxes = false;
    }
  }

  /**
   * Custom table column filtration
   * @param data RoleDefinition
   * @param filter string
   */
  customFilterPredicate(data: RoleDefinition, filter: string): boolean {
    const searchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const dataStr = `|${data.friendlyName}|${data.roleName}|`;
    return dataStr.search(new RegExp(searchTerm, 'gi')) !== -1;
  }

  /** Sorting accessor */
  sortingDataAccessor = (item: RoleDefinition, property: string) => {
    switch (property) {
      case 'dependencies': return item.dependencies ? item.dependencies.map(dep => dep.roleName).join('|') : '';
      case 'bypassAssociation': return item.hasOwnProperty('bypassAssociation') ? item.bypassAssociation.toString() : '';
      default: return item[property].toLocaleLowerCase();
    }
  };

  /**
   * Table Pagination
   * @param event PageEvent
   */
  onPagination(event: PageEvent) {
    this.pageInfo.perPage = event.pageSize;
  }

  /**
   * To check for readonly access
   * @param flag boolean
   */
  isDisabled(flag: boolean) {
    this.disable = flag;
  }

  /**
   * To clear the selection model
   */
  cancel() {
    this.selection.clear();
    this.disableRemoveCheckBoxes = false;
  }

  /**
   * Change event logic to select only one checkbox in remove column
   */
  selectRemoveCheckBox($event, dataSource) {
    if (this.selection.selected.length === 0) {
      this.disableRemoveCheckBoxes = true;
    } else {
    this.disableRemoveCheckBoxes = false;
    }
  }

  deleteDefinition() {
    // remove role definition document from the definitions collection
    this.processing = true;
    this.roleCapabilityService.deleteDefinition(this.selection.selected[0]._id).subscribe({
      next:response => {
        if (response && response.status === 204) {
          // get role capability by role name document from the rolecapabilities collection
          this.selection.clear();
          this.filterText = '';
          this.loadRoles();
          this.disableRemoveCheckBoxes = false;
          this.notificationsService.flashNotification('success', REMOVE_ROLE_SUCCESS);
        } else {
          this.notificationsService.flashNotification('danger', REMOVE_ROLE_ERROR);
        }
      },
      error: err => {
        this.loggerService.error('Failed to delete role', err);
        this.notificationsService.flashNotification('danger', REMOVE_ROLE_ERROR);
      },
      complete: () => {
        this.processing = false;
      }
    });
  }

  getRoleCapByRoleName() {
    // get role capability by role name document from the rolecapabilities collection
    this.processing = true;
    this.roleCapabilityService.getRoleCapByRoleName(`roleName=${this.selection.selected[0].roleName}`).subscribe(response => {
      if (response && response.status === 200 && response.body) {
        const requests = [];
        let allResults = [];
        const allErrors = [];
        requests.push(
          this.roleCapabilityService.deleteDefinition(this.selection.selected[0]._id),
          this.roleCapabilityService.deleteRoleCapabaility(response.body._id)
        );
        from(requests)
        .pipe(mergeMap(observable => observable, CONCURRENT_REQUESTS), tap(() => {}))
        .subscribe({
          next: partialResults => {
            allResults = allResults.concat(partialResults);
          },
          error: error => {
            this.loggerService.error('Failed to delete role', error);
            this.notificationsService.flashNotification('danger', REMOVE_ROLE_ERROR);
          },
          complete: () => {
            allResults.forEach(result => {
              if (result && result.status !== 204) {
                allErrors.push(result);
              }
            });
            if (allErrors.length === 0) {
              this.filterText = '';
              this.selection.clear();
              this.loadRoles();
              this.disableRemoveCheckBoxes = false;
              this.notificationsService.flashNotification('success', REMOVE_ROLE_SUCCESS);
            } else {
              this.notificationsService.flashNotification('danger', REMOVE_ROLE_ERROR);
            }
            this.processing = false;
          }
        });
      } else {
        this.deleteDefinition();
      }
    });
  }

  /**
   * Remove Role(s) confirmation
   */
  openRemoveRolesConfirm() {
    this.processing = true;
    // does role association exist or not using get method
    this.roleCapabilityService.doesRoleAssociationExist(`name=${this.selection.selected[0].roleName}`).subscribe({
      next:response => {
        if (response && response.status === 200 && response.body === true) {
          this.notificationsService.flashNotification('danger', GET_ROLE_ASSOCIATION_ERROR);
        } else if (response && response.status === 200 && response.body === false) {
          const dialogLines = [...REMOVE_ROLES_DIALOG_LINES];
          dialogLines.push(this.selection.selected.map(item => item.friendlyName).join('<br />'));
          this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            panelClass: 'dialogMainContainer',
            data: { dialogTitle: REMOVE_ROLES_DIALOG_TITLE, dialogLines },
            autoFocus: false,
            restoreFocus: false
          });
          this.subscription.add(
            this.dialogRef.afterClosed().subscribe((result: boolean) => {
              if (result) {
                // TODO - remove roles
                // get role capability by role name document from the rolecapabilities collection
                this.getRoleCapByRoleName();
              }
            })
          );
        } else {
          this.notificationsService.flashNotification('danger', GET_DOES_ROLE_ASSOCIATION_ERROR);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get Roles Associates', err);
        this.notificationsService.flashNotification('danger', GET_DOES_ROLE_ASSOCIATION_ERROR);
      },
      complete: () => {
        this.processing = false;
      }
    });
  }

  /**
   * Add Role Dialog
   */
  openAddRoleDialog() {
    this.dialogRef = this.dialog.open(RolecapsAddEditRoleComponent, {
      disableClose: true,
      panelClass: 'dialog-main',
      data: {},
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((role: RoleDefinition) => {
        if (role) {
          this.filterText = '';
          this.selection.clear();
          this.disableRemoveCheckBoxes = false;
          this.loadRoles();
        }
      })
    );
  }

  /**
   * Edit Role Dialog
   */
  openEditRoleDialog(role: RoleDefinition) {
    this.dialogRef = this.dialog.open(RolecapsAddEditRoleComponent, {
      disableClose: true,
      panelClass: 'dialog-main',
      data: { role },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((updatedRole: RoleDefinition) => {
        if (updatedRole) {
          this.filterText = '';
          this.selection.clear();
          this.disableRemoveCheckBoxes = false;
          this.loadRoles();
        }
      })
    );
  }

}
