import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { CandidateProfilesService } from '../../services/candidate-profiles.service';
import { ClientContactService } from '../../services/client-contact.service';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';
import { Candidate } from '../../models/candidate';
import { Client } from '../../models/client';
import { ClientContact } from '../../models/client-contact';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddClientContactComponent } from '../../../public/project-alpha/client-contact/add-client-contact/add-client-contact.component';

/**class for header status component */
@Component({
  selector: 'app-header-status',
  templateUrl: './header-status.component.html',
  styleUrls: ['./header-status.component.scss']
})
export class HeaderStatusComponent implements OnInit, OnDestroy {
  /**receive disable value based on role*/
  @Input() disable: any;
  @Input() selectedClient: Client;
  /**event emitter to emit candidate data */
  @Output() event = new EventEmitter<Candidate>();
  /**event emitter to emit client data */
  @Output() clientEvent = new EventEmitter<any>();
  /**event emitter to emit client data */
  @Output() clientContactEvent = new EventEmitter<any>();
  /**event emitter to call updateDataSource in CostModel component */
  @Output() costModelEvent = new EventEmitter<any>();
  /**variables to store activeCandidates */
  activeCount: number;
  /**variables to store activeCandidates */
  currentClientCount: number;
  /**variable to store type */
  public type: string;
  /**variable to storre label */
  label: string;
  /**flag to load the template */
  isLoaded = false;
  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**store disableButton value */
  disableButton = false;
  /**
   * Heading to be focused first
   */
  @ViewChild('pageheading', {static : false}) pageheading: ElementRef;
  /**
   * injecting dependencies
   * @param candidateProfilesService object to access candidateProfilesService
   * @param dialog Matdialog object
   * @param notificationsService object to access notificationService
   */
  constructor(
    private readonly candidateProfilesService: CandidateProfilesService,
    private readonly clientContactService: ClientContactService,
    public dialog: MatDialog,
    private readonly notificationsService: NotificationsService,
  ) { }

  /**Initialising the component */
  ngOnInit() {
    this.subscription.add(
      this.candidateProfilesService.totalCount$.subscribe(result => {
        if (!this.label) {
          setTimeout(() => {
            this.pageheading.nativeElement.focus();
          }, 2000);
        }
        this.activeCount = result.length;
        this.type = result.type;
        this.label = result.label;
        this.isLoaded = true;
      })
    );
    this.candidateProfilesService.testSubject$.subscribe(result => {
      this.activeCount = result.length;
      this.type = result.type;
      this.label = result.label;
      this.isLoaded = true;
    });
  }

  openDialogClientContact(evt: MouseEvent): void {
    evt.preventDefault();
    const dialogRef = this.dialog.open(AddClientContactComponent, {
      panelClass: 'dialogMainContainer',
      disableClose: true,
      data: {
        selectedClient: this.selectedClient,
        disableButton: this.disable
      }
    });

    this.subscription.add(dialogRef.afterClosed().subscribe((clientContact: ClientContact) => {
      const statusDate = this.formatDate(clientContact.statusDate !== undefined ?
        clientContact.statusDate : new Date());
      if (clientContact.executedFunctionType === 'Create') {
        clientContact.statusDate = statusDate;
        this.clientContactEvent.emit(clientContact);
        this.notificationsService.flashNotification(
          'success',
          'Added Client Contact Successfully.',
          true,
          'dismiss'
        );
      } else if (clientContact.executedFunctionType === 'Invite') {
        clientContact.statusDate = statusDate !== undefined ? statusDate : new Date().toISOString().substring(0, 10);
        this.clientContactEvent.emit(clientContact);
        console.log('After send invite');
        this.notificationsService.confirmNotification('Email');
      } else {
        clientContact.executedFunctionType = 'Create';
        clientContact.statusDate = statusDate !== undefined ? statusDate : new Date().toISOString().substring(0, 10);
        this.clientContactEvent.emit(clientContact);
        this.notificationsService.flashNotification(
          'success',
          'Saved Client Contact Successfully.',
          true,
          'dismiss'
        );
      }
    }));
  }

  formatDate(date) {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const monthVal = (newDate.getMonth() + 1);
    const month = `0${monthVal}`.slice(-2);
    const dateVal = (newDate.getDate());
    const dateStr = `0${dateVal}`.slice(-2);
    return `${year}-${month}-${dateStr}`;
  }

  isDisabled(flag) {
    this.disableButton = flag;
  }
  /** destroy the subscriptions */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
