import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClientService } from '../../../app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from '../../../app/core/services/remote-logging.service';
import { urlType } from '../models/urlType';
import { BrowserStorageService } from './browser-storage.service';

/** user service to post details of login to the server
 * header
 */
@Injectable({
    providedIn: 'root'
})
export class UserContextService {
    /**
     * base constructor
     */
    constructor(
        protected baseClient: BaseClientService,
        private readonly logSvc: RemoteLoggingService,
        private readonly browserStorageService: BrowserStorageService
    ) { }

    getLoggedInUserDetails(): Observable<any> {
        const user = JSON.parse(this.browserStorageService.getSessionStorageValue('UserContext'));
        const partyId = this.browserStorageService.getSessionStorageValue('car-ses-con');
        const details = {
            name: user ? user.name : null,
            userId: partyId ? partyId : null
        };
        if (details['name'] || details['userId']) {
            return of(details);
        } else {
            return this.baseClient.getById<any>('/user/context', '', urlType.accessmgmt).pipe(
                map(r => r.body),
                catchError((err, source) => {
                    const empty: any = null;
                    this.logSvc.logError(err);
                    return of(empty);
                })
            );
        }
    }

    getLoggedInUserInformation(): Observable<any> {
        const partyId = this.browserStorageService.getSessionStorageValue('car-ses-con');
        console.warn(partyId);
        return this.baseClient.getById<any>(`/userProfile/${partyId}`, '', urlType.accessmgmt).pipe(
            map(r => r.body),
            catchError((err, source) => {
                const empty: any = null;
                this.logSvc.logError(err);
                return of(empty);
            })
        );
    }

}
