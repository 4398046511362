import { Component, OnDestroy, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Team } from '../../models/teams.model';
import { AddTeamComponent } from '../add-team/add-team.component';

@Component({
  selector: 'app-teams-header',
  templateUrl: './teams-header.component.html',
  styleUrls: ['./teams-header.component.scss']
})
export class TeamsHeaderComponent implements OnDestroy {

  @Input() loaded: boolean;
  @Input() showAddIcon: boolean;
  @Input() showItemCount: boolean;
  @Input() heading: string;
  @Input() itemCount: string;
  @Input() subHeading: string;

  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Dialog Ref */
  dialogRef: MatDialogRef<AddTeamComponent>;

  /** To check for readonly access */
  disable = false;

  /** injecting dependencies */
  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
  ) { }

  /** Open Add Team dialog */
  openAddTeamDialog(): void {
    this.dialogRef = this.dialog.open(AddTeamComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: {},
      autoFocus: false,
      restoreFocus: false,
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((team: Team) => {
        if (team) {
          this.router.navigate(['project-alpha/teams', team.id], { state: team });
        }
      })
    );
  }

  /** To check for readonly access */
  isDisabled(flag) {
    this.disable = flag;
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
