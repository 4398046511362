<div id="app-menu" #menu [class.open]="menuIsOpen">
  <div id="header">
    <mat-icon class="close-button" aria-hidden="false" (click)="toggleMenu()" (keydown.enter)="toggleMenu()">close</mat-icon>
  </div>
  <div id="content">
    <app-mini-springboard></app-mini-springboard>
    <ng-container *ngFor="let item of menuItems; let index = index">
      <app-menu-item [item]="item" (itemSelected)=onItemSelected($event)></app-menu-item>
    </ng-container>
    <ng-container *ngIf="loading === false && menuItems && menuItems.length === 0">
      <p>There are no menu items available</p>
    </ng-container>
    <div id="loading" *ngIf="loading">
      <div class="la-ball-spin-clockwise-fade la-dark">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <div id="footer"></div>
</div>