import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../../core/services/permissions.service';
import { AppConfigService } from '../../core/services/app-config.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private readonly router: Router,
    private readonly permissionService: PermissionsService,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      return new Promise((resolve, reject) => {
        if (!this.appConfig.getConfig('byPassAuthorization')) {
          if (!!route && !!route.data && !!route.data['capabilities']) {
            this.permissionService.checkAuthorization(route.data['capabilities']).then(resp => {
              if (resp) {
                resolve(true);
              } else {
                this.router.navigate(['/contact-us']);
                resolve(false);
              }
            });
          } else {
            resolve(true);
          }
        } else {
          resolve(true);
        }
    });
  }
}
