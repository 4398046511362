<ng-template [appReadonly]="['Manage User Alignment']" (valueChange)="isDisabled($event)"></ng-template>
<ng-container>
  <table class="table" mat-table [dataSource]="tableDataSource" [class.transparent-bg]="markDisabled">
    <ng-container *ngFor="let column of tableColumns" [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef>
        {{column.name}}
      </th>
      <td mat-cell *matCellDef="let element">
        {{element | columnDataKey: column.dataKey}}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>
