import { Component, Input, EventEmitter, Output, HostBinding } from '@angular/core';
import { SpringboardApp } from '../../../models/SpringboardApps.model';

@Component({
  selector: 'app-mini-springboard-app',
  templateUrl: './mini-springboard-app.component.html',
  styleUrls: [
    './mini-springboard-app.component.scss'
  ]
})
export class MiniSpringboardAppComponent {

  /** Component css class */
  @HostBinding('class') class = 'app-mini-springboard-app';

  @Input() application: SpringboardApp;
  @Output() appSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  /**
   * Emit the selected app to the parent component to be handled
   */
  selectApp(): void {
    this.appSelected.emit(this.application.name);
  }

}
