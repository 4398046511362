<div class="sb-card" *ngIf="application" (click)="selectApp()" (keydown.enter)="selectApp()">
  <span id="mobilifyHR-logo" class="sb-logo" *ngIf="application.name === 'mobilifyHR'"></span>
  <span id="benefitsBuilder-logo" class="sb-logo" *ngIf="application.name === 'benefitsBuilder'"></span>
  <span id="movePro360-logo" class="sb-logo" *ngIf="application.name === 'movePro360'"></span>
  <span id="supplierPortal-logo" class="sb-logo" *ngIf="application.name === 'supplierPortal'"></span>
  <span id="cartusOnline-logo" class="sb-logo" *ngIf="application.name === 'cartusOnline'"></span>
  <span id="compensationServices-logo" class="sb-logo" *ngIf="application.name === 'compensationServices'"></span>
  <span id="atlas-logo" class="sb-logo" *ngIf="application.name === 'atlas'"></span>
  <span id="atlasReporting-logo" class="sb-logo" *ngIf="application.name === 'atlasReporting'"></span>
  <h1>{{ application.displayName }}</h1>
</div>
