import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { TableColumn } from '../../models/table.model'

@Component({
  selector: 'app-user-alignment-table',
  templateUrl: './user-alignment-table.component.html',
  styleUrls: ['./user-alignment-table.component.scss']
})
export class UserAlignmentTableComponent implements OnInit {
  /** Table data source */
  public tableDataSource: MatTableDataSource<any[]> = new MatTableDataSource(<any[]>[])

  /** displayedColumns variable to store the displayed columns */
  public displayedColumns: string[]

  @Input() tableColumns: any[] = []
  @Input() markDisabled = false
  @Input() set tableData(data: any[]) { this.setTableDataSource(data) }
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>()

  /** To check for readonly access */
  disable = false
  
  constructor() { }

  /** Component initialization */
  ngOnInit(): void {
    this.displayedColumns = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.name)
  }

  /**
   * Sets the table data source
   * @param data table data
   */
  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data)
  }

    /**
   * To check for readonly access
   * @param flag boolean
   */
    isDisabled(flag) {
      this.disable = flag
    }

}
