import { Component, OnInit, ViewChild } from '@angular/core';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { apiErrorMessage } from '../../models/constants';
import { Team, SearchTerms } from '../../models/teams.model';
import { TeamSearchComponent } from '../team-search/team-search.component';
import * as querystring from 'querystring';
import { BrowserStorageService } from 'src/app/core/services/browser-storage.service';

export const TEAMS_LIST_PAGINATION_SESSION_STORAGE_KEY = "teams-list-pagination-size";

@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.scss']
})

export class TeamsListComponent implements OnInit {

  /** displayed table columns */
  displayedColumns: string[] = [
    'teamName',
    'teamOwner',
    'teamStatus'
  ];

  /** table data */
  ELEMENT_DATA = [];

  /** table datasource */
  dataSource: MatTableDataSource<Team> = new MatTableDataSource(this.ELEMENT_DATA);

  /** filter value */
  filterText = '';

  /** sort by */
  sortBy = 'teamName';

  /** sorting direction */
  sortDir = 'asc';

  /** announce sort statement for accessibility */
  sortStatement = '';

  /** service subscription  */
  private readonly subscription: Subscription = new Subscription();

  /** pagination */
  pageInfo = {
    itemCount: 0,
    currentPage: 0,
    perPage: 5,
    perPageOptions: [5, 10, 15]
  };

  /** display header after load */
  loaded = false;

  /** header show add button */
  showAddIcon = true;

  /** header show count */
  showItemCount = true;

  /** header heading */
  heading = 'Teams';

  /** header subheading */
  subHeading = 'Teams';

  /** header teams count */
  itemCount = 0;

  /** Search Terms */
  searchTerms: SearchTerms = {};

  /** Search Active */
  searchActive = false;

  /** Injecting dependencies */
  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly browserStorageService: BrowserStorageService,
  ) { }

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**To initialize the component */
  ngOnInit() {
    const sessionPagination = Number(this.browserStorageService.getSessionStorageValue(
      TEAMS_LIST_PAGINATION_SESSION_STORAGE_KEY
    ))
    this.pageInfo.perPage = sessionPagination > 0 ? sessionPagination : 5
    this.loadTeams(1, this.pageInfo.perPage);
  }

  /** Server side sort */
  sortData(event) {
    switch (event.direction) {
      case 'asc':
        this.sortStatement = `sorting ${event.active} in ascending order`;
        break;
      case 'desc':
        this.sortStatement = `sorting ${event.active} in descending order`;
        break;
      case '':
        this.sortStatement = `sorting ${event.active} in random order`;
        break;
    }
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'desc' : 'asc';
    this.loadTeams(this.pageInfo.currentPage + 1, this.pageInfo.perPage);
  }

  /** Load Table Data */
  loadTeams(page: number, perPage: number) {
    this.spinner.show();
    // const queryString = this.getQueryString(page, perPage, this.filterText ? 'or' : 'and'); // Enable this once implemented in the IL
    const queryString = this.getQueryString(page, perPage);
    this.teamsService.getTeams(queryString).subscribe({
      next: response => {
        if (response && response.status === 204) {
          this.ELEMENT_DATA = [];
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        } else if (response && response.status === 200 && response.body) {
          const paginationHeader = response.headers.get('X-Pagination');
          if (paginationHeader) {
            const pagination = JSON.parse(paginationHeader);
            this.pageInfo.itemCount = pagination.itemCount;
            this.pageInfo.currentPage = pagination.currentPage - 1;
          }
          response.body.forEach(ele => { if (ele.hasOwnProperty('_id')) { ele['id'] = ele._id; } });
          this.ELEMENT_DATA = response.body;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.loaded = true;
          this.itemCount = Number(this.pageInfo.itemCount);
          this.subHeading = this.itemCount === 1 ? 'Team' : 'Teams';
        } else {
          this.notificationsService.flashNotification('danger', apiErrorMessage);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get teams', err);
        this.notificationsService.flashNotification('danger', apiErrorMessage);
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  /** Table filtering */
  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    // if (this.filterText.length >= 3 || this.filterText === '') {
    //   this.searchActive = false;
    //   this.searchTerms = this.filterText === '' ? {} : {
    //     teamName: this.filterText,
    //     teamOwner: this.filterText,
    //     teamStatus: this.filterText
    //   };
    //   this.loadTeams(1, this.pageInfo.perPage);
    // }
  }


  /**
   * function called when page changed
   * @param event page event
   */
  onPagination(event: PageEvent) {
    this.pageInfo.perPage = event.pageSize;
    this.browserStorageService.setSessionStorageValue(
      TEAMS_LIST_PAGINATION_SESSION_STORAGE_KEY,
      this.pageInfo.perPage
    );
    this.loadTeams(event.pageIndex + 1, this.pageInfo.perPage);
  }

  /**
   * navigate to team details page
   * @param team team
   * */
  navigateToTeamDetails(team: Team) {
    this.router.navigate(['project-alpha/teams', team.id]);
  }

  /** Employee Search */
  openTeamSearchDialog(): void {
    const dialogRef = this.dialog.open(TeamSearchComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { searchTerms: this.searchActive ? this.searchTerms : {} },
      autoFocus: false,
      restoreFocus: false,
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((searchTerms: SearchTerms) => {
        if (searchTerms) {
          this.filterText = '';
          this.searchTerms = searchTerms;
          this.searchActive = Object.entries(this.searchTerms).filter(([k, v], i) => !!v).length > 0;
          this.loadTeams(1, this.pageInfo.perPage);
        }
      })
    );
  }

  /** Search Filter querystring */
  // getQueryString(page: number, perPage: number, searchMethod: string): string { // Enable this once implemented in the IL
  getQueryString(page: number, perPage: number): string {
    const qs = {};
    for (const [key, value] of Object.entries(this.searchTerms)) {
        if (value && !['client', 'employee', 'owner'].includes(key)) {
        qs[key] = value;
      }
    }
    // qs['searchMethod'] = searchMethod; // Enable this once implemented in the IL
    qs['sortField'] = this.sortBy;
    qs['sortDirection'] = this.sortDir;
    qs['page'] = page;
    qs['perPage'] = perPage;
    return querystring.stringify(qs);
  }

}
