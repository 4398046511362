<div Fxflex="100%" class="active-header-status-container" *ngIf="isLoaded">
    <div Fxflex="100%" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" class="active-text-container">
            <h1 #pageheading class="active-txt">{{label}}:</h1>
            <div class="active-txtcount" aria-live="polite" aria-atomic="true">
                <span class="active-total-candidate">{{activeCount}}</span>
                <span class="active-total-candidate-txt">{{type}}</span>
            </div>
        </div>
        <div [ngSwitch]="type">
            <div fxLayoutAlign="end" fxLayoutAlign.xs="end" fxLayout="column" class="active-addicon"
                *ngSwitchCase="'Client Contacts'">
                <a href="#" (click)="openDialogClientContact($event)">
                    <span class="cdk-visually-hidden">Add Client Contact</span>
                    <mat-icon matPrefix id="add_client_contact">add_circle</mat-icon>
                </a>
            </div>
        </div>
    </div>
</div>