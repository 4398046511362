<div class="">
    <div class="settingsContainer" flexLayout fxLayout.xs="column">
        <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row"
            fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
            <div class="candidate-dialogtitle">{{ title }}</div>
            <a class="close-icon" (click)="onNoClick()">
                <mat-icon>close</mat-icon>
            </a>
        </div>
        <div class="middle-section">
            <mat-dialog-content class="withdraw-popup-content">
                <p> {{ displayText1 }}</p>
                <div *ngFor="let client of data.selectedClients">
                    <div class="candidate-name-highlightbg">{{client.lastName }}, {{ client.firstName }}</div>
                </div>
                <p> {{ displayText2 }}</p>
            </mat-dialog-content>
        </div>
        <mat-dialog-actions>
            <div class="statusBtn delete-dialog-btn">
                <button mat-button color="primary" class="mat-button text-button" (click)="onNoClick()">CANCEL</button>
                <button mat-button class="mat-button contained-button" (click)="inactivateClient()"
                    id="withdrawBtn">Inactivate
                    {{data.noOfSelected}} Client Contact</button>
            </div>
        </mat-dialog-actions>

    </div>
</div>