import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { ProjectAlphaRoutingModule } from './project-alpha-routing.module';
import { ProjectAlphaComponent } from './project-alpha.component';
import { SettingsComponent } from './settings/settings.component';
import { HighlightSearchPipe } from './highlight-search.pipe';
import { ServicesComponent } from './services/services.component';
import { DuplicateCandidateComponent } from './candidate-profile/duplicate-candidate/duplicate-candidate.component';
import { HeaderStatusComponent } from '../../core/components/header-status/header-status.component';
import { InvitationSentComponent } from './candidate-profile/invitation-sent/invitation-sent.component';
import { CandidateContactInfoComponent } from '../../core/components/candidate-contact-info/candidate-contact-info.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CanAccessDirective } from './../../core/directives/can-access.directive';
import { ClientContactComponent } from './client-contact/client-contact.component';
import { AddClientContactComponent } from './client-contact/add-client-contact/add-client-contact.component';
import { ClientContactColumnsComponent } from './client-contact/client-contact-columns/client-contact-columns.component';
import { InactivateClientContactsComponent } from './client-contact/inactivate-client-contacts/inactivate-client-contacts.component';
import { CartusEmployeeComponent } from './cartus-employee/cartus-employee/cartus-employee.component';
import { AddRolesComponent } from './cartus-employee/cartus-employee/add-roles/add-roles.component';
import { EmployeeDetailsComponent } from './cartus-employee/cartus-employee/employee-details/employee-details.component';
import { FooterComponent } from '../../core/components/footer/footer.component';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { AccountManagerDetailsComponent } from './account-manager/account-manager-details/account-manager-details.component';
import { AddAccountManagerComponent } from './account-manager/add-account-manager/add-account-manager.component';
import { ReadonlyDirective } from '../../core/directives/readonly.directive';
import { TestComponent } from '../../core/directives/TestComponent';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { SensitiveInfoMaskComponent } from 'src/app/core/components/sensitive-info-mask/sensitive-info-mask.component';
import { ConsultantsComponent } from './consultants/consultants.component';
import { ConsultantDetailsComponent } from './consultants/consultant-details/consultant-details.component';
import { AddConsultantModalComponent } from './consultants/add-consultant-modal/add-consultant-modal.component';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';
import { ProgressSpinnerComponent } from 'src/app/core/components/progress-spinner/progress-spinner.component';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { CallSummarizerComponent } from './call-summarizer/call-summarizer.component';

@NgModule({
  declarations: [
    CanAccessDirective,
    ReadonlyDirective,
    TestComponent,
    ProjectAlphaComponent,
    SettingsComponent,
    HighlightSearchPipe,
    ServicesComponent,
    DuplicateCandidateComponent,
    InvitationSentComponent,
    CandidateContactInfoComponent,
    HeaderStatusComponent,
    ClientContactComponent,
    AddClientContactComponent,
    ClientContactColumnsComponent,
    InactivateClientContactsComponent,
    CartusEmployeeComponent,
    AddRolesComponent,
    EmployeeDetailsComponent,
    FooterComponent,
    AccountManagerComponent,
    AccountManagerDetailsComponent,
    AddAccountManagerComponent,
    AccountSettingsComponent,
    SensitiveInfoMaskComponent,
    ConsultantsComponent,
    ConsultantDetailsComponent,
    AddConsultantModalComponent,
    CustomSnackbarComponent,
    ConfirmDialogComponent,
    ProgressSpinnerComponent,
    CallSummarizerComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ProjectAlphaRoutingModule,
    NgxSpinnerModule
  ],
  providers: [
    OverlayService
  ],
  bootstrap: [
    SettingsComponent,
    DuplicateCandidateComponent,
    InvitationSentComponent,
    ClientContactComponent,
    InactivateClientContactsComponent,
    AddClientContactComponent,
    ClientContactColumnsComponent,
    AccountSettingsComponent,
    CustomSnackbarComponent,
    CallSummarizerComponent
  ],
  exports: [
    ReadonlyDirective,
    HighlightSearchPipe,
    ConfirmDialogComponent,
    ProgressSpinnerComponent
  ]
})
export class ProjectAlphaModule { }
