<ng-template [appReadonly]="['Manage Client Contacts']" (valueChange)="isDisabled($event)">
</ng-template>

<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>
<div class="content-wrapper-container">
  <div class="middle-section">
    <section>
      <span fxFlex="12.5em" fxLayoutAlign="end" fxFlex.xs="10.5em">
        <span fxFlex="12.5em" fxLayoutAlign="end" fxFlex.xs="10.5em">
          <mat-form-field>
            <mat-label>Company</mat-label>
            <mat-select id="level" [(value)]="selectedCompany" required (selectionChange)="getClientFromAPI($event)">
              <mat-option *ngFor="let client of clientList; let i = index" [value]="client.clientLegalName + '->' + i">
                {{client.clientLegalName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </span>
    </section>
    <br>
    <section>

      <app-header-status [selectedClient]="clientToBeSent" (clientContactEvent)="updateDataSource($event)"
        aria-live="assertive" aria-atomic="true" [disable]="disable"></app-header-status>
    </section>

    <section class="search_table">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span fxFlex="60%" fxLayoutAlign="start">
          <mat-form-field appearance="outline" class="search-within-table search-box">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput id="searchInput" (keyup)="applyFilter($event.target.value)"
              placeholder="Search within table for..." width="80%" fxFlex.sm="65">
          </mat-form-field>
          <span fxLayoutAlign="start center">
            <a id="selectColumns" (click)="openModal()">
              <mat-icon matTooltip="Select columns" matTooltipPosition="right" class="viewsetting-icon">view_column
              </mat-icon>
            </a>
          </span>
        </span>

      </div>
    </section>
    <p></p>
    <section class="content_table">
      <section class="mat-elevation-z8">
        <div style="overflow:auto;">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortDataCustom($event)"
            aria-describedby="Lists Client Contacts for a Client" matSortDisableClear>

            <!-- Select Checkbox Column -->
            <ng-container matColumnDef="select" sticky>
              <th mat-header-cell *matHeaderCellDef scope="col">
                <mat-checkbox (change)="$event && masterToggle()"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event && selection.toggle(row)"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Client Name Column -->
            <ng-container matColumnDef="lastName" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Full Name </th>
              <td mat-cell *matCellDef="let element" class="cLinks" (click)="open($event, element)">
                <span class="text-as-link"
                  [innerHTML]="element.lastName | highlightSearch: filterText">{{element.lastName}}</span>,
                <span class="text-as-link"
                  [innerHTML]="element.firstName | highlightSearch: filterText">{{element.firstName}}</span>
              </td>
            </ng-container>



            <!-- Billing Contact Name Column -->
            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Company Name</th>
              <td mat-cell *matCellDef="let element">
                <span
                  [innerHTML]="element.client.clientLegalName | highlightSearch: filterText">{{element.client.clientLegalName}}</span>
                <br>
                <span class="text-as-link subtitle">Client#:
                  <span [innerHTML]="element.client.clientID | highlightSearch: filterText">
                    {{element.client.clientID}}</span>
                </span>
              </td>
            </ng-container>


            <!-- Billing Contact Role Column -->
            <ng-container matColumnDef="roleName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Role</th>
              <td mat-cell *matCellDef="let element" [innerHTML]="element.roleName | highlightSearch: filterText">
                {{element.roleName}}</td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status" stickyEnd>
              <th mat-header-cell id="" *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element">
                <div class="notification-important">
                  <span *ngIf="element.status === 'Ready for Review'" (click)="open($event, element)">
                    <mat-icon *ngIf="element.status === 'Ready for Review'"> notification_important </mat-icon>
                    <a class="text-as-link"
                      [innerHTML]="element.status | highlightSearch: filterText">{{element.status}}</a>
                  </span>
                  <span *ngIf="element.status !== 'Ready for Review'"
                    [innerHTML]="element.status | highlightSearch: filterText">{{element.status}}
                  </span>
                </div>
                <span *ngIf="element.status === 'Ready for Review' && element.status !== 'Active'"
                  class="status-subtitle">
                  on
                  <span [innerHTML]="element.statusDate | highlightSearch: filterText"> {{element.statusDate}}</span>
                </span>
                <span *ngIf="element.status !== 'Ready for Review'  && element.status !== 'Active'" class="subtitle">
                  on
                  <span [innerHTML]="element.statusDate | highlightSearch: filterText"> {{element.statusDate}}</span>
                </span>
              </td>
            </ng-container>

            <!-- Delete Action Column -->
            <ng-container matColumnDef="action" stickyEnd>
              <th mat-header-cell *matHeaderCellDef scope="col">
                Delete
              </th>
              <td mat-cell *matCellDef="let row">
                <a id="deleteLink" (click)="deleteRow($event, row, true)">
                  <mat-icon matPrefix class="error">delete</mat-icon>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
      </section>
    </section>

    <div [hidden]="ELEMENT_DATA?.length>0 && dataSource.filteredData?.length>0" class="invalidData">
      No records found
    </div>
  </div>
  <div *ngIf="selection.selected.length>0" class="candidate-highlight-whitebg">
    <div class="statusBtn">
      <button mat-button class="mat-button contained-button" id="withdrawBtn" [disabled]="disable"
        (click)="inactivateClients(true)">
        {{this.inactivateButtonText}}<span class=" hide-xs cost-model-btn-txt"></span></button>
    </div>
  </div>
</div>