import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { UserContextService } from './user-context.service';
import { CandidateProfilesService } from './candidate-profiles.service';
import { BrowserStorageService } from './browser-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PartySharedService {

  /** Instance of BehaviorSubject of type String */
  partyId = new BehaviorSubject<string>(null);
  partyDetails;

  /** Instance of BehaviorSubject of type UserRoleCapabilities */
  userRoleCapabilities = new BehaviorSubject<any>(null);
  /** UserRoleCapabilities as Promise */
  userCapabilities;

  /**
   * Base Constructor
   */
  constructor(private readonly loggedInUserService: UserContextService,
    private readonly candidateProfilesService: CandidateProfilesService,
    private readonly browserStorageService: BrowserStorageService) {

  }

  updateData(data: string) {
    this.partyId.next(data);
  }

  /**
   * Function to Get party id and update Shared-Service
   */
  async getPartyId() {
    if (!this.partyId.value) {
      const response = await lastValueFrom(this.loggedInUserService.getLoggedInUserDetails());
      this.updateData(response.userId);
      return this.partyDetails = response.userId;
    } else {
      return this.partyDetails = this.partyId.value;
    }
  }

  /**
   * Function to Update the RoleCapabilities subject
   * @param data updated RoleCapabilities
   */
  updateRoleCapabilities(data: any) {
    this.userRoleCapabilities.next(data);
  }

  /**
  * Function to Get User Role Capabilities and update Shared-Service
  */
  async getRoleCapabilities(partyId: string) {
    if (!this.userRoleCapabilities.value) {
      const response = await lastValueFrom(this.candidateProfilesService.getRoleCapabilities(partyId));
      // this.browserStorageService.setSessionStorageValue('roles', response.partyRolesEncrypted);
      const decryptedData = this.getDecryptedData(response);
      this.updateRoleCapabilities(decryptedData);
      return this.userCapabilities = decryptedData;
    } else {
      return this.userCapabilities = this.userRoleCapabilities.value;
    }
  }

  getDecryptedData(data: object): Object {
    const buffer = Buffer.from(data['partyRoles'], 'base64');
    const respJson = buffer.toString('ascii');
    return JSON.parse(respJson);
  }

}
