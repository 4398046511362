<ng-template [appReadonly]="['Manage Teams']" (valueChange)="isDisabled($event)"></ng-template>
<div class="header-container" *ngIf="loaded" fxflex="100%" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" class="header-text-container">
          <h1 tabindex="0" #pageheading class="heading">{{heading}}:</h1>
          <div class="sub-heading-container">
              <span *ngIf="showItemCount" class="item-count">{{itemCount}}</span>
              <span class="sub-heading">{{subHeading}}</span>
          </div>
      </div>
      <div *ngIf="showAddIcon" fxLayoutAlign="end" fxLayoutAlign.xs="end" fxLayout="column" class="add-icon-container">
        <button type="button" id="add-team" class="button-icon-xl" (click)="openAddTeamDialog()" [disabled]="disable">
          <mat-icon matPrefix id="add_icon">add_circle</mat-icon>
        </button>
      </div>
</div>
