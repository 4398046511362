import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { LoggerService } from './logger.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Extension } from '../models/extension.model';
import { extension } from 'src/UnitTest-Support/Mocks/data.mock';

@Injectable({
  providedIn: 'root'
})
export class ExtensionService {
  extension$ = new BehaviorSubject<Extension[]>(null);
  extensionList$: Observable<any> = this.extension$.asObservable();
  /**
     * Base constructor
     * @param baseClientService Injects base client service
     * @param customLogger Injects remote logging service
     */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly customLogger: LoggerService
  ) { }

  getExtensionDetails(): Observable<any> {
    return this.baseClientService.get<any>(`/candidate/country-info`).pipe(
      map(r => {
        r.body.sort((q, w) => q.dialingCode - w.dialingCode || q.name.localeCompare(w.name)); 
        const extensions = r.body.reduce((result, item, i) => {
          if (i === 1) { return [item]; }
          const index = result.findIndex(val => val.dialingCode === item.dialingCode);
          if (index > -1) {
            if (item.iso3CharCode === 'USA') {
              result[index] = item;
            }
            return result;
          } else {
            return [...result, item];
          }
        });
        return extensions;
      }),
      catchError(err => {
        this.customLogger.error('Failed to get Extension details', err);
        const empty: Extension = null;
        return of(empty);
      })
    );
  }
}
