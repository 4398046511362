<ng-template [appReadonly]="['Role Capability Maintenance']" (valueChange)="isDisabled($event)"></ng-template>
<h1>{{title}}</h1>
<section id="capability-name">
  <div>
    <h2>Capability: <span>{{capability}}</span></h2>
    <h3>{{capabilityDescription}}</h3>
  </div>
  <button type="button" id="add-role" class="button-icon-xl" (click)="openAddRoleDialog()" [disabled]="disable">
    <mat-icon matPrefix id="add_icon">add_circle</mat-icon>
  </button>
</section>
<section id="role-table">
  <h4>Roles</h4>
  <div class="mat-elevation-z8">
    <div>
      <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortBy" matSortDirection="asc"
        matSortDisableClear>
        <!-- Role Name Column -->
        <ng-container matColumnDef="roleName" sticky>
          <th mat-header-cell id="roleName" *matHeaderCellDef mat-sort-header="roleName">Role Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.roleName"></span>
        </ng-container>
        <!-- Friendly Name Column -->
        <ng-container matColumnDef="friendlyName" sticky>
          <th mat-header-cell id="friendlyName" *matHeaderCellDef mat-sort-header="friendlyName">Friendly Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.friendlyName"></span>
        </ng-container>
        <!-- Operation Column -->
        <ng-container matColumnDef="operation">
          <th mat-header-cell id="operation" *matHeaderCellDef mat-sort-header="operation">Capability<br />Operation</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never" appearance="none">
              <mat-select [(value)]="element.capabilities[0].operation" (openedChange)="onMatSelectOpen(element.capabilities[0].operation)"
                (selectionChange)="onMatSelectValueChanges($event, element)">
                <ng-container *ngFor="let opt of operationOptions">
                  <mat-option [value]="opt">{{opt}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <!-- Permission Column -->
        <ng-container matColumnDef="permission">
          <th mat-header-cell id="permission" *matHeaderCellDef mat-sort-header="permission">Capability<br />Permission</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never" appearance="none">
              <mat-select [(value)]="element.capabilities[0].permission" (openedChange)="onMatSelectOpen(element.capabilities[0].permission)"
                (selectionChange)="onMatSelectValueChanges($event, element)">
                <ng-container *ngFor="let opt of permissionOptions">
                  <mat-option [value]="opt">{{opt}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell id="actions" *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button disableRipple mat-icon-button class="action-button" [disabled]="disable"
              (click)="openRemoveCapConfirm(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div [hidden]="!(ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0)">
      <mat-paginator [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage"
        [pageSizeOptions]="pageInfo.perPageOptions" [pageIndex]="pageInfo.currentPage"
        [disabled]="pageInfo.itemCount === 0" (page)="onPagination($event)"></mat-paginator>
    </div>
    <div [hidden]="!dataSource || (ELEMENT_DATA?.length > 0 && dataSource.filteredData.length > 0)" class="no-data" role="alert">
      <p>No Role Capabilities</p>
    </div>
  </div>
</section>
<section id="role-actions">
  <button mat-button type="button" id="cancel" class="button-outline-sm" (click)="openCancelConfirm()">
    <mat-icon>cancel</mat-icon>
    <span>Cancel</span>
  </button>
  <button mat-button type="button" id="save-role-caps" class="button-sm" *ngIf="changedRoleCaps.length > 0" (click)="openUpdateRoleCapsConfirm()"
    [disabled]="disable">
    <mat-icon>done</mat-icon>
    <span>Save Changes</span>
  </button>
</section>
<app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing"
  [mode]="progressMode" [value]="progressValue">
</app-progress-spinner>
