import { Component, OnInit, OnDestroy, Inject, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { RoleCapabilityService } from 'src/app/rolecaps/services/role-capability.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { ROLE_SEARCH_TITLE, GET_ROLE_DEFINITIONS_ERROR, GET_CAPABILITY_DEFINITIONS_ERROR, OPERATION_OPTIONS } from 'src/app/rolecaps/models/constants';
import { CapabilityDefinition, RoleDefinition } from 'src/app/rolecaps/models/definition.model';
import { Capability, RoleCapability, RoleCapabilityChanges } from 'src/app/rolecaps/models/rolecapability.model';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort } from '@angular/material/sort';
import { OperationType } from 'src/app/core/models/OperationType';

@Component({
  selector: 'app-rolecaps-role-search',
  templateUrl: './rolecaps-role-search.component.html',
  styleUrls: ['./rolecaps-role-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RolecapsRoleSearchComponent implements OnInit, OnDestroy {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-role-search';

  /** Current Role Capability */
  roleCaps: Array<RoleCapability>;

  /** Component title */
  title = ROLE_SEARCH_TITLE;

  /** Capability Title */
  capabilityName = '';
  
  /** Capability Definition */
  capabilityDefinition: CapabilityDefinition;

  /** Table data */
  ELEMENT_DATA: Array<RoleDefinition> = [];

  /** Found Roles datasource */
  filteredDataSource: MatTableDataSource<RoleDefinition>;

  /** Selected Roles datasource */
  selectedDataSource: MatTableDataSource<RoleDefinition>;

  /** Displayed table columns */
  displayedColumns: string[] = [
    // 'remove',
    'roleName',
    'friendlyName',
    'operation',
    'permission',
    'actions'
  ];

  /** Initial table sort by column */
  sortBy = 'roleName';

  /** Initial table sort direction */
  sortDir = 'asc';

  /** To sort the 'filtered table' columns */
  @ViewChild('filteredSort', { static: false }) set filteredSortOrder(filteredSort: MatSort) {
    if (filteredSort) {
      this.filteredDataSource.sort = filteredSort;
    }
  }

  /** To sort the 'selected table' columns */
  @ViewChild('selectedSort', { static: false }) set selectedSortOrder(selectedSort: MatSort) {
    if (selectedSort) {
      this.selectedDataSource.sort = selectedSort;
    }
  }

  /** Available Roles */
  availableRoles: RoleDefinition[] = [];

  /** Filtered Roles */
  filteredRoles: RoleDefinition[] = [];

  /** Selected Roles */
  selectedRoles: RoleDefinition[] = [];

  /** Selected Roles Count */
  selectedRolesCount = 0;

  /** Role Capability Permission options */
  permissionOptions = ['allow', 'deny'];

  /** Role Capability Operation options */
  operationOptions = OPERATION_OPTIONS;

  /** Capability options */
  roleOptions: string[] = [];

  /** Filtered Capability options */
  filteredRoleOptions: Observable<string[]>;

  /** Capability Search Input */
  search = new FormControl('');

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Processing */
  processing = false;

  /** Injecting dependencies */
  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialogRef: MatDialogRef<RolecapsRoleSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data && this.data.roleCaps) {
      this.roleCaps = data.roleCaps;
    }
    if (this.data && this.data.capability) {
      this.capabilityName = this.data.capability;
    }
  }

  /** Init */
  ngOnInit() {
    this.loadRoles();
    this.loadCapabilities();
    this.filteredDataSource = new MatTableDataSource(this.filteredRoles);
    this.filteredDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.selectedDataSource = new MatTableDataSource(this.selectedRoles);
    this.selectedDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  /** Load Role definitions */
  loadRoles() {
    this.processing = true;
    this.subscription.add(this.roleCapabilityService.getRoleDefinitions().subscribe({
      next: response => {
        if (response && response.status === 200) {
          this.availableRoles = response.body;
          if (this.roleCaps) {
            this.availableRoles = this.availableRoles.filter(item =>
              this.roleCaps.find(role => role.roleName === item.roleName) === undefined
            );
          }
          this.roleOptions = this.availableRoles.map((role: RoleDefinition) => role.roleName).sort();
          this.filteredRoleOptions = this.search.valueChanges.pipe(startWith(''), map(value => this.filterRoleOptions(value || '')));
        } else {
          this.notificationsService.flashNotification('danger', GET_ROLE_DEFINITIONS_ERROR);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get role definitions', err);
        this.notificationsService.flashNotification('danger', GET_ROLE_DEFINITIONS_ERROR);
      },
      complete: () => {
        this.processing = false;
      }
    }));
  }
  
  /** Load Capability definition */
  loadCapabilities() {
    this.processing = true;
    this.subscription.add(this.roleCapabilityService.getCapabilityDefinitions().subscribe({
      next: response => {
        if (response && response.status === 200) {
          this.capabilityDefinition = response.body.filter(item => item.capabilityName === this.capabilityName)[0];
        } else {
          this.notificationsService.flashNotification('danger', GET_CAPABILITY_DEFINITIONS_ERROR);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get capability definitions', err);
        this.notificationsService.flashNotification('danger', GET_CAPABILITY_DEFINITIONS_ERROR);
      },
      complete: () => {
        this.processing = false;
      }
    }));
  }

  /**
   * Sorting accessor
   * @param item Capability
   * @param property string
   */
  sortingDataAccessor(item: RoleDefinition, property: string) {
    if (item[property]) {
      return item[property].toLocaleLowerCase();
    } else {
      return '';
    }
  }

  /** Filter Available Roles */
  filterRoles(value: string): RoleDefinition[] {
    const filterValue = value.toLowerCase();
    return this.availableRoles.filter(role => role.roleName.toLowerCase().includes(filterValue));
  }

  /** Filter Role Options */
  filterRoleOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.roleOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  /** Filter available capability options by search term */
  searchRoles() {
    const filterValue = this.search.value.toLowerCase();
    this.filteredRoles = this.availableRoles.filter(role => filterValue !== '' && role.roleName.toLowerCase().includes(filterValue));
    this.filteredDataSource.data = this.filteredRoles;
  }

  searchKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.searchRoles();
    }
  }

  resetSearch() {
    this.search.setValue('');
  }

  /** Update row permission value */
  onPermissionChanges(event: MatSelectChange, element: RoleDefinition) {
    element['permission'] = event.value;
  }

  /** Update row operation value */
  onOperationChanges(event: MatSelectChange, element: RoleDefinition) {
    element['operation'] = event.value;
  }

  /** Add capability to selected section */
  addRoleToSelected(element) {
    this.selectedRoles.push(element);
    this.selectedRolesCount = this.selectedRoles.length;
    this.availableRoles.splice(this.availableRoles.findIndex(role => role.roleName === element.roleName), 1);
    this.filteredRoles.splice(this.filteredRoles.findIndex(role => role.roleName === element.roleName), 1);
    this.filteredDataSource.data = this.filteredRoles;
    this.selectedDataSource.data = this.selectedRoles;
  }

  /** Remove capability from selected section */
  removeRoleFromSelected(element) {
    this.selectedRoles.splice(this.selectedRoles.findIndex(role => role.roleName === element.roleName), 1);
    this.selectedRolesCount = this.selectedRoles.length;
    delete element.operation;
    delete element.permission;
    this.availableRoles.push(element);
    const searchValue = this.search.value.toLowerCase();
    if (searchValue !== '' && element.roleName.toLowerCase().indexOf(searchValue) > -1) {
      this.filteredRoles.push(element);
    }
    this.filteredDataSource.data = this.filteredRoles;
    this.selectedDataSource.data = this.selectedRoles;
  }

  /** Close the dialog and return an array of RoleCapabilityChanges */
  updateRoles() {
    const changedRoleCaps: Array<RoleCapabilityChanges> = this.selectedRoles.map((item: any) => {
      const tempCapDefinition = deepCopy(this.capabilityDefinition);
      const { 
        _id, definitionType, capabilityName, application, __v, createdAt, updatedAt, createdBy, updatedBy, ...tempCap
      } = tempCapDefinition;
      const capability = Object.assign({}, {
        ...tempCap,
        name: this.capabilityDefinition.capabilityName,
        operation: item.operation,
        permission: item.permission
      });
      return { _id: item._id, roleName: item.roleName, friendlyName: item.friendlyName, capability, operationType: OperationType.create };
    });
    this.dialogRef.close(changedRoleCaps);
  }

  /** Closes the dialog without changes */
  cancel() {
    this.dialogRef.close();
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
