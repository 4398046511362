import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Navigation, Router } from '@angular/router';
import { PARTY_ID_LENGTH_ERROR, PARTY_ID_PATTERN_ERROR, PARTY_ID_REQ_ERROR, USER_ALIGNMENT_HEADING } from '../../models/constants';

@Component({
  selector: 'app-user-alignment-dashboard',
  templateUrl: './user-alignment-dashboard.component.html',
  styleUrls: ['./user-alignment-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserAlignmentDashboardComponent {

  /** Component css class */
  @HostBinding('class') class = 'user-alignment-dashboard';
  /** Page heading */
  heading = USER_ALIGNMENT_HEADING;
  /** Party Search Form */
  searchForm: FormGroup;
  /** Entered Party Ids */
  partyIds = [];
  /** To check for readonly access */
  disable = false;

  /** Injecting dependencies */
  constructor(
    private readonly fb: UntypedFormBuilder,
    private router: Router
  ) {
    this.searchForm = this.fb.group({
      partyId: this.fb.control('', Validators.compose([Validators.required, Validators.pattern('[a-fA-F0-9]*'), Validators.minLength(24), Validators.maxLength(24)]))
    });
    const nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras?.state?.partyIds) {
      this.partyIds = nav.extras.state.partyIds;
    }
  }

  /**
   * To check for readonly access
   * @param flag boolean
   */
  isDisabled(flag: boolean) {
    this.disable = flag;
  }

  /** Add the entered partyId to partyIds array */
  public addPartyId(): void {
    const x = this.searchForm.value.partyId;
    const n = x.length;
    if (n === 24 && this.partyIds.includes(x) === false) {
      this.partyIds.push(x);
      this.searchForm.reset();
      this.searchForm.get('partyId').updateValueAndValidity();
    }
  }

  /**
   * Remove the selected partyId from partyIds array
   * @param element partyId
   */
  public removePartyId(element) {
    for (let i = 0; i < this.partyIds.length; i++) {
      if (this.partyIds[i] === element) {
        this.partyIds.splice(i, 1);
      }
    }
  }
  
  /**
   * Custom error messages
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(fieldName) {
    if (fieldName === 'partyId') {
      const control = this.searchForm.get('partyId');
      return control.hasError('required')
        ? PARTY_ID_REQ_ERROR
        : control.hasError('pattern')
          ? PARTY_ID_PATTERN_ERROR
          : control.hasError('minlength')
            ? PARTY_ID_LENGTH_ERROR
            : control.hasError('maxlength')
              ? PARTY_ID_LENGTH_ERROR
              : '';
    }
    return '';
  }
  
  /** Redirect to user alignment parties compoment passing the entered partyIds */
  public searchParties() {
    this.router.navigateByUrl('/project-alpha/user-alignment-parties', { skipLocationChange: true, state: { partyIds: this.partyIds } });
  }

}
