import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PartySharedService } from 'src/app/core/services/party-shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private readonly spinner: NgxSpinnerService,
    private readonly router: Router,
    private readonly partySharedSvc: PartySharedService
  ) {
  }

  ngOnInit() {
    this.spinner.show();
    this.partySharedSvc.getPartyId().then(id => {
      this.partySharedSvc.getRoleCapabilities(id).then(response => {
        if (!!response && !!response.roleCapabilities && response.roleCapabilities.length > 0) {
            this.router.navigate(['/project-alpha']);
        } else {
            this.router.navigate(['/contact-us']);
        }
      });
    }, err => {
      this.router.navigate(['/contact-us']);
    });
  }
}
