<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between end"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">{{title}}</div>
    <button type="button" class="button-icon" (click)="cancel()" title="close" [disabled]="processing">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form fxLayout="column" class="middle-section" [formGroup]="clientSearchForm">
    <div class="middle-section">
      <mat-dialog-content>
        <div class="client-search-container">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start">
            <div fxFlex="40%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput placeholder="Client Name" formControlName="name"
                (keyup.enter)="clientSearchForm.valid && search(1, pageInfo.perPage)">
                <mat-error>{{ getErrorMessage('NAME') }}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="40%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput placeholder="Client Number" formControlName="number"
                (keyup.enter)="clientSearchForm.valid && search(1, pageInfo.perPage)">
                <mat-error>{{ getErrorMessage('NUMBER') }}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="30%" fxLayoutAlign="end" fxFlex.lt-md="100%">
              <button mat-button type="button" class="button-sm"
              (click)="search(1, pageInfo.perPage)" [disabled]="!clientSearchForm.valid || processing">
                <mat-icon *ngIf="!processing">search</mat-icon>
                <mat-icon *ngIf="processing"><mat-spinner diameter="20"></mat-spinner></mat-icon>
                <span>Search</span>
              </button>
            </div>
          </div>
          <mat-error class="formgroup-error">{{ getErrorMessage('FORMGROUP') }}</mat-error>
        </div>
        <mat-divider></mat-divider>
        <div class="sub-title">Found Clients</div>
        <div class="found-clients-container">
          <div fxLayout="column" fxLayoutGap="0.25em">
            <ng-container *ngFor="let client of foundClients">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" fxLayoutAlign.lt-md="start">
                <div fxFlex="75%" fxLayoutAlign="start">
                  <span>{{client.name}} ({{client.number}})</span>
                </div>
                <div fxFlex="25%" fxLayoutAlign="end">
                  <button mat-button type="button" class="button-xs" (click)="addClientToList(client)"
                  [disabled]="(!multiSelect && selectedClientsCount === 1) ||
                  (client | teamClient:{ team: this.team, selectedClients: this.selectedClients })">
                    <mat-icon>check_circle</mat-icon>
                    <span>Select</span>
                  </button>
                </div>
              </div>
              <mat-divider></mat-divider>
            </ng-container>
          </div>
          <div *ngIf="foundClients.length === 0 && formSubmitted && !processing">No Clients found</div>
        </div>
        <div>
          <mat-paginator [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage" [pageSizeOptions]="pageInfo.perPageOptions"
          [pageIndex]="pageInfo.currentPage" (page)="onPagination($event)" [disabled]="pageInfo.itemCount === 0"></mat-paginator>
        </div>
        <mat-divider></mat-divider>
        <div class="sub-title" *ngIf="multiSelect">Selected Clients</div>
        <div class="selected-clients-container" fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="multiSelect">
          <div class="selected-clients-item" *ngFor="let client of selectedClients" fxFlex="0 0 100%">
            <mat-checkbox [checked]="client.checked" (change)="removeClient(client)">{{client.name}} ({{client.number}})</mat-checkbox>
          </div>
        </div>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions *ngIf="multiSelect">
      <button mat-button type="button" class="button-outline-sm" (click)="cancel()" [disabled]="processing">
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
      <button mat-button type="button" class="button-sm" *ngIf="selectedClientsCount !== 0"
      (click)="save()" [disabled]="selectedClientsCount === 0 || processing">
        <mat-icon>add_circle</mat-icon>
        <span>{{selectedClientsCount}} {{selectedClientsCount === 1 ? 'Client' : 'Clients' }}</span>
      </button>
    </mat-dialog-actions>
  </form>
</div>
