import { Injectable } from '@angular/core';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { Observable, of } from 'rxjs';
import { map, catchError, delay, shareReplay } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { urlType } from 'src/app/core/models/urlType';
import { timeout } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class CallSummarizerService {

  /**
   * base constructor
   * @param baseClientService baseclient service
   * @param logSvc remote logging service
   * @param customLogger logger service
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService
  ) { }

   /**
   * getemailSummeryResponse
   * @param inputText inputText
   */
  getemailSummeryResponse(input: any, generate: 'email' | 'summary'): Observable<any> {
    return this.baseClientService
      .post<any>(`/call-summarizer?generate=${generate}`, input, 'Get Summarized Data', urlType.opdata, 120000)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to Get Summarized Data', err);
        })
      );
  }

  catchBlock(message: string, error: any) {
    this.customLogger.error(message, error);
    return of(error);
  }
  
}
