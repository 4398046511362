<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between end"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">{{title}}</div>
    <button id="form-close" type="button" class="button-icon" (click)="cancel()" title="Close Team Search">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form fxLayout="column" class="middle-section" [formGroup]="teamSearchForm">
    <div class="middle-section">
      <mat-dialog-content>
        <div class="team-search-container">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start">
            <div fxFlex="0 0 calc(50% - 1em)" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input id="team-name" matInput placeholder="Team Name" formControlName="teamName" spellcheck="false"
                (keyup.enter)="teamSearchForm.valid && search()">
                <mat-error id="team-name-error">{{ getErrorMessage('NAME') }}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="0 0 calc(50% - 1em)" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input id="team-owner" matInput placeholder="Team Owner" formControlName="owner" spellcheck="false" [readonly]="true"
                (click)="openEmployeeSearchDialog('owner')" (keydown.space)="openEmployeeSearchDialog('owner')">
                <button id="team-owner-search" type="button" mat-button matSuffix mat-icon-button
                *ngIf="!teamSearchForm.controls.owner.value" (click)="openEmployeeSearchDialog('owner')"
                (keydown.space)="openEmployeeSearchDialog('owner')" title="Search Team Owner">
                  <mat-icon>search</mat-icon>
                </button>
                <button id="team-owner-reset" type="button" mat-button matSuffix mat-icon-button
                *ngIf="teamSearchForm.controls.owner.value"
                (click)="teamSearchForm.controls.owner.setValue('');teamSearchForm.controls.teamOwnerId.setValue('')" title="Reset Team Owner">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error id="team-owner-error">{{ getErrorMessage('OWNER') }}</mat-error>
            </mat-form-field>
          </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start">
          <div fxFlex="0 0 calc(50% - 1em)" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input id="team-client" matInput placeholder="Client on Team" formControlName="client" spellcheck="false" [readonly]="true"
              (click)="openClientSearchDialog()" (keydown.space)="openClientSearchDialog()">
              <button id="team-client-search" type="button" mat-button matSuffix mat-icon-button
              *ngIf="!teamSearchForm.controls.client.value" (click)="openClientSearchDialog()" (keydown.space)="openClientSearchDialog()"
              title="Search Team Client">
                <mat-icon>search</mat-icon>
              </button>
              <button id="team-client-reset" type="button" mat-button matSuffix mat-icon-button *ngIf="teamSearchForm.controls.client.value"
              (click)="teamSearchForm.controls.client.setValue('');teamSearchForm.controls.teamClientId.setValue('')" title="Reset Team Client">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error id="team-client-error">{{ getErrorMessage('CLIENT') }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="0 0 calc(50% - 1em)" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input id="team-employee" matInput placeholder="Employee on Team" formControlName="employee"
              spellcheck="false" [readonly]="true" (click)="openEmployeeSearchDialog('employee')"
              (keydown.space)="openEmployeeSearchDialog('employee')">
              <button id="team-employee-search" type="button" mat-button matSuffix mat-icon-button
              *ngIf="!teamSearchForm.controls.employee.value" (click)="openEmployeeSearchDialog('employee')"
              (keydown.space)="openEmployeeSearchDialog('employee')" title="Search Team Employee">
                <mat-icon>search</mat-icon>
              </button>
              <button id="team-employee-reset" type="button" mat-button matSuffix mat-icon-button
              *ngIf="teamSearchForm.controls.employee.value"
              (click)="teamSearchForm.controls.employee.setValue('');teamSearchForm.controls.teamEmployeeId.setValue('')"
              title="Reset Team Employee">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error id="team-employee-error">{{ getErrorMessage('EMPLOYEE') }}</mat-error>
           </mat-form-field>
          </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start">
          <div fxFlex="0 0 calc(50% - 1em)" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-slide-toggle id="include-inactive" formControlName="includeInactive"
            disableRipple="true" labelPosition="before">Include Inactive</mat-slide-toggle>
          </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div id="formgroup-error">
          <mat-error>{{ getErrorMessage('FORMGROUP') }}</mat-error>
        </div>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions>
      <button id="form-reset" type="button" mat-button class="button-outline-sm" *ngIf="searchActive && !teamSearchForm.dirty"
      (click)="reset()" title="Reset Team Search">
        <mat-icon>clear_all</mat-icon>
        <span>Reset</span>
      </button>
      <button id="form-cancel" type="button" mat-button class="button-outline-sm" (click)="cancel()" title="Cancel Team Search">
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
      <button id="form-search" type="button" mat-button class="button-sm" (click)="search()" [disabled]="!teamSearchForm.valid"
      title="Search">
        <mat-icon>search</mat-icon>
        <span>Search</span>
      </button>
    </mat-dialog-actions>
  </form>
</div>
