import { Injectable } from '@angular/core';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { SpringboardApps } from '../models/SpringboardApps.model';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { urlType } from '../models/urlType';

@Injectable({
  providedIn: 'root'
})
export class MiniSpringboardSetupService {

  /**
   * base constructor
   * @param baseClientService Base Client Service Service injector
   * @param logService Log Service Service injector
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logService: RemoteLoggingService
  ) { }

  /**
   * getApps method to get the apps available to the logged in user from the api
   */
  getApps(): Observable<SpringboardApps> {
    return this.baseClientService.get<SpringboardApps>('/springboard', 'getting springboard apps', urlType.accessmgmt)
      .pipe(
        map(r => r.body), catchError((err: any, _source: Observable<any>) => {
          this.logService.logError(err);
          return EMPTY;
        })
      );
  }

  /**
   * getColToken method set a token for CartusOnline authentication
   */
  getColToken(): Observable<any> {
    return this.baseClientService.get<any>('/v1/navigateToCOL', 'getting CartusOnline token', urlType.integration)
      .pipe(
        map(r => r), catchError((err: any, _source: Observable<any>) => {
          this.logService.logError(err);
          return EMPTY;
        })
      );
  }

}
