import { Component, OnInit, OnDestroy, Inject, HostBinding, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { clientSearchTitle, apiErrorMessage } from '../../models/constants';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Team, Client, SelectedClient } from '../../models/teams.model';
import * as querystring from 'querystring';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-team-client-search',
  templateUrl: './team-client-search.component.html',
  styleUrls: ['./team-client-search.component.scss']
})
export class TeamClientSearchComponent implements OnInit, OnDestroy {

  /** Component css class */
  @HostBinding('class') class = 'team-client-search';

  /** Current Team */
  team: Team;

  /** Form group name */
  clientSearchForm: UntypedFormGroup;

  /** Has search form been submitted */
  formSubmitted = false;

  /** Title to display the dialog window page title */
  title = clientSearchTitle;

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Found Clients */
  foundClients: Client[] = [];

  /** Selected clients */
  selectedClients: SelectedClient[] = [];

  /** Selected clients count */
  selectedClientsCount = 0;

  /** Single or multi select */
  multiSelect = false;

  /** Processing */
  processing = false;

  /** Pagination */
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  pageInfo = {
    itemCount: 0,
    currentPage: 0,
    perPage: 25,
    perPageOptions: [5, 25, 50, 100]
  };

  /** Injecting dependencies */
  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TeamClientSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  /** Init */
  ngOnInit() {
    this.clientSearchForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([Validators.minLength(3)])
      ],
      number: [
        '',
        Validators.compose([Validators.minLength(2)])
      ]
    }, { validators: this.atLeastOne(['name', 'number']) });
    if (this.data && this.data.hasOwnProperty('team')) {
      this.team = this.data.team;
    }
    if (this.data && this.data.hasOwnProperty('multiSelect')) {
      this.multiSelect = this.data.multiSelect;
    }
  }

  /** Custom error messages */
  getErrorMessage(fieldName) {
    if (fieldName === 'NAME') {
      return this.clientSearchForm.get('name').hasError('minlength')
        ? 'Enter more than two characters'
        : '';
    }
    if (fieldName === 'NUMBER') {
      return this.clientSearchForm.get('number').hasError('minlength')
      ? 'Enter more than one character'
      : '';
    }
    if (fieldName === 'FORMGROUP') {
      return this.clientSearchForm.dirty && this.clientSearchForm.hasError('atLeastOne')
      ? 'Enter at least one search term'
      : '';
    }
    return '';
  }

  /** Custom validator */
  atLeastOne = (fields: Array<string>) => {
    return (group: UntypedFormGroup) => {
      for (const fieldName of fields) {
        if (group.get(fieldName).value) {
          return null;
        }
      }
      return { atLeastOne: true };
    };
  }

  /** Find clients */
  search(page: number, perPage: number) {
    this.processing = true;
    this.foundClients = [];
    const queryString = {};
    if (!!this.clientSearchForm.controls.name.value) {
      queryString['clientName'] = this.clientSearchForm.controls.name.value;
    }
    if (!!this.clientSearchForm.controls.number.value) {
      queryString['clientNumber'] = this.clientSearchForm.controls.number.value;
    }
    queryString['page'] = page;
    queryString['perPage'] = perPage;
    queryString['sortField'] = 'name';
    queryString['sortDirection'] = 'asc';
    this.subscription.add(
      this.teamsService.getClients(querystring.stringify(queryString)).subscribe({
        next: response => {
        if (response && response.status === 204) {
          this.foundClients = [];
        } else if (response && response.status === 200 && response.body) {
          const paginationHeader = response.headers.get('X-Pagination');
          if (paginationHeader) {
            const pagination = JSON.parse(paginationHeader);
            this.pageInfo.itemCount = pagination.itemCount;
            this.pageInfo.currentPage = pagination.currentPage - 1;
          }
          this.foundClients = response.body;
        } else {
          this.notificationsService.flashNotification('danger', apiErrorMessage);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get clients', err);
        this.notificationsService.flashNotification('danger', apiErrorMessage);
      },
      complete: () => {
        this.processing = false;
        this.formSubmitted = true;
      }})
    );
  }

  /** Pagination */
  onPagination(event: PageEvent) {
    this.pageInfo.perPage = event.pageSize;
    this.search(event.pageIndex + 1, this.pageInfo.perPage);
  }

  /** Add client to Selected Clients */
  addClientToList(client: Client) {
    if (!this.multiSelect) {
      const selectedClient: SelectedClient = { id: client.id, number: client.number, name: client.name, checked: true };
      this.dialogRef.close(selectedClient);
      return;
    }
    const clientToAdd: SelectedClient = { id: client.id, number: client.number, name: client.name, checked: true };
    if (!this.selectedClients.find(selected => selected.id === clientToAdd.id)) {
      this.selectedClients.push(clientToAdd);
    } else {
      this.selectedClients.find(selected => selected.id === clientToAdd.id).checked = true;
    }
    this.selectedClientsCount = this.selectedClients.filter(selected => selected.checked).length;
    this.formSubmitted = false;
  }

  /** Remove selected client */
  removeClient(client) {
    const index = this.selectedClients.findIndex(item => item.id === client.id);
    this.selectedClients.splice(index, 1);
    this.selectedClientsCount = this.selectedClients.filter(selected => selected.checked).length;
  }

  /** Closes dialog and returns Selected Clients */
  save() {
    const checked = this.selectedClients.filter(client => client.checked);
    this.dialogRef.close(checked);
  }

  /** Closes dialog and clears the form */
  cancel() {
    this.clientSearchForm = this.formBuilder.group({
      name: [''],
      number: ['']
    });
    this.dialogRef.close();
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
