import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnDataKey'
})
export class ColumnDataKeyPipe implements PipeTransform {

  transform(object: any, keyName: string): any {
    return object[keyName];
  }

}
