import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../app/public/project-alpha/authGuard';
import { ProjectAlphaComponent } from '../public/project-alpha/project-alpha.component';
import { TeamsListComponent } from 'src/app/teams/components/teams-list/teams-list.component';
import { TeamDetailsComponent } from './components/team-details/team-details.component';
const routes: Routes = [
  {
    path: 'project-alpha',
    component: ProjectAlphaComponent,
    children: [
      {
        path: 'teams',
        component: TeamsListComponent,
        data: { capabilities: ['Manage Teams'] },
        canActivate: [AuthGuard]
      },
      {
        path: 'teams/:teamId',
        component: TeamDetailsComponent,
        data: { capabilities: ['Manage Teams'] },
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamsRoutingModule { }
