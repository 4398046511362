<ng-template [appReadonly]="['Role Capability Maintenance']" (valueChange)="isDisabled($event)"></ng-template>
<h2>Roles</h2>
<section id="role-search">
  <div>
    <span>
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput id="search-input" (input)="applyFilter($event.target.value)" [value]="filterText"
          placeholder="Search by Friendly / Role Name...">
      </mat-form-field>
    </span>
    <button type="button" id="add-role" class="button-icon-xl" (click)="openAddRoleDialog()" [disabled]="disable">
      <mat-icon matPrefix id="add_icon">add_circle</mat-icon>
    </button>
  </div>
</section>
<section id="role-table">
  <div class="mat-elevation-z8">
    <div *ngIf="ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0">
      <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortBy" matSortDirection="asc" matSortDisableClear>
        <!-- Remove Column -->
        <ng-container matColumnDef="remove" sticky>
          <th mat-header-cell id="remove" *matHeaderCellDef>Remove</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox disableRipple (click)="$event.stopPropagation()" (change)="$event ? selectRemoveCheckBox($event, element) : null" (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)" [disabled]="disableRemoveCheckBoxes && !selection.isSelected(element)"></mat-checkbox>
          </td>
        </ng-container>
        <!-- Friendly Name Column -->
        <ng-container matColumnDef="friendlyName" sticky>
          <th mat-header-cell id="friendlyName" *matHeaderCellDef mat-sort-header="friendlyName">Friendly Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.friendlyName | highlightSearch: filterText"></span>
        </ng-container>
        <!-- Role Name Column -->
        <ng-container matColumnDef="roleName">
          <th mat-header-cell id="roleName" *matHeaderCellDef mat-sort-header="roleName">Role Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.roleName | highlightSearch: filterText"></span>
        </ng-container>
        <!-- Dependencies Name Column -->
        <ng-container matColumnDef="dependencies">
          <th mat-header-cell id="dependencies" *matHeaderCellDef mat-sort-header="dependencies">Dependencies</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngFor="let item of element?.dependencies; let i = index" >
              <span [innerHTML]="item.roleName"></span>
              <br *ngIf="element?.dependencies.length > 1 && i !== element.dependencies.length - 1" />
            </ng-container>
            <span *ngIf="!element.dependencies">-</span>
          </td>
        </ng-container>
        <!-- Bypass Association Name Column -->
        <ng-container matColumnDef="bypassAssociation">
          <th mat-header-cell id="bypassAssociation" *matHeaderCellDef mat-sort-header="bypassAssociation">Bypass Association</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox disableRipple class="bypass-checkbox" [checked]="element?.bypassAssociation === true"
            [indeterminate]="!element.hasOwnProperty('bypassAssociation')" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell id="actions" *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button disableRipple mat-icon-button class="action-button" [disabled]="disable" (click)="openEditRoleDialog(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div [hidden]="!(ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0)">
      <mat-paginator [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage" [pageSizeOptions]="pageInfo.perPageOptions"
      [pageIndex]="pageInfo.currentPage" [disabled]="pageInfo.itemCount === 0" (page)="onPagination($event)"></mat-paginator>
    </div>
    <div [hidden]="!dataSource || (ELEMENT_DATA?.length > 0 && dataSource.filteredData.length > 0) || processing" class="no-data" role="alert">
      <p>No records found</p>
    </div>
  </div>
</section>
<section id="role-actions">
  <button mat-button type="button" id="cancel" class="button-outline-sm" *ngIf="selection.selected.length > 0" (click)="cancel()" [disabled]="disable">
    <mat-icon>cancel</mat-icon>
    <span>Cancel</span>
  </button>
  <button mat-button type="button" id="remove-role" class="button-sm" *ngIf="selection.selected.length > 0" (click)="openRemoveRolesConfirm()" [disabled]="disable">
    <mat-icon>remove_circle</mat-icon>
    <span>{{
      selection.selected.length === 1 ?
      'Remove ' + selection.selected.length + ' Role' :
      'Remove ' + selection.selected.length + ' Roles'
    }}</span>
  </button>
</section>
<app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing">
</app-progress-spinner>
