import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectAlphaModule } from '../public/project-alpha/project-alpha.module';
import { EntitlementManagerRoutingModule } from './entitlement-manager-routing.module';
import { EntitlementManagerDashboardComponent } from './components/entitlement-manager-dashboard/entitlement-manager-dashboard.component';

@NgModule({
  declarations: [
    EntitlementManagerDashboardComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ProjectAlphaModule,
    EntitlementManagerRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class EntitlementManagerModule { }
