<ng-template [appReadonly]="['Manage Teams']" (valueChange)="isDisabled($event)"></ng-template>
<mat-card class="team-card">
  <mat-card-header class="team-card-header">
    <div mat-card-avatar>
      <mat-icon class="team-card-avatar">people</mat-icon>
    </div>
    <mat-card-title class="team-card-title">{{title}}</mat-card-title>
    <button mat-button class="button-sm team-card-add-employee" (click)="openEmployeeSearchDialog()" [disabled]="profileFormChanged || disable">
      <mat-icon>add_circle</mat-icon>
      <span>Employees</span>
    </button>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="team-card-content">
    <div id="employees-table" [hidden]="ELEMENT_DATA.length === 0">
      <table mat-table [dataSource]="this.dataSource" matSort matSortDisableClear>
        <!-- Name Column -->
        <ng-container matColumnDef="employeeName" sticky>
          <th mat-header-cell id="name" *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.name"></span>
          </td>
        </ng-container>
        <!-- Roles Column -->
        <ng-container matColumnDef="employeeRoles">
          <th mat-header-cell id="roles" *matHeaderCellDef mat-sort-header>Role(s)</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngFor="let role of element.roles; let i = index" >
              <span [innerHTML]="role.description"></span>
              <br *ngIf="element.roles.length > 1 && i !== element.roles.length - 1" />
            </ng-container>
          </td>
        </ng-container>
        <!-- Effective Date Column -->
        <ng-container matColumnDef="employeeFromDate">
          <th mat-header-cell id="fromDate" *matHeaderCellDef mat-sort-header>Effective Date</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngFor="let role of element.roles; let i = index" >
              <span [innerHTML]="role.fromDate | date:'dd-MMM-yyyy'"></span>
              <br *ngIf="element.roles.length > 1 && i !== element.roles.length - 1" />
            </ng-container>
          </td>
        </ng-container>
        <!-- Expiration Date Column -->
        <ng-container matColumnDef="employeeToDate">
          <th mat-header-cell id="toDate" *matHeaderCellDef mat-sort-header>Expiration Date</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngFor="let role of element.roles; let i = index" >
              <span [innerHTML]="role.toDate | date:'dd-MMM-yyyy'"></span>
              <br *ngIf="element.roles.length > 1 && i !== element.roles.length - 1" />
            </ng-container>
          </td>
        </ng-container>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event && masterToggle()" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()" [disabled]="profileFormChanged || disable">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event && selection.toggle(row)"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" [disabled]="profileFormChanged || disable">
            </mat-checkbox>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.disabled]="profileFormChanged || disable"
        (click)="!disable && !profileFormChanged && selection.toggle(row)"></tr>
      </table>
    </div>
    <div [hidden]="!dataSource || ELEMENT_DATA.length === 0">
      <mat-paginator [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]"
      [disabled]="profileFormChanged"></mat-paginator>
    </div>
    <div [hidden]="!dataSource || (ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0)" class="no-data" role="alert">
      No records found
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions class="team-card-actions">
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1em">
      <div fxLayoutAlign="end" fxLayout.xs="row" fxFlex.xs="100%">
        <button mat-button id="remove" class="button-sm" (click)="openRemoveEmployeesDialog()"
        *ngIf="selection.selected.length > 0" [disabled]="profileFormChanged || disable">
          <mat-icon>remove_circle</mat-icon>
          <span>{{selection.selected.length}} {{selection.selected.length === 1 ? 'Employee' : 'Employees'}}</span>
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
