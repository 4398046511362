<ng-template [appReadonly]="['Manage User Alignment']" (valueChange)="isDisabled($event)"></ng-template>
<div class="header-access-container">
  <div class="header-text-container">
    <h1 class="heading">Manage User Alignment</h1>
  </div>
  <div *ngIf="disable" id="readonly-indicator">
    <span>Read Only</span>
    <mat-icon>edit_off</mat-icon>
  </div>
</div>
<section id="people-search">
  <div id="party-entry">
      <form [formGroup]="searchForm" novalidate>
        <mat-form-field appearance="outline">
          <button disableRipple mat-icon-button matPrefix id="add-party-button" type="button" class="action-button"
            [disabled]="searchForm.invalid" (click)="addPartyId()">
            <mat-icon>add_circle</mat-icon>
          </button>
          <input matInput id="party-input" formControlName="partyId" class="form-control" placeholder="Enter the Party Ids"
            required (keyup.enter)="searchForm.valid && !disable && addPartyId()">
        </mat-form-field>
        <mat-hint *ngIf="searchForm.get('partyId')?.invalid && searchForm.get('partyId')?.dirty">
          <span class="error-warning">{{ getErrorMessage('partyId') }}</span>
        </mat-hint>
      </form>
  </div>
  <div id="party-search">
    <table id="parties-table">
      <caption>You must enter at least two different Party Ids in order to perform a search</caption>
      <thead>
        <tr>
          <th>Party Id</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of partyIds">
          <td>{{item}}</td>
          <td>
            <button disableRipple mat-icon-button type="button" id="remove-party-button" class="action-button"
              (click)="removePartyId(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="search-actions">
      <button disableRipple mat-button type="button" id="search-button" class="button-sm" (click)="searchParties()"
        [disabled]="partyIds.length < 2">
        <mat-icon>search</mat-icon>
        <span>Search</span>
      </button>
    </div>
  </div>
</section>
