import { Component, OnInit, OnDestroy, Inject, HostBinding, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { employeeSearchTitle } from '../../models/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Team, SelectedEmployee, Person, Role } from '../../models/teams.model';
import * as querystring from 'querystring';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { apiErrorMessage } from 'src/app/teams/models/constants';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-team-employee-search',
  templateUrl: './team-employee-search.component.html',
  styleUrls: ['./team-employee-search.component.scss']
})
export class TeamEmployeeSearchComponent implements OnInit, OnDestroy {

  /** Component css class */
  @HostBinding('class') class = 'team-employee-search';

  /** Current Team */
  team: Team;

  /** Form group name */
  employeeSearchForm: UntypedFormGroup;

  /** Has search form been submitted */
  formSubmitted = false;

  /** Title to display the dialog window page title */
  title = employeeSearchTitle;

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Found Employees */
  foundEmployees: Person[] = [];

  /** Selected Employees */
  selectedEmployees: SelectedEmployee[] = [];

  /** Selected Employees count */
  selectedEmployeesCount = 0;

  /** Single or multi select */
  multiSelect = false;

  /** Team status options */
  teamRoles: string[] = [];

  /** Selected Role */
  selectedRole: string;

  /** Selected Roles */
  selectedRoles: string[] = [];

  /** Processing */
  processing = false;

  /** Pagination */
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  pageInfo = {
    itemCount: 0,
    currentPage: 0,
    perPage: 25,
    perPageOptions: [5, 25, 50, 100]
  };

  /** Injecting dependencies */
  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TeamEmployeeSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public spinner: NgxSpinnerService,
  ) {}

  /** Init */
  ngOnInit() {
    this.employeeSearchForm = this.formBuilder.group({
      employee: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      roleName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      roleDescription: [
        ''
      ],
      searchName: [
        ''
      ]
    });
    if (this.data && this.data.hasOwnProperty('team')) {
      this.team = this.data.team;
    }
    if (this.data && this.data.hasOwnProperty('multiSelect')) {
      this.multiSelect = this.data.multiSelect;
    }
    if (this.multiSelect) {
      setTimeout(() => this.loadRoles());
    } else {
      this.employeeSearchForm.controls.roleName.clearValidators();
    }
  }

  /** Load the roles that are applicable to Team members */
  loadRoles() {
    this.spinner.show();
    this.subscription.add(
      this.teamsService.getRoles().subscribe({
        next: response => {
          if (response && response.status === 204) {
            this.notificationsService.flashNotification('danger', apiErrorMessage);
          } else if (response && response.status === 200 && response.body && response.body.values) {
            this.teamRoles = response.body.values;
          } else {
            this.notificationsService.flashNotification('danger', apiErrorMessage);
          }
        },
        error: err => {
          this.loggerService.error('Failed to get roles', err);
          this.notificationsService.flashNotification('danger', apiErrorMessage);
        },
        complete: () => {
          this.spinner.hide();
        }
      })
    );
  }

  /** Custom error messages */
  getErrorMessage(fieldName) {
    if (fieldName === 'EMPLOYEE') {
      return this.employeeSearchForm.get('employee').hasError('required')
        ? 'You must enter an employee name'
        : '';
    }
    if (fieldName === 'ROLE_NAME') {
      return this.employeeSearchForm.get('roleName').hasError('required')
        ? 'You must select a role'
        : '';
    }
    return '';
  }

  /** Role changed */
  roleChanged(event: MatSelectChange) {
    const option: MatOption = (event.source.selected as MatOption);
    this.employeeSearchForm.controls.roleDescription.setValue(option.value.displayValue);
    this.employeeSearchForm.controls.searchName.setValue(option.value.searchValue);
    this.selectedRole = this.employeeSearchForm.controls.roleName.value.value;
    this.foundEmployees = [];
    this.pageInfo.itemCount = 0;
    this.formSubmitted = false;
  }

  /** Find employees */
  search(page: number, perPage: number) {
    this.processing = true;
    this.foundEmployees = [];
    const queryString = {};
    queryString['name'] = this.employeeSearchForm.controls.employee.value;
    if (this.multiSelect) {
      queryString['roleName'] = this.employeeSearchForm.controls.searchName.value;
    }
    queryString['page'] = page;
    queryString['perPage'] = perPage;
    queryString['sortField'] = 'lastName';
    queryString['sortDirection'] = 'asc';
    this.subscription.add(
      this.teamsService.getEmployees(querystring.stringify(queryString)).subscribe({
        next: response => {
        if (response && response.status === 204) {
          this.foundEmployees = [];
        } else if (response && response.status === 200 && response.body) {
          const paginationHeader = response.headers.get('X-Pagination');
          if (paginationHeader) {
            const pagination = JSON.parse(paginationHeader);
            this.pageInfo.itemCount = pagination.itemCount;
            this.pageInfo.currentPage = pagination.currentPage - 1;
          }
          this.foundEmployees = response.body;
        } else {
          this.notificationsService.flashNotification('danger', apiErrorMessage);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get employees', err);
        this.notificationsService.flashNotification('danger', apiErrorMessage);
      },
      complete: () => {
        this.processing = false;
        this.formSubmitted = true;
      }})
    );
  }

  /** Pagination */
  onPagination(event: PageEvent) {
    this.pageInfo.perPage = event.pageSize;
    this.search(event.pageIndex + 1, this.pageInfo.perPage);
  }

  /** Add employee to Selected Employees */
  addEmployeeToList(employee) {
    const roles: Role[] = [];
    if (this.multiSelect) {
      roles.push({
        name: this.employeeSearchForm.controls.roleName.value.value,
        description: this.employeeSearchForm.controls.roleDescription.value
      });
    }
    const selectedEmployee: SelectedEmployee = {
      id: employee.id,
      name: employee.name,
      roles: roles,
      checked: true };
    if (!this.multiSelect) {
      this.dialogRef.close(selectedEmployee);
      return;
    }
    const foundEmployee = this.selectedEmployees.find(selected => selected.id === selectedEmployee.id);
    if (!foundEmployee) {
      this.selectedEmployees.push(selectedEmployee);
    } else {
      if (foundEmployee.roles.findIndex(role => role.name === selectedEmployee.roles[0].name &&
        role.description === selectedEmployee.roles[0].description) === -1) {
          foundEmployee.roles.push(selectedEmployee.roles[0]);
        }
        foundEmployee.checked = true;
    }
    this.formSubmitted = false;
    this.updateState();
  }

  /** Remove selected employee / role combination */
  removeEmployee(employee: SelectedEmployee, role: string) {
    const index = this.selectedEmployees.findIndex(item => item.id === employee.id);
    this.selectedEmployees[index].roles = this.selectedEmployees[index].roles.filter(item => item.description !== role);
    if (this.selectedEmployees[index].roles.length === 0) {
      this.selectedEmployees.splice(index, 1);
    }
    this.updateState();
  }

  /** Update selected employee count and selected roles array */
  updateState() {
    this.selectedEmployeesCount = this.selectedEmployees.filter(item => item.checked).length;
    const tempRoles: string[] = [];
    for (const employee of this.selectedEmployees) {
      const roles = employee.roles.map(role => role.description);
      tempRoles.push(...roles);
    }
    this.selectedRoles = tempRoles.filter((role, index) => tempRoles.indexOf(role) === index);
    this.selectedRoles.sort((a, b) => (a > b) ? 1 : -1);
  }

  /** Closes dialog and returns Selected Employees */
  save() {
    const checked = this.selectedEmployees.filter(employee => employee.checked);
    this.dialogRef.close(checked);
  }

  /** Closes dialog and clears the form */
  cancel(): void {
    this.employeeSearchForm = this.formBuilder.group({
      employee: [''],
      roleName: [''],
      roleDescription: [''],
      searchName: ['']
    });
    this.dialogRef.close();
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
