import { Pipe, PipeTransform } from '@angular/core';
import { SelectedEmployee } from '../models/teams.model';

@Pipe({
  name: 'teamRole',
  pure: false
})
export class TeamRolePipe implements PipeTransform {
  transform(items: SelectedEmployee[], filter: Object): any {
    if (!items || !filter) {
        return items;
    }
    if (filter['role']) {
      return items.filter(item => item.roles.filter(role => role.description === filter['role']).length > 0);
    } else {
      return items;
    }
  }
}
