import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { ProjectAlphaModule } from '../public/project-alpha/project-alpha.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { TeamsRoutingModule } from './teams-routing.module';
import { TeamsListComponent } from './components/teams-list/teams-list.component';
import { AddTeamComponent } from './components/add-team/add-team.component';
import { TeamsHeaderComponent } from './components/teams-header/teams-header.component';
import { TeamDetailsComponent } from './components/team-details/team-details.component';
import { TeamProfileComponent } from './components/team-profile/team-profile.component';
import { TeamClientsComponent } from './components/team-clients/team-clients.component';
import { TeamEmployeesComponent } from './components/team-employees/team-employees.component';
import { TeamEmployeeSearchComponent } from './components/team-employee-search/team-employee-search.component';
import { TeamClientSearchComponent } from './components/team-client-search/team-client-search.component';
import { TeamSearchComponent } from './components/team-search/team-search.component';
import { TeamRolePipe } from './pipes/team-role.pipe';
import { TeamMemberPipe } from './pipes/team-member.pipe';
import { TeamClientPipe } from './pipes/team-client.pipe';

@NgModule({
  declarations: [
    HighlightSearchPipe,
    TeamsListComponent,
    AddTeamComponent,
    TeamsHeaderComponent,
    TeamDetailsComponent,
    TeamProfileComponent,
    TeamClientsComponent,
    TeamEmployeesComponent,
    TeamEmployeeSearchComponent,
    TeamClientSearchComponent,
    TeamSearchComponent,
    TeamRolePipe,
    TeamMemberPipe,
    TeamClientPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ProjectAlphaModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    TeamsRoutingModule
  ]
})
export class TeamsModule { }
