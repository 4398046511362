import { Injectable } from '@angular/core';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { urlType } from 'src/app/core/models/urlType';
import { RoleCapability } from '../models/rolecapability.model';
import { Definition } from '../models/definition.model';

@Injectable({
  providedIn: 'root'
})
export class RoleCapabilityService {

  /** Base constructor method
   * @param baseClientService BaseClientService injection
   * @param customLogger: LoggerService injection
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly customLogger: LoggerService
  ) { }

  // #region role capabilities

  /**
   * Return the Role Capability for the specfied id
   * @param roleCapabilityId rolecapabilityId
   */
  getRoleCapability(roleCapabilityId: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/role-capability/${roleCapabilityId}`, 'Get RoleCapability by id', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get RoleCapability', err);
        })
      );
  }

  /**
   * Return all of the Role Capabilities for the specfied filter
   * @param queryString querystring values
   */
  getRoleCapabilities(queryString: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/role-capability?${queryString}`, 'Get RoleCapabilities', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get RoleCapabilities', err);
        })
      );
  }

  /**
   * Return true if role association exists else return false
   * @param queryString querystring values
   */
  doesRoleAssociationExist(queryString: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/role-capability/doesRoleAssociationExist?${queryString}`, 'Get doesRoleAssociationExist', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get Roles Associates', err);
        })
      );
  }

    /**
   * Return Role Capabilities for the specfied roleName
   * @param queryString querystring values
   */
    getRoleCapByRoleName(queryString: string): Observable<any> {
      return this.baseClientService
        .get<any>(`/role-capability/getRoleCapByRoleName?${queryString}`, 'Get RoleCapabilities', urlType.opdata)
        .pipe(
          map(r => r),
          catchError(err => {
            return this.catchBlock('Failed to get RoleCapabilitiesByRoleName', err);
          })
        );
    }

  /**
   * Delete an existing RoleCapabaility
   * @param roleCapId: string
   */
  deleteRoleCapabaility(roleCapId: string): Observable<any> {
    return this.baseClientService
      .delete<any>(`/role-capability/${roleCapId}`, 'Delete Role Capability', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to delete Role Capability', err);
        })
      );
  }

  /**
   * Create a new Role Capability
   * @param roleCapability RoleCapability
   */
  createRoleCapability(roleCapability: RoleCapability): Observable<any> {
    return this.baseClientService
      .post<any>('/role-capability', roleCapability, 'Create RoleCapability', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to create RoleCapability', err);
        })
      );
  }

  /**
   * Update an existing Role Capability
   * @param roleCapabilityId: string
   * @param roleCapability: RoleCapability
   */
  updateRoleCapability(roleCapabilityId: string, roleCapability: RoleCapability): Observable<any> {
    return this.baseClientService
      .put<any>(`/role-capability/${roleCapabilityId}`, roleCapability, 'Update RoleCapability', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to update RoleCapability', err);
        })
      );
  }

  // #endregion

  // #region definitions

  /**
   * Return the Definition for the specfied id
   * @param definitionId definitionId
   */
  getDefinition(definitionId: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/definition/${definitionId}`, 'Get Definition by id', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get Definition', err);
        })
      );
  }

  /**
   * Return all of the Definitions for the specfied filter
   * @param queryString querystring values
   */
  getDefinitions(queryString: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/definition?${queryString}`, 'Get Definitions', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get Definitions', err);
        })
      );
  }

  /**
   * Return all of the Role Definitions
   */
  getRoleDefinitions(): Observable<any> {
    return this.baseClientService
      .get<any>(`/definition?definitionType=role`, 'Get Role Definitions', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return of(this.catchBlock('Failed to get Role Definitions', err));
        })
      );
  }

  /**
   * Return all of the Capability Definitions
   */
  getCapabilityDefinitions(): Observable<any> {
    return this.baseClientService
      .get<any>(`/definition?definitionType=capability`, 'Get Capability Definitions', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get Capability Definitions', err);
        })
      );
  }

  /**
   * Create a new Definition
   * @param definition Definition
   */
  createDefinition(definition: Definition): Observable<any> {
    return this.baseClientService
      .post<any>('/definition', definition, 'Create Definition', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to create Definition', err);
        })
      );
  }

  /**
   * Update an existing Definition
   * @param definitionId: string
   * @param definition: Definition
   */
  updateDefinition(definitionId: string, definition: Definition): Observable<any> {
    return this.baseClientService
      .put<any>(`/definition/${definitionId}`, definition, 'Update Definition', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to update Definition', err);
        })
      );
  }

  /**
   * Delete an existing Definition
   * @param definitionId: string
   */
  deleteDefinition(definitionId: string): Observable<any> {
    return this.baseClientService
      .delete<any>(`/definition/${definitionId}`, 'Delete Definition', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to delete Definition', err);
        })
      );
  }

  // #endregion

  // #region definitions

  /**
   * Return valuelists for the specfied filter
   * @param queryString querystring values
   */
  getValuelist(queryString: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/value-list?${queryString}`, 'Get valuelist', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get Valuelist', err);
        })
      );
  }

  /**
   * Update an existing Valuelist
   * @param valuelistId: string
   * @param valuelist: Valuelist
   */
  updateValuelist(valuelistId: string, valuelist): Observable<any> {
    return this.baseClientService
      .put<any>(`/value-list/${valuelistId}`, valuelist, 'Update Valuelist', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to update Valuelist', err);
        })
      );
  }

  // #endregion

  catchBlock(message: string, error: any) {
    this.customLogger.error(message, error);
    return of(error);
  }

}
