import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '../models/Menu.model';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class MenuSetupService {

  allMenuItems = [
    {
      path: 'project-alpha/client-contacts',
      label: 'Client Contacts',
      description: 'Client Contacts',
      icon: 'perm_contact_calendar',
      sortOrder: 1,
      children: [],
      capability: 'Manage Client Contacts'
    },
    {
      path: 'project-alpha/cartus-employee',
      label: 'Employees',
      description: 'Employees',
      icon: 'group',
      sortOrder: 2,
      children: [],
      capability: 'Assign Employee Roles'
    },
    {
      path: 'project-alpha/teams',
      label: 'Manage Teams',
      description: 'Manage Teams',
      icon: 'group_work',
      sortOrder: 3,
      children: [],
      capability: 'Manage Teams'
    },
    {
      path: 'project-alpha/account-manager',
      label: 'Account Managers',
      description: 'Account Managers',
      icon: 'supervisor_account',
      sortOrder: 4,
      children: [],
      capability: 'Account Manager to Client Association'
    },
    {
      path: 'project-alpha/consultants',
      label: 'Consultants',
      description: 'Consultants',
      icon: 'groups',
      sortOrder: 5,
      children: [],
      capability: 'Employee to Client Association'
    },
    {
      path: 'project-alpha/rolecaps',
      label: 'Role/Capability Maintenance',
      description: 'Role And Capability Maintenance',
      icon: 'admin_panel_settings',
      sortOrder: 6,
      children: [],
      capability: 'Role Capability Maintenance'
    },
    {
      path: 'project-alpha/callsummarizer',
      label: 'Call Summarizer',
      description: 'Call Summarizer',
      icon: 'record_voice_over',
      sortOrder: 7,
      children: [],
      capability: 'MOB - Call Summarizer'
    },
    {
      path: 'project-alpha/user-alignment',
      label: 'User Alignment',
      description: 'User Alignment',
      icon: 'supervisor_account',
      sortOrder: 8,
      children: [],
      capability: 'Manage User Alignment'
    },
    {
      path: 'project-alpha/entitlement-manager',
      label: 'Entitlement Manager',
      description: 'Entitlement Manager',
      icon: 'supervisor_account',
      sortOrder: 9,
      children: [],
      capability: 'MOB - Entitlement Manager'
    },
    {
      path: 'project-alpha/hotel-leads',
      label: 'Hotel Leads',
      description: 'Hotel Leads',
      icon: 'hotel',
      sortOrder: 10,
      children: [],
      capability: 'MOB - Hotel Leads'
    }
  ];

  menuItems: BehaviorSubject<Array<MenuItem>> = new BehaviorSubject<Array<MenuItem>>(null);

  constructor(
    private readonly featureFlagService: FeatureFlagService
  ) { }

  async filterMenuItems(capabilities: Array<string>) {
    const enableTeams = await this.featureFlagService.getFeatureFlag('Manage Teams');
    const enableUserAlignment = await this.featureFlagService.getFeatureFlag('User Alignment');
    const enableEntitlementManager = await this.featureFlagService.getFeatureFlag('Entitlement Manager');
    if (!enableTeams) this.allMenuItems = this.allMenuItems.filter(item => item.label !== 'Manage Teams');
    if (!enableUserAlignment) this.allMenuItems = this.allMenuItems.filter(item => item.label !== 'User Alignment');
    if (!enableEntitlementManager) this.allMenuItems = this.allMenuItems.filter(item => item.label !== 'Entitlement Manager');
    const filteredMenuItems: Array<MenuItem> = [...this.allMenuItems].filter(item => capabilities.includes(item.capability) || !item.capability);
    this.menuItems.next(filteredMenuItems);
  }

}
