<!-- total consultant count -->
<div Fxflex="100%" class="active-header-status-container emp-headercontainer">
    <div Fxflex="100%" fxLayout="row">
      <div fxLayout="column" class="active-text-container consultant-active">
        <h1 class="active-txtcount">
          <span class="active-total-candidate">{{childObj.totalEmployeesCount}}</span>
          <span class="active-total-candidate-txt">Consultants</span>
        </h1>
      </div>
    </div>
  </div>
  <!-- total consultant count ends -->
  <!-- search container -->
  <section class="search_table">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span fxFlex="60%" fxLayoutAlign="start">
        <mat-form-field appearance="outline" class="search-within-table search-box">
          <mat-icon matPrefix id="search_icon">search</mat-icon>
          <input matInput id="searchInput" (input)="applyFilter($event.target.value)"
            placeholder="Search within table for..." width="80%" fxFlex.sm="65" aria-label="Search within table">
        </mat-form-field>
      </span>
    </div>
  </section>
  <!-- search container ends -->
  <p></p>
  <app-consultant-details [filterText]="filterText"></app-consultant-details>