import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { teamClientsTitle, allClientsConfirmTitle, allClientsConfirmLines, removeClientsTitle,
         removeClientsLines, apiErrorMessage, apiSuccessMessage } from '../../models/constants';
import { Team, Client, SelectedClient, UpdateClients } from '../../models/teams.model';
import { TeamClientSearchComponent } from '../team-client-search/team-client-search.component';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-team-clients',
  templateUrl: './team-clients.component.html',
  styleUrls: ['./team-clients.component.scss']
})
export class TeamClientsComponent implements OnInit, OnChanges {
  /** Selected Team */
  @Input() team: Team;
  /** Team changed emitter */
  @Output() teamChange = new EventEmitter<{updated: boolean, team: Team}>();

  /** Has the profile form changed */
  @Input() profileFormChanged: boolean;

  /** To check for readonly access */
  disable = false;

  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Displayed table columns */
  displayedColumns: string[] = [
    'clientName',
    'clientNo',
    'select'
  ];

  /** Table datasource */
  dataSource: any;

  /** Table data */
  ELEMENT_DATA = [];

  /** All Clients */
  includeAll = false;

  /** Pagination */
  pageInfo = {
    pageSize: 5,
    pageIndex: 0,
    totalCount: 0
  };

  /** Selected clients */
  selection = new SelectionModel<Client>(true, []);

  /** Title */
  title = teamClientsTitle;

  /** Dialog Ref */
  dialogRef: MatDialogRef<any>;

  /** Injecting dependencies */
  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }

  /** Init */
  ngOnInit() {
    this.loadClients();
  }

  /** Load Clients */
  loadClients() {
    this.spinner.show();
    if (this.team) {
      if (this.team.includeAll && this.team.includeAll === true) {
        this.includeAll = true;
        this.ELEMENT_DATA = [{id: 'includeAll', name: 'All Clients', number: 'n/a' }];
      } else {
        this.includeAll = false;
        this.ELEMENT_DATA = this.team.children;
      }
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    } else {
      this.ELEMENT_DATA = [];
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    }
    this.spinner.hide();
  }

  /** Sorting accessor */
  sortingDataAccessor = (item, property) => {
    switch (property) {
      case 'clientName': return item.name;
      case 'clientNo': return item.number;
      default: return item[property];
    }
  }

  /** Are all rows are selected */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects / clears all rows */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** Check label for passed row */
  checkboxLabel(row?: Client): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  /** Remove clients confirm */
  openRemoveClientsDialog(): void {
    const clientCount = this.selection.selected.length;
    let dialogTitle = removeClientsTitle;
    const dialogLines = [...removeClientsLines];
    if (clientCount === 1 && this.selection.selected[0].id === 'includeAll') {
      dialogTitle = dialogTitle.replace('Clients', 'All Clients');
      dialogLines[0] = dialogLines[0].replace('$ClientCount', 'All Clients');
    } else if (clientCount === 1) {
      dialogTitle = dialogTitle.replace('Clients', 'Client');
      dialogLines[0] = dialogLines[0].replace('$ClientCount', `${clientCount} Client`);
    } else {
      dialogLines[0] = dialogLines[0].replace('$ClientCount', `${clientCount} Clients`);
    }
    dialogLines[0] = dialogLines[0].replace('$TeamName', this.team.name);
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle, dialogLines },
      autoFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.removeClients();
        }
      })
    );
  }

  /** Remove selected clients */
  removeClients() {
    const update: UpdateClients = {
      includeAll: false,
      clients: []
    };
    if (this.selection.selected.length === 1 && this.selection.selected[0].id === 'includeAll') {
      update['includeAll'] = false;
    } else {
      update['clients'] = this.selection.selected.map(({ id, ...other }) => id);
    }
    this.spinner.show();
    this.teamsService.removeClients(this.team.id, update).subscribe({
      next: response => {
      this.updateState(response);
    },
    error: err => {
      this.loggerService.error('Failed to remove clients from team', err);
      this.notificationsService.flashNotification('danger', apiErrorMessage);
    },
    complete: () => {
      this.spinner.hide();
    }});
  }

  /** Client Search */
  openClientSearchDialog(): void {
    this.dialogRef = this.dialog.open(TeamClientSearchComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { multiSelect: true, team: this.team },
      autoFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((selectedClients: SelectedClient[]) => {
        if (selectedClients) {
          const update: UpdateClients = {
            includeAll: false,
            clients: selectedClients.map(({ id, ...other }) => id)
          };
          this.addClients(update);
        }
      })
    );
  }

  /** Add all clients confirm */
  openAllClientConfirmDialog(): void {
    const dialogTitle = allClientsConfirmTitle;
    const dialogLines = allClientsConfirmLines;
    dialogLines[0] = dialogLines[0].replace('$TeamName', this.team.name);
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle, dialogLines },
      autoFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          const update: UpdateClients = {
            includeAll: true
          };
          this.addClients(update);
        }
      })
    );
  }

  /** Add clients / all clients */
  addClients(update: UpdateClients) {
    this.spinner.show();
    this.teamsService.addClients(this.team.id, update).subscribe({
      next: response => {
      this.updateState(response);
    },
    error: err => {
      this.loggerService.error('Failed to add clients to team', err);
      this.notificationsService.flashNotification('danger', apiErrorMessage);
    },
    complete: () => {
      this.spinner.hide();
    }});
  }

  /** Emit team change event */
  updateState(response: HttpResponse<any>) {
    if (response && response.status === 200) {
      const team: Team = response.body;
      this.notificationsService.flashNotification('success', apiSuccessMessage);
      this.teamChange.emit({ updated: true, team: team });
    } else {
      this.notificationsService.flashNotification('danger', apiErrorMessage);
    }
  }

  /** Update component following team update */
  ngOnChanges(changes: SimpleChanges) {
    if ('team' in changes) {
      this.selection.clear();
      this.loadClients();
      this.cdr.detectChanges();
    }
  }

  isDisabled(flag) {
    this.disable = flag;
  }

}
