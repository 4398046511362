<section class="content_table">
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
        <table mat-table class="mat-elevation-z8 emp-datatable" summary="Employee details" [dataSource]="dataSource" matSort
          [matSortActive]="sortBy" matSortDirection="asc" aria-describedby="table-employee-details"
          (matSortChange)="sortData($event)">
          <!-- Full Name Column -->
          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Full name"> Full Name </th>
            <td mat-cell *matCellDef="let element" class="emp-name">
              <span [innerHTML]="element.name | highlightSearch: filterText"></span>
            </td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="emailAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Email"> Email </th>
            <td mat-cell *matCellDef="let element" class="emp-name">
              <span [innerHTML]="element.emailAddress? (element.emailAddress | highlightSearch: filterText): ''"></span>
            </td>
          </ng-container>

          <!-- Roles Column -->
          <ng-container matColumnDef="role" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> Roles </th>
            <td mat-cell *matCellDef="let element">
              <a href="javascript:void(0);" class="addrole-btn" role="button" (click)="openModal(element)">
                <span *ngIf="element.roles.length>0" class="emp-role-circle">{{element.roles.length}} <span class="cdk-visually-hidden">to</span></span>
                <span *ngIf="element.roles.length>0" click>Review</span>
                <span *ngIf="element.roles.length===0" click>Add</span>
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedEmpColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedEmpColumns;"></tr>
        </table>
      </div>
      <div [hidden]="!(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)">
        <mat-paginator [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]"
          (page)="onPagination($event)"></mat-paginator>
      </div>
    </section>
  </div>
  <div [hidden]="!dataSource || (ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)" class="invalidData" role="alert">
    No records found
  </div>
</section>