import { Component, OnInit, OnDestroy, ViewChild, HostListener, NgZone, ViewContainerRef, ComponentRef, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { AppConfigService } from './core/services/app-config.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, 
         MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
         MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { UserContextService } from './core/services/user-context.service';
import { SessionTimeoutComponent } from './public/project-alpha/session-timeout/session-timeout.component';
import { PartySharedService } from './core/services/party-shared.service';
import { AuthenticationService } from './core/services/authentication.service';
import { BrowserStorageService } from './core/services/browser-storage.service';
import { UserContext } from './core/models/user-context.model';
import { MenuComponent } from './core/components/menu/menu.component';

/** Base application component - builds the site structure */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('menuContainer', { read: ViewContainerRef, static: true })
  public viewRef!: ViewContainerRef;
  menu: ComponentRef<MenuComponent> | undefined;

  menuItems = [
    {
      icon: 'settingsIcon',
      title: 'Settings',
      link: '/project-alpha/account-settings'
    }, {
      icon: 'logoutIcon',
      title: 'Logout',
      link: '/logout'
    }
  ];

  /** nav api subscription */
  private readonly navSub: Subscription;

  loggedInUserName: string;
  loggedInUserInitials: string;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /**Session Timeout */
  // MouseOver axis
  lastX = 0;
  lastY = 0;
  idleTimeoutMinutes = 15; // Idle Session Timeout in Minutes
  idleWarningMsgMinutes = 2; // Idle Session Warning Msg Time in Minutes
  idlelastActionKeyName = 'lastAction';
  pollInterval = 1000;
  sessionTimeoutFlag = true;
  refreshTokenInterval: any;
  refreshTokenTimeout = 9; // 3 chances to update in a 30 minute window
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  timeoutConfig: any = {
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
  };
  /** snackbar ref */
  snackRef: MatSnackBarRef<SessionTimeoutComponent>;

  /**
   * @param snackBar to get MatSnackBar
   */
  /** Component instantiation */
  previousUrl: string = null;
  currentUrl: string = null;
  constructor(
    public readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly titleService: Title,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly updates: SwUpdate,
    private readonly cookieService: CookieService,
    private readonly snackbar: MatSnackBar,
    private readonly ngZone: NgZone,
    private readonly partySharedService: PartySharedService,
    private readonly userContextService: UserContextService,
    private readonly browserStorageService: BrowserStorageService
  ) {
    matIconRegistry.addSvgIcon(
      'logoutIcon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/logout_icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'settingsIcon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/settings.svg'
      )
    );
  }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    this.updates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        // Reload the page to update to the latest version.
        document.location.reload();
      });
    this.router.events.subscribe(event => {
      // if ((event instanceof NavigationEnd) && ((window as any).ga)) {
      //   // Google Analytics
      //     (window as any).ga('set', 'page', `${window.location.pathname}#${event.urlAfterRedirects}`);
      //     (window as any).ga('send', 'pageview');
      // }
      if (event instanceof NavigationEnd) {
        const pageTitle = event.urlAfterRedirects.replace(/(^\/)|(\/$)/g, ''); //remove slashes from the page title
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        // Google Analytics
        // if ((<any>window).ga) {
        //   (<any>window).ga('set', 'page', window.location.pathname + '#' + event.urlAfterRedirects);
        //   (<any>window).ga('send', 'pageview');
        // }
        // Google Tag Manager
        if ((<any>window).dataLayer) {
          (<any>window).dataLayer.push({
            event: 'pageview',
            page: {
              path: window.location.pathname + '#' + event.urlAfterRedirects
            }
          });
          // GTM Implementation for page navigation
          (<any>window).dataLayer.push({
            event: 'virtualPageview',
            pageUrl: window.location.href,
            pageTitle: pageTitle,
            pageReferrer:this.previousUrl
          });
        }
      }
    });
    // if ((window as any).ga) {
    //   const googleAnalyticsConfig: { [key: string]: string } = this.appConfig.getConfig('google_analytics');
    //   (window as any).ga('create', googleAnalyticsConfig.key, 'auto'); // Start Google Analytics session
    // }

    if (this.isIE() === null) {
      /*Checking for cookies availability else redirect to login*/
      const sessionCookie: string = this.cookieService.get('car-ses-tok');
      if (sessionCookie === null || sessionCookie.length === 0) {
        this.logout();
      }
      const currTimeMs = new Date().getTime();
      const idleLastAction = this.browserStorageService.getSessionStorageValue(this.idlelastActionKeyName);
      const idleExpireMs = parseInt(idleLastAction, 10);
      if (!!idleExpireMs && currTimeMs > idleExpireMs) {
        this.router.navigate(['/logout']);
      }
      this.getLoggedInUserDetails();
      this.titleService.setTitle('Mobilify');
      this.refreshSessionInApp();
      this.startIdleTimeCountDown();
    } else {
      this.router.navigate(['not-supported']); // redirect to non-supported url.
    }
  }


  /** Token refresh */
  public refreshSessionInApp() {
    this.refreshTokenInterval = setInterval(() => {
      this.refreshToken();
    }, 60000 * this.refreshTokenTimeout);
  }

  refreshToken() {
    this.authService.trySso().then((freshToken: any) => {
      if (freshToken) {
        if (this.appConfig.getConfig('local')) {
          this.cookieService.set(
            'car-ses-tok',
            freshToken.tokens.accessToken.accessToken,
            undefined,
            undefined,
            undefined,
            undefined,
            'Lax'); // Developer
        } else {
          this.cookieService.set(
            'car-ses-tok',
            freshToken.tokens.accessToken.accessToken,
            null, // We are relying on Okta session expiration
            '/',
            '.cartus.com',
            true
          );
        }
      }
    })
      .catch(err => {
        console.log('error in freshToken :', err);
      });
  }

  getLoggedInUserDetails(): void {
    this.subscription.add(
      this.userContextService
        .getLoggedInUserDetails()
        .subscribe(response => {
          this.partySharedService.updateData(response.userId);
          this.loggedInUserName = response.name.replace(/ .*/, '');
          const matches = response.name.match(/\b(\w)/g);
          this.loggedInUserInitials = matches.join('');
          this.browserStorageService.setSessionStorageValue('car-ses-con', response.userId);
          const userContext: UserContext = {
            name: response.name
          };
          this.browserStorageService.setSessionStorageValue('UserContext', JSON.stringify(userContext));
          this.addMenu();
        })
    );
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.navSub && !this.navSub.closed) {
      this.navSub.unsubscribe();
    }
  }

  // Session Timeout begins

  /** Listen for mouse events */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    if (this.sessionTimeoutFlag && (e.pageX !== this.lastX || e.pageY !== this.lastY)) {
      this.lastX = e.pageX;
      this.lastY = e.pageY;
      this.refreshIdleCookie();
    } else if (this.snackRef) {
      this.snackRef.dismiss();
    }
  }

  /** Listen for keypress events */
  @HostListener('document:keypress')
  onKeyPress() {
    if (this.sessionTimeoutFlag) {
      this.refreshIdleCookie();
    } else if (this.snackRef) {
      this.snackRef.dismiss();
    }
  }

  /** Start idle timeout feature */
  startIdleTimeCountDown() {
    this.refreshIdleCookie();
    this.ngZone.runOutsideAngular(() => {
      setTimeout(this.checkIdle.bind(this), this.pollInterval); // Check for one second
    });
  }

  /** Refresh idle timeout cookie */
  refreshIdleCookie() {
    if (this.sessionTimeoutFlag) {
      const idleExpireMs = (new Date().getTime() + (this.idleTimeoutMinutes * 60000) - 500);
      this.browserStorageService.setSessionStorageValue(this.idlelastActionKeyName, idleExpireMs.toString());
    }
  }

  /** Check idle timeout status */
  checkIdle() {
    this.ngZone.run(() => {
      const idleLastAction = this.browserStorageService.getSessionStorageValue(this.idlelastActionKeyName);
      const idleExpireMs = parseInt(idleLastAction, 10);
      const idleWarningMsgTime = (idleExpireMs - (this.idleWarningMsgMinutes * 60000) - 500);
      const currTimeMs = new Date().getTime();
      if (currTimeMs > idleExpireMs) {
        if (this.snackRef) {
          this.snackRef.dismiss();
        }
        this.logout();
      } else {
        if (this.sessionTimeoutFlag && currTimeMs >= idleWarningMsgTime) {
          this.sessionTimeoutFlag = false;
          this.snackRef = this.snackbar.openFromComponent(SessionTimeoutComponent, this.timeoutConfig);
          this.snackRef.afterDismissed().subscribe(() => {
            this.sessionTimeoutFlag = true;
            this.refreshIdleCookie();
          });
        }
        this.ngZone.runOutsideAngular(() => {
          setTimeout(this.checkIdle.bind(this), this.pollInterval); // Check for one second
        });
      }
    });
  }

  logout() {
    this.removeMenu();
    clearInterval(this.refreshTokenInterval);
    this.router.navigate(['/logout']); // redirect to Login page
  }

  //  Session Timeout ends

  /** Check if the browser is IE */
  isIE(): any {
    return /(MSIE|Trident)/.exec(window.navigator.userAgent);
  }

  addMenu(): void {
    this.viewRef.clear();
    this.menu = this.viewRef.createComponent(MenuComponent);
  }

  removeMenu(): void {
    this.viewRef.clear();
  }

  toggleMenu() {
    if (this.menu.instance) {
      this.menu.instance.toggleMenu();
    }
  }

}
