import { Pipe, PipeTransform } from '@angular/core';
import { Client, SelectedClient } from '../models/teams.model';

@Pipe({
  name: 'teamClient',
  pure: false
})
export class TeamClientPipe implements PipeTransform {
  transform(item: Client, filter: Object): any {
    if (!item || !filter) {
      return false;
    }
    if (filter['team'] && filter['team'].children && filter['selectedClients']) {
      return (
        filter['team'].children.find((client: Client) => client.id === item.id) !== undefined ||
        filter['selectedClients'].find((client: SelectedClient) => client.id === item.id) !== undefined
        );
    } else if (filter['team'] && !filter['team'].children && filter['selectedClients']) {
      return (
        filter['selectedClients'].find((client: SelectedClient) => client.id === item.id) !== undefined
        );
    } else {
      return false;
    }
  }
}
