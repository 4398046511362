<div class="dialog-form-container">
    <div mat-dialog-title disableTypography className={styles.dialogTitle}>
      <div class="dialog-title">{{ title }}</div>
      <button type="button" class="button-icon" (click)="cancel()" title="close" [disabled]="processing">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <form [formGroup]="addEditRoleForm">
      <div class="dialog-middle-section">
        <mat-dialog-content>
          <div>
            <div class="row">
              <div class="col50">
                <mat-form-field>
                  <input id="RoleName" matInput placeholder="Role Name" formControlName="roleName" (blur)="roleNameBlur()">
                  <mat-error>{{ getErrorMessage('ROLE_NAME') }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col50">
                <mat-form-field>
                  <input id="FriendlyName" matInput placeholder="Friendly Name" formControlName="friendlyName" (blur)="friendlyNameBlur()">
                  <mat-error>{{ getErrorMessage('FRIENDLY_NAME') }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col50">
                <mat-form-field>
                  <mat-select panelClass="select-container" (openedChange)="openedChange($event)"
                    placeholder="Dependencies" formControlName="dependencies" multiple disableOptionCentering
                    [disableRipple]="true">
                    <mat-select-trigger>
                      {{addEditRoleForm.controls.dependencies.value ? addEditRoleForm.controls.dependencies.value[0] : ''}}
                      <span *ngIf="addEditRoleForm.controls.dependencies.value?.length > 1" class="additional-selection">
                        (+{{addEditRoleForm.controls.dependencies.value.length - 1}}
                        {{addEditRoleForm.controls.dependencies.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-optgroup>
                      <mat-form-field>
                        <input id="search" #search autocomplete="off" placeholder="Search" aria-label="Search" matInput
                          [formControl]="searchTextboxControl">
                        <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
                          (click)="clearSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(filteredOptions | async).length === 0">
                      <div>No results found</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="selectionChange($event)"
                      *ngFor="let option of filteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col50">
                <div class="inline-checkbox">
                  <mat-checkbox *ngIf="addEditRoleForm.controls.dependencies.value && addEditRoleForm.controls.dependencies.value.length > 0" [checked]="false"
                    formControlName="bypassAssociation" [disableRipple]="true" labelPosition="before">
                    Bypass Association
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </mat-dialog-content>
      </div>
      <mat-dialog-actions>
        <button mat-button type="button" class="button-outline-sm" (click)="cancel()" [disabled]="processing">
          <mat-icon>cancel</mat-icon>
          <span>Cancel</span>
        </button>
        <button mat-button type="button" class="button-sm" *ngIf="!editRole" (click)="createOrUpdateRole(OperationType.create)"
          [disabled]="!addEditRoleForm.valid || processing">
          <mat-icon>add_circle</mat-icon>
          <span>Role</span>
        </button>
        <button mat-button type="button" class="button-sm" *ngIf="editRole" (click)="createOrUpdateRole(OperationType.update)"
          [disabled]="!addEditRoleForm.valid || !addEditRoleForm.dirty || processing">
          <mat-icon>check</mat-icon>
          <span>Save Changes</span>
        </button>
      </mat-dialog-actions>
      </form>
    </div>
<app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing"></app-progress-spinner>
