import { Component, HostBinding, OnInit } from '@angular/core';
import { SpringboardApp, SpringboardApps } from '../../models/SpringboardApps.model';
import { MiniSpringboardSetupService } from '../../services/mini-springboard-setup.service';
import { NotificationsService } from '../../services/notifications.service';
import { firstValueFrom } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mini-springboard',
  templateUrl: './mini-springboard.component.html',
  styleUrls: [
    './mini-springboard.component.scss'
  ]
})
export class MiniSpringboardComponent implements OnInit {

  /** Component css class */
  @HostBinding('class') class = 'app-mini-springboard';

  /** applications variable to store the retrieved applications */
  applications: SpringboardApp[];

  /** is springboard open */
  springboardIsOpen: boolean = false;
  
  /** loading */
  loading: boolean = true;

  /**
   * base constructor
   * @param miniSpringboardSetupService Mini Springboard Setup Service injector
   * @param notificationsService Notifications Service injector
   * @param titleService: Title Service injector
   * @param libraryConfigService Library Config Service
   * @param  router: Router
   */
  constructor(
    private readonly miniSpringboardSetupService: MiniSpringboardSetupService,
    private readonly notificationsService: NotificationsService,
    private readonly titleService: Title,
    private readonly router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getApps();
  }

  /**
   * getApps method to get the apps available to the logged in user
   * @param miniSpringboardSetupService Mini Springboard Setup Service injector
   */
  async getApps() {
    this.loading = true;
    this.miniSpringboardSetupService.getApps().subscribe({
      next: (resp: SpringboardApps) => {
        if (resp?.Applications) {
          const currentApp = 'mobilifyHR';
          this.applications = resp.Applications.filter((app: SpringboardApp) => app.name !== currentApp);
        }
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  /**
   * expand the mini springboard container
   */
  expandSpringboard() {
    this.springboardIsOpen = true;
  }

  /**
   * collapse the mini springboard container
   */
  collapseSpringboard() {
    this.springboardIsOpen = false;
  }

  /**
   * toggle the mini springboard container
   */
  toggleSpringboard() {
    this.springboardIsOpen = !this.springboardIsOpen;
  }

  /**
   * To handle the value emitted from child mini-springboard-app component
   * @param valueEmitted - app name
   */
  async appSelectedHandler(valueEmitted: string): Promise<void> {
    const app: SpringboardApp | undefined = this.applications.find(app => app.name === valueEmitted);
    let coError: boolean = false;
    if (valueEmitted === 'cartusOnline') {
      const resp = await firstValueFrom(this.miniSpringboardSetupService.getColToken());
      if (!resp || (resp && resp.status !== 200)) {
        coError = true;
      }
    }
    if (!coError) {
      if (app?.url && app.url.indexOf('http') > -1) {
        this.titleService.setTitle('Loading...');
        this.router.navigate(['/externalRedirect', { externalUrl: app.url }], { skipLocationChange: true });
      } else {
        this.notificationsService.flashNotification('danger', `${app.displayName} is currently unavailable.`, false);
      }
    } else {
      this.notificationsService.flashNotification('danger', `${app.displayName} is currently unavailable.`, false);
    }
  }

}
