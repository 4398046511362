import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import {
  inactivateClientContactTitle,
  inactivateClientContactDisplayText1,
  inactivateClientContactDisplayText2
} from 'src/app/core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';


@Component({
  selector: 'app-inactivate-client-contacts',
  templateUrl: './inactivate-client-contacts.component.html',
  styleUrls: ['./inactivate-client-contacts.component.scss']
})
export class InactivateClientContactsComponent implements OnInit {

  /** Title for cost model pop up */
  title = inactivateClientContactTitle;
  /** Text to be displayed - first line */
  displayText1 = inactivateClientContactDisplayText1;
  /** Text to be displayed - last line */
  displayText2 = inactivateClientContactDisplayText2;
  /**
   * base constructor
   * @param data data received from calling component
   * @param dialogRef MatDialogRef component
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InactivateClientContactsComponent>,
    private Logger: LoggerService,
    private loggedInUserService: UserContextService
  ) { }

  /** Function to be called on init  */
  ngOnInit() {
    this.data.selectedClients.sort((firstData, secondData) => (firstData.lastName > secondData.lastName) ? 1 : -1);
  }

  /** Function to be called on cancel/close; returns 'close' to calling component */
  onNoClick() {
    this.dialogRef.close('cancel');
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-INACTIVE_CLIENT', 'INACTIVE_CLIENT_CONTACT');
      });

  }
  /** Function to be called on confirmation; returns 'withdraw' to calling component */
  inactivateClient() {
    this.dialogRef.close('inactivateClients');
  }

}
