export const USER_ALIGNMENT_HEADING = 'User Alignment';
export const PARTY_ID_LENGTH_ERROR = 'The Length should be 24.';
export const PARTY_ID_REQ_ERROR = 'Please enter a Party Id.';
export const PARTY_ID_PATTERN_ERROR = 'Invalid hex String.';
export const GET_PEOPLE_DATA_ERROR = 'The request to retrieve the People data failed';
export const GET_PEOPLE_DATA_ERROR_HEADER = 'There is a problem with your submission';
export const GET_PEOPLE_DATA_ZERO_PARTIES_ERROR = ['None of the entered Party Ids were found', 'At least two valid Party Ids are required to proceed'];
export const GET_PEOPLE_DATA_ONE_PARTY_ERROR = ['Only Party Id $partyId was found', 'At least two valid Party Ids are required to proceed'];
export const GET_PEOPLE_DATA_SUCCESS = 'Found $x of $y entered Party Ids';
export const UPDATE_IDENTITY_PROVIDER_ID_ERROR = 'The request to copy the identityProviderID failed';
export const UPDATE_IDENTITY_PROVIDER_ID_SUCCESS = 'The  identityProviderID was updated successfully';
export const UPDATE_IDENTITY_PROVIDER_ID_DIALOG_LINES = ['The identityProviderID will be copied to the following party records.', 'Do you want to Continue?'];
export const UPDATE_IDENTITY_PROVIDER_ID_DIALOG_TITLE = 'Copy IdentityProviderID';
export const REJECTED_ERROR_ON_UPDATE_IDENTITY_PROVIDER_ID = 'The update of partyId $partyId failed due to the following error: $message';
export const FULFILLED_ON_UPDATE_IDENTITY_PROVIDER_ID = 'PartyId $partyId was updated successfully';
