<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="cdk-visually-hidden" aria-live="polite" aria-atomic="true" [innerHTML]="sortStatement"></div>
<section class="content_table">
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
        <table mat-table class="mat-elevation-z8 emp-datatable" [dataSource]="dataSource" matSort
          [matSortActive]="sortBy" matSortDirection="asc" summary="Consultant Details" aria-describedby="table-employee-details"
          (matSortChange)="sortData($event)">
          <!-- Full Name Column -->
          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Full Name </th>
            <td mat-cell *matCellDef="let element" class="emp-name">
              <span [innerHTML]="element.name | highlightSearch: filterText"></span>
            </td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="emailAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element" class="emp-name">
              <span [innerHTML]="element.emailAddress? (element.emailAddress | highlightSearch: filterText):''"></span>
            </td>
          </ng-container>

          <!-- Assigned Clients Column -->
          <ng-container matColumnDef="associatedClients" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> Assigned Clients </th>
            <td mat-cell *matCellDef="let element">
              <a href="javascript:void(0)" class="addrole-btn" role="button" (click)="openModal(element)">
                <span *ngIf="element.associatedClients.length>0" class="emp-role-circle">{{element.associatedClients.length}}<span class="cdk-visually-hidden">to</span></span>
                <span *ngIf="element.associatedClients.length>0" click>Review</span>
                <span *ngIf="element.associatedClients.length===0" click>Add</span>
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedAccManagerColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedAccManagerColumns;"></tr>
        </table>
      </div>
      <div [hidden]="!(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)">
        <mat-paginator [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[10, 25, 50, 75]"
          (page)="onPagination($event)"></mat-paginator>
      </div>
    </section>
  </div>
  <div [hidden]="!dataSource || (ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)" class="invalidData" role="alert">
    No records found
  </div>
</section>