/** API error message */
export const apiErrorMessage = 'We are unable to process your request at this time. Please try again later.';
/** API success message */
export const apiSuccessMessage = 'Changes were saved successfully.';
/** API team already exists error message */
export const apiTeamAlreadyExists = 'Error: A team already exists with the name $TeamName. Team names must be unique.';
/** API team not found */
export const apiTeamNotFound = 'The Team could not be found.';
/** API team missing team role */
export const apiTeamRoleNotFound = 'Error: This Team is missing the \'team\' role. Please contact IT for assistance.';
/** API add team employees error header */
export const apiEmpNotAddedHeader = 'The following $Noun could not be added to the Team:';
/** API add team employees error footer */
export const apiEmpNotAddedFooter = 'Please contact IT for assistance.';
/** Special characters error message */
export const specialCharactersMessage = 'Special characters are not allowed';
/** Add Team title */
export const addTeamTitle = 'Add Team';
/** Team Profile title */
export const teamProfileTitle = 'Team Profile';
/** Team Employees title */
export const teamEmployeesTitle = 'Employees on Team';
/** Team Clients title */
export const teamClientsTitle = 'Clients on Team';
/** Employee Search Title */
export const employeeSearchTitle = 'Employee Search';
/** Employee Search Title */
export const clientSearchTitle = 'Client Search';
/** Add All Clients Confirm Title  */
export const allClientsConfirmTitle = 'Add All Clients';
/** Add All Clients Confirm Message Lines */
export const allClientsConfirmLines = [
  `You are about to add All Clients to Team $TeamName.`,
  'This will replace any clients that have been added to the Team individually.',
  'Are you sure you would like to continue?'
];
/** Save Team Profile Confirm Title */
export const saveTeamProfileTitle = 'Save Team Profile';
/** Remove Employees Confirm Title */
export const removeEmployeesTitle = 'Remove Employees';
/** Remove Employees Confirm Lines */
export const removeEmployeesLines = [
  'Are you sure you wish to remove $EmployeeCount from Team $TeamName?'
];
/** Remove Clients Confirm Title */
export const removeClientsTitle = 'Remove Clients';
/** Remove Clients Confirm Lines */
export const removeClientsLines = [
  'Are you sure you wish to remove $ClientCount from Team $TeamName?'
];
/** Team Search Title */
export const teamSearchTitle = 'Team Search';
/** Team Statuses */
export const teamStatuses = ['Inactive', 'Active'];
/** Team Profile Form Control Validation Errors */
export const teamProfileFormErrors = {
  name: {
    required: 'You must enter a Team Name',
    pattern: specialCharactersMessage,
    minlength: 'Team Name should be more than one character',
    maxLength: 'Team name should be less than thirty characters'
  },
  owner: {
    required: 'You must select a Team Owner'
  },
  status: {
    required: 'You must select a Team Status'
  },
  effectiveDate: {
    matDatepickerParse: 'You must enter a valid From Date'
  },
  expirationDate: {
    matDatepickerParse: 'You must enter a valid To Date'
  }
};
