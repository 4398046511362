import { Directive, TemplateRef, ElementRef, ViewContainerRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PermissionsService } from '../services/permissions.service';

@Directive({
  selector: '[appReadonly]'
})
export class ReadonlyDirective implements OnInit {

  @Input() appReadonly: any;
  @Output() valueChange = new EventEmitter<any>();

  constructor(private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef, private readonly workflowEvents: PermissionsService) { }

  ngOnInit(): void {
    this.applyPermission(this.appReadonly);
  }

  private applyPermission(value: string | string[]): void {
    this.workflowEvents.doCheckReadAuthorization(value)
      .then(authorized => {
        if (authorized) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.valueChange.emit(true);
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.valueChange.emit(false);
        }
      });
  }

}
