import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Team, Person, UpdateMembers, UpdateMembersApiError, UpdateMembersErrorData } from '../../models/teams.model';
import { TeamEmployeeSearchComponent } from '../team-employee-search/team-employee-search.component';
import { Subscription } from 'rxjs';
import { SelectedEmployee } from '../../models/teams.model';
import { teamEmployeesTitle, removeEmployeesTitle, removeEmployeesLines, apiErrorMessage, apiSuccessMessage,
         apiEmpNotAddedHeader, apiEmpNotAddedFooter } from '../../models/constants';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-team-employees',
  templateUrl: './team-employees.component.html',
  styleUrls: ['./team-employees.component.scss']
})
export class TeamEmployeesComponent implements OnInit, OnChanges {

  /** Selected Team */
  @Input() team: Team;

  /** Team changed emitter */
  @Output() teamChange = new EventEmitter<{updated: boolean, team: Team}>();

  /** Has the profile form changed */
  @Input() profileFormChanged: boolean;

  /** To check for readonly access */
  disable = false;

  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** displayed table columns */
  displayedColumns: string[] = [
    'employeeName',
    'employeeRoles',
    'employeeFromDate',
    'employeeToDate',
    'select'
  ];

  /** table datasource */
  dataSource: any;

  /** table data */
  ELEMENT_DATA = [];

  /** pagination */
  pageInfo = {
    pageSize: 5,
    pageIndex: 0,
    totalCount: 0
  };

  /** selected employees */
  selection = new SelectionModel<Person>(true, []);

  /** Title */
  title = teamEmployeesTitle;

  /** Dialog Ref */
  dialogRef: MatDialogRef<any>;

  /** Injecting dependencies */
   constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }

  /** Init */
  ngOnInit() {
    this.loadEmployees();
  }

  /** Are all rows are selected */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects / clears all rows */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** Check label for passed row */
  checkboxLabel(row?: Person): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  /** Remove employees confirm */
  openRemoveEmployeesDialog(): void {
    const employeeCount = this.selection.selected.length;
    let dialogTitle = removeEmployeesTitle;
    const dialogLines = [...removeEmployeesLines];
    if (employeeCount === 1) {
      dialogTitle = dialogTitle.replace('Employees', 'Employee');
    }
    dialogLines[0] = dialogLines[0].replace('$TeamName', this.team.name);
    dialogLines[0] = dialogLines[0].replace('$EmployeeCount', `${employeeCount} ${employeeCount > 1 ? 'Employees' : 'Employee'}`);
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle, dialogLines },
      autoFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.removeEmployees();
        }
      })
    );
  }

  /** Add employees */
  addEmployees(update: UpdateMembers) {
    this.spinner.show();
    this.teamsService.addEmployees(this.team.id, update).subscribe({
      next: response => {
        this.updateState(response, true);
      },
      error: err => {
        this.loggerService.error('Failed to add employees to team', err);
        this.notificationsService.flashNotification('danger', apiErrorMessage);
      },
      complete: () => {
        this.spinner.hide();
      }}
    );
  }

  /** Remove selected employees */
  removeEmployees() {
    const update: UpdateMembers = {
      employees: this.selection.selected.map(({ id, ...other }) => id)
    };
    this.spinner.show();
    this.teamsService.removeEmployees(this.team.id, update).subscribe({
      next: response => {
      this.updateState(response, false);
      },
      error: err => {
        this.loggerService.error('Failed to remove employees from team', err);
        this.notificationsService.flashNotification('danger', apiErrorMessage);
      },
      complete: () => {
        this.spinner.hide();
      }}
    );
  }

  /** Load Team employees */
  loadEmployees() {
    this.spinner.show();
    if (this.team && this.team.members) {
      this.ELEMENT_DATA = this.team.members;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    } else {
      this.ELEMENT_DATA = [];
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    }
    this.spinner.hide();
  }

  /** Sorting accessor */
  sortingDataAccessor = (item: Person, property: string) => {
    switch (property) {
      case 'employeeName': return item.name.split(' ').reverse().join(' ');
      case 'employeeRoles': return item.roles.map(role => role.description).join('+');
      case 'employeeFromDate': return item.roles[0].fromDate ? new Date(item.roles[0].fromDate) : new Date(null);
      case 'employeeToDate': return item.roles[0].toDate ? new Date(item.roles[0].toDate) : new Date(null);
      default: return item[property];
    }
  }

  /** Employee Search */
  openEmployeeSearchDialog(): void {
    this.dialogRef = this.dialog.open(TeamEmployeeSearchComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { multiSelect: true, team: this.team },
      autoFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((selectedEmployees: SelectedEmployee[]) => {
        if (selectedEmployees) {
          const update: UpdateMembers = {
            employees: selectedEmployees.map(({ checked, ...other }) => other)
          };
          this.addEmployees(update);
        }
      })
    );
  }

  /** Emit Team Change */
  updateState(response: HttpResponse<any>, adding: boolean) {
    if (response && response.status === 200) {
      const team: Team = adding ? response.body.teamResponse : response.body;
      if (response.body.errors && response.body.errors.length > 0) {
        const errorData: UpdateMembersErrorData = this.processErrors(response.body.errors);
        this.notificationsService.flashNotification('warning', errorData);
      } else {
        this.notificationsService.flashNotification('success', apiSuccessMessage);
      }
      this.teamChange.emit({ updated: true, team: team });
    } else {
      this.notificationsService.flashNotification('danger', apiErrorMessage);
    }
  }

  /** Update component following team update */
  ngOnChanges(changes: SimpleChanges) {
    if ('team' in changes) {
      this.selection.clear();
      this.loadEmployees();
      this.cdr.detectChanges();
    }
  }

  isDisabled(flag) {
    this.disable = flag;
  }

  /** Translate errors into user friendly messages */
  processErrors(errors: UpdateMembersApiError[]): UpdateMembersErrorData {
    const errorCount = errors.length;
    const header = apiEmpNotAddedHeader.replace('$Noun', `${errorCount > 1 ? 'employees' : 'employee'}`);
    const data: UpdateMembersErrorData = {
      html: true,
      header: header,
      lines: [],
      footer: apiEmpNotAddedFooter
    };
    const sortedErrors = [...errors].sort((a, b) => (a.employeeDetails.name > b.employeeDetails.name) ? 1 : -1);
    sortedErrors.forEach(error => {
      data.lines.push(`${error.employeeDetails.name} - ${error.message}.`);
    });
    return data;
  }

}
