<ngx-spinner type="ball-spin-clockwise-fade" aria-atomic="true" role="alert" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<!--Team Details starts-->
<div *ngIf="team">
  <div class="teams-nav">
    <a [routerLink]="['/project-alpha/teams']" #breadcrumb>Manage Teams</a><span class="current">{{ teamName }}</span>
  </div>
  <!--Team Profile starts-->
  <app-team-profile [team]="team" (profileFormChange)="profileFormChanged = $event"
  (teamChange)="teamChanged($event)"></app-team-profile>
  <!--Team Profile ends-->
  <!--Team Employees starts-->
  <app-team-employees [team]="team" [profileFormChanged]="profileFormChanged"
  (teamChange)="teamChanged($event)"></app-team-employees>
  <!--Team Employees ends-->
  <!--Team Clients starts-->
  <app-team-clients [team]="team" [profileFormChanged]="profileFormChanged"
  (teamChange)="teamChanged($event)"></app-team-clients>
  <!--Team Clients ends-->
</div>
<!--Team Details ends-->
<!--Team Not Found starts-->
<mat-card *ngIf="notFound" class="team-card-not-found">
  <mat-card-header class="team-card-header">
    <div mat-card-avatar>
      <mat-icon class="team-card-avatar">error</mat-icon>
    </div>
    <mat-card-title class="team-card-title">Not Found</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="team-card-content">
    <p>The Team could not be found.</p>
  </mat-card-content>
</mat-card>
<!--Team Not Found ends-->
