import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { ClientContact } from '../models/client-contact';
import { Client } from '../models/client';
import { formatDate } from '@angular/common';
import { RemoteLoggingService } from './remote-logging.service';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { roleType } from '../models/client-role';
import { APIResponse } from '../models/response.model';
import { CdkAccordion } from '@angular/cdk/accordion';
import { LoggerService } from './logger.service';
import { clientContacts } from 'src/UnitTest-Support/Mocks/data.mock';

@Injectable({
  providedIn: 'root'
})
export class ClientContactService {

  constructor(private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService) { }

  clientContactList: ClientContact[] = [{
    'clientID': '10000',
    'clientContactID': '15898',
    'firstName': 'James',
    'lastName': 'Smith',
    'emailAddress': 'smithj@example.com',
    'phoneNumber': '3124659860',
    'role': [{
      'roleID': '345433',
      'roleName': roleType.initiator,
      'roleDescrpition': 'This user can only view candidates created by themselves.'
    }],
    'isBillingContact': true,
    'invitedAsClientContact': true,
    'status': 'Active',
    'statusDate': '2019-10-10',
    'isDeleted': false,
    countryDialingCode: '+1'
  },
  {
    'clientID': '10001',
    'clientContactID': '44534',
    'firstName': 'Angela',
    'lastName': 'Erickson',
    'emailAddress': 'aerickson@another.com',
    'phoneNumber': '5514720980',
    'role': [{
      'roleID': '345435',
      'roleName': roleType.admin,
      'roleDescrpition': 'This user can view all the candidates created for their client.'
    }],
    'isBillingContact': true,
    'invitedAsClientContact': true,
    'status': 'Active',
    'statusDate': '2019-10-10',
    'isDeleted': false,
    countryDialingCode: '+1'
  }
  ];
  client$ = new BehaviorSubject<Client[]>(null);
  clientList$: Observable<any> = this.client$.asObservable();

  /* Return the candidate json list and loop to display in the table */
  getClientContacts(): Observable<Array<ClientContact>> {
    return this.baseClientService.get<ClientContact>('/clientContact/getAllclientContacts').pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logger('', 'Failed to get client contacts', err);
        const emptyArray: ClientContact[] = [];
        return of(emptyArray);
      })
    );
  }

  /* Return the candidate json list and loop to display in the table */
  getClientContactsfor(clientId: string): Observable<Array<ClientContact>> {
    return this.baseClientService.get<ClientContact>(`/clientContact/getClientContactsForClient?clientID=${clientId}`).pipe(
      map(r => {
        // console.log('API Response : ' + JSON.stringify(r.body, null, 4));
        return r.body;
      }),
      catchError(errMsg => {
        this.logSvc.logger('', 'Failed to get client contacts', errMsg);
        const emptyArray: ClientContact[] = [];
        return of(emptyArray);
      })
    );
    // return of(clientContacts);
  }


  /* Return the candidate json list and loop to display in the table */
  getClientContactsWithClients(): Observable<Array<any>> {
    const activeClients = this.clientContactList.filter(item => item.isDeleted === false);
    return of(activeClients);
  }

  checkStatus(executeFunctionType): string | undefined {
    if (executeFunctionType === 'create') {
      return 'Invitation Sent';
    } else if (executeFunctionType === 'saveDraft') {
      return 'Invitation Not Sent';
    }
  }

  addClientContact(formData, clientID, clientContactID, executeFunctionType, roleDetails, isBillingContact): ClientContact {
    const dateString = this.formatDate(new Date());
    const currentDate = new Date();
    const numberOfDaysToAdd = 30;
    currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);
    const status = this.checkStatus(executeFunctionType);
    if (this.clientContactList.find(v => v.clientContactID === clientContactID) === undefined) {
      const newClientObj: ClientContact = {
        'clientID': clientID,
        'firstName': formData.ClientContactFirstName ? formData.ClientContactFirstName : '',
        'lastName': formData.ClientContactLastName ? formData.ClientContactLastName : '',
        'emailAddress': formData.ClientContactEmailAddress ? formData.ClientContactEmailAddress : '',
        'phoneNumber': formData.PhoneNumber ? formData.PhoneNumber : '',
        'role': [{
          roleID: roleDetails.roleID,
          roleDescrpition: roleDetails.roleDescrpition,
          roleName: roleDetails.roleName
        }],
        'isBillingContact': isBillingContact ? isBillingContact : false,
        'invitedAsClientContact': formData.InviteClientContactCheckBox ? formData.InviteClientContactCheckBox : false,
        'status': status,
        'statusDate': dateString,
        'isDeleted': false,
        countryDialingCode: '+1'
      };
      this.clientContactList.push(newClientObj);
      return newClientObj;
    } else {
      const updateObj = this.clientContactList.filter(function (item) {
        return item.clientID === clientID;
      }).map(function (item) {
        item.firstName = formData.ClientContactFirstName ? formData.ClientContactFirstName : '';
        item.lastName = formData.ClientContactLastName ? formData.ClientContactLastName : '';
        item.emailAddress = formData.ClientContactEmailAddress ? formData.ClientContactEmailAddress : '';
        item.phoneNumber = formData.PhoneNumber ? formData.PhoneNumber : '';
        item.role[0].roleID = roleDetails.roleID;
        item.role[0].roleName = roleDetails.roleName;
        item.role[0].roleDescrpition = roleDetails.roleDescrpition;
        item.isBillingContact = isBillingContact ? isBillingContact : false;
        item.invitedAsClientContact = formData.InviteClientContactCheckBox;
        item.status = 'Active';
        item.statusDate = dateString;
        // console.log('Update Item: ' + item);
        return item;
      });
      return updateObj[0];
    }
  }

  deleteClientContact(clientContactID) {
    const index = this.clientContactList.indexOf(this.clientContactList.find(v => v.clientID === clientContactID), 0);
    this.clientContactList.filter(function (item) {
      return item.clientContactID === clientContactID;
    }).forEach(function (item) {
      item.isDeleted = true;
      return item;
    });
    this.clientContactList.splice(index, 1);
  }

  getBillingContactFor(clientID) {
    return this.clientContactList.find(v => v.clientID === clientID && v.isBillingContact === true);
  }

  updateClientIdForBillingContacts(oldClientId, newClientId) {
    this.clientContactList.forEach(clientContact => {
      if (clientContact.clientID === oldClientId) {
        clientContact.clientID = newClientId;
      }
    });
  }

  createClientContact(request: ClientContact): Observable<APIResponse> {
    return this.baseClientService.post<APIResponse>('/clientContact', request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        this.logSvc.logger('', 'Failed to add candidate details', err);
        return of(err);
      })
    );
  }

  activateClientContact(request: ClientContact): Observable<APIResponse> {
    // console.log('Put request to be sent:' + JSON.stringify(request, null, 4));
    return this.baseClientService.put<APIResponse>(`/clientContact/activateclientContact/${request.clientContactID}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const emptyMsg: APIResponse = null;
        this.logSvc.logger('', 'Failed to activate client details', err);
        return of(err);
      })
    );
  }

  inActivateClientContact(request: ClientContact[]): Observable<APIResponse> {
    return this.baseClientService.put<APIResponse>(`/clientContact/inActivateclientContact/${request[0].clientContactID}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const emptyMsg: APIResponse = null;
        this.logSvc.logger('', 'Failed to inactivate client details', err);
        return of(err);
      })
    );
  }

  updateClientContact(request: ClientContact): Observable<APIResponse> {
    return this.baseClientService.put<APIResponse>(`/clientContact/updateClientContact/${request.clientContactID}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const emptyMsg: APIResponse = null;
        this.logSvc.logger('', 'Failed to update client details', err);
        return of(err);
      })
    );
  }

  /**
   * used to send email invite to client contact
   * @param request candidate to whom invite is to be sent
   */
  sendInviteToClientContact(request: ClientContact): Observable<APIResponse> {
    console.log('Expected Method Call');
    return this.baseClientService
      .post<APIResponse>(`/clientContact/email`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: APIResponse = null;
          this.customLogger.error('sending Invite to client contact Failed', err);
          return of(empty);
        })
      );
  }

  /**
   * used to resend email invite
   * @param request client contact for whom email is to be resent
   */
  reSendInviteToClientContact(request: ClientContact): Observable<APIResponse> {
    return this.baseClientService
      .post<APIResponse>('/clientContact/email/resend', request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const emptyMsg: APIResponse = null;
          this.customLogger.error('Resending Invite to client contact Failed', err);
          return of(emptyMsg);
        })
      );
  }


  toggleDeleteFlag(clientContactID) {
    this.clientContactList.filter(function (item) {
      return item.clientContactID === clientContactID;
    }).forEach(function (item) {
      item.isDeleted = !item.isDeleted;
      return item;
    });
  }

  formatDate(date) {
    return `${date.getFullYear()} + '-' + ${('0' + (date.getMonth() + 1)).slice(-2)} + '-' + ${('0' + date.getDate()).slice(-2)}`;
  }
}
