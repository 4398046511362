import { Location } from './location';

/** Client Contact Section Starts*/
export const addClientContactTitleForCreateClientContact = 'Add Client Contact';
/**Flag mode for Create */
export const addClientContactModelMode = 'create';
/** Title for Edit Client */
export const addClientContactTitleForReviewClientContact = 'Review Client Contact';
/** Title for Edit Client */
export const addClientContactTitleForEditClientContact = 'Edit Client Contact';
/** Title for Delete Client */
export const addClientContactTitleForDeleteClientContact = 'Inactivate Client Contact';
/** Title for Invite Client Contact*/
export const addClientContactTitleforinviteClientContact = 'Send Invite';
/** Title for Submit button on Add Client Dialog*/
export const addClientContactSubmitButtonLabel = 'Send Invite';
/** Title for Submit button on Add Client Dialog*/
export const addClientContactActivateClientContactButtonLabel = 'Activate Client Contact';
/** Title for Submit button on Add Client Dialog*/
export const addClientContactSaveClientContactButtonLabel = 'Save Changes';
/** Client Contact Section Ends*/

/**mock location data */
export const locationData: Location[] = [
  { name: 'NJ, Nutley', id: 'desitnation1' },
  { name: 'GA, Atlanta', id: 'desitnation2' },
  { name: 'TX, Dallas', id: 'desitnation3' },
  { name: 'NJ, Jersey City', id: 'desitnation4' },
  { name: 'TX, Houston', id: 'desitnation2' },
  { name: 'NY, New York City', id: 'desitnation3' },
  { name: 'NY, Fushing', id: 'desitnation4' },
  { name: 'NJ, Edison', id: 'desitnation2' },
  { name: 'NJ, Newark', id: 'desitnation3' }
];
/**mock city data */
export const cityData: Array<string> = ['Newyork', 'Washington', 'Danbury, CT'];
/** response for createCandidate service call */
export const createCandidateResponse = `Added user Successfully`;
/** response for updateCandidate service call */
export const updateCandidateResponse = `Updated user Successfully`;
/** response if duplicate candidate is present */
export const duplicateResponse = `Duplicate`;
/** response for sendInviteToCandidate service call */
export const sendInviteResponse = `E-Mail Invitation Sent Successfully`;
/** response for reSendInviteToCandidate service call */
export const reSendInviteResponse = `E-Mail Invitation ReSent Successfully`;
/** status if invitation is sent successfully */
export const invitationSentStatus = `Invitation Sent`;
/** status if invitation is not sent */
export const invitationNotSentStatus = `Invitation Not Sent`;
/** variable to store cost model title */
export const inactivateClientContactTitle = 'Inactivate Client Contact';
/** variable to store cost model display text - 1 */
export const inactivateClientContactDisplayText1 =
  'You are about to inactivate the following client contact(s):';
/** variable to store  cost model display text - 2 */
export const inactivateClientContactDisplayText2 =
  `They will be removed and you will no longer have access to them and their related details.`;
/** Used to store date formats */
export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input'
  }
};
/** api error message */
export const apiErrorMessage = 'We are unable to process your request at this time. Please try again later.';
/**Title to store error message for phone number */
export const phoneErrorMessage = 'You must enter mobile #';
/**error message to display on bad gateway error */
export const errorMessage = 'We are unable to process your request at this time. Please try again later.';
/** Title to display copyright*/
export const copyright = `© 2000-${new Date().getFullYear()} Cartus Corporation`;
/** skip records for filter */
export const skipRecordCount = 0;
