import { Component, OnDestroy, ɵConsole, OnInit, Inject } from '@angular/core';
import {
  FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors,
  ValidatorFn, AbstractControl,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocationService } from '../../../../core/services/location.service';
import { ClientRoleService } from '../../../../core/services/client-role.service';
import { ClientRole, roleType } from '../../../../core/models/client-role';
import { Client, ClientStatus } from '../../../../core/models/client';
import { ClientContact } from '../../../../core/models/client-contact';
import { Location } from '../../../../core/models/location';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationsService } from '../../../../core/services/notifications.service';

import {
  addClientContactTitleForCreateClientContact,
  addClientContactModelMode,
  addClientContactTitleForReviewClientContact,
  addClientContactTitleForEditClientContact,
  addClientContactTitleForDeleteClientContact,
  addClientContactTitleforinviteClientContact,
  addClientContactSubmitButtonLabel,
  addClientContactActivateClientContactButtonLabel,
  phoneErrorMessage,
  sendInviteResponse,
  reSendInviteResponse,
  invitationSentStatus,
  invitationNotSentStatus,
  addClientContactSaveClientContactButtonLabel,
  createCandidateResponse,
  updateCandidateResponse,
  duplicateResponse,
  errorMessage
} from 'src/app/core/models/constants';
import { ClientServiceService } from 'src/app/core/services/client-service.service';
import { ClientContactService } from '../../../../core/services/client-contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';
import { Extension } from 'src/app/core/models/extension.model';
import { ExtensionService } from 'src/app/core/services/extension.service';

/**
 * Exporting the errormessages
 */
export const errorMessages: { [key: string]: string } = {
  ClientContactFirstName: 'You must enter First Name',
  ClientContactLastName: 'You must enter Last Name',
  ClientContactEmailAddress: '\'You must enter email address',
  ClientContactRole: 'You must select a valid role',
  ClientContactMobileNumber: 'You must enter a valid mobile phone number',
  CompanyName: 'You must select a Company',
  Extension: 'You must select Extension'
};

@Component({
  selector: 'app-add-client-contact',
  templateUrl: './add-client-contact.component.html',
  styleUrls: ['./add-client-contact.component.scss']
})
export class AddClientContactComponent implements OnInit, OnDestroy {
  /**Form group name */
  addClientContactForm: UntypedFormGroup;
  /* Title to display the dialog window page title */
  title = addClientContactTitleForCreateClientContact;
  /**Flag mode for Create */
  mode = addClientContactModelMode;
  /**Assign formready Variable as False */
  formReady = false;
  /**Variables for error */
  errors = errorMessages;
  /**Binding the dropdown values to roles filed */
  clientRoles: ClientRole[];
  /** auto complete values for destination */
  options: Location[];
  /* variable declared for showLoader*/
  showLoader = false;
  /* variable declared for deleteDialog*/
  deleteDialog = false;
  /* variable declared for title for invite as client contact*/
  inviteAsClientContactLabel = addClientContactTitleforinviteClientContact;
  /* variable declared for invite as client contact flag*/
  inviteAsClientContactFlag = false;
  /* variable declared for title for submit button*/
  submitButtonLabel = addClientContactSubmitButtonLabel;
  /** Subscription prop for unsubscribing services */
  private subscription: Subscription = new Subscription();
  /**addCandidate  model of type Candidate */
  addClient: ClientContact = {} as ClientContact;
  /** flag to check phone number valid */
  isPhoneNumberValid = false;
  isEmailValid = false;
  /** flag to show inactive checkbox */
  showInactiveCheckBox = false;
  /** flag to maintain inactive client status */
  inactivateClientFlag = false;
  /** flag to show save draft checkbox */
  showDraftButton = false;
  stateList: any[];
  clientList: Client[];
  clientID = '15898';
  checkFirstTimeEntry = true;
  inactiveButtonLabel = 'Set to Inactive';
  saveDraftButtonLabel = 'Save Draft';
  isInvitationSent = false;
  showResendButton = true;
  roleDetails: ClientRole;
  roleDetailList: ClientRole[] = [];
  /** Binding the drop down values to extension filed */
  extensions: Extension[];
  defaultExtension: any;
  selectedClient: ClientContact;
  /**
   * Initializes form elements
   * @param formBuilder - property for form elements
   * @param dialogRef - property for mat dialog reference
   * @param data - contains popup data
   * @param candidateProfilesService - service parameter
   * @param changeDetectorRef - property for change detections
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddClientContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationService: LocationService,
    private notificationsService: NotificationsService,
    private clientRolesService: ClientRoleService,
    private clientService: ClientServiceService,
    private clientContactService: ClientContactService,
    private spinner: NgxSpinnerService,
    private Logger: LoggerService,
    private loggedInUserService: UserContextService,
    private ExtnSvc: ExtensionService
  ) {
    this.stateList = this.locationService.getStates();
    this.clientService.clientList$.subscribe(result => {
      this.clientList = result;
    });
    this.selectedClient = this.data.selectedClient;
    this.clientRoles = this.clientRolesService.getRoles();
    this.showDraftButton = true;
    /* Create the Add/Edit dialog window */
    this.initializeClientForm();
    /* If the data is present - it will open and pre-populate dialog window */
    if (this.selectedClient) {
      this.checkFirstTimeEntry = false;
      if (this.selectedClient.status === invitationSentStatus) {
        this.isInvitationSent = true;
        this.showResendButton = true;
        this.saveDraftButtonLabel = 'Save Changes';
        this.title = addClientContactTitleForEditClientContact;
      }
      if (this.selectedClient['clientLegalName'] !== undefined) {
        this.title = addClientContactTitleForCreateClientContact;
      } else {
        this.title = addClientContactTitleForEditClientContact;
        if (this.selectedClient.status === 'Active') {
          this.submitButtonLabel = addClientContactSaveClientContactButtonLabel;
          this.showResendButton = false;
          // this.showInactiveCheckBox = true
          this.showDraftButton = true;
          this.saveDraftButtonLabel = 'Save Changes';
          this.isInvitationSent = true;
        } else if (this.selectedClient.status === 'Invitation Not Sent') {
          this.submitButtonLabel = addClientContactSubmitButtonLabel;
          this.showDraftButton = true;
          this.showResendButton = false;
        }
      }
      // console.log('Add client varialble in Init Method : ' + JSON.stringify(this.addClient, null, 4));
      // console.log('data varialble in Init Method : ' + JSON.stringify(this.selectedClient, null, 4));
      //  this.addClient = this.selectedClient
      let clientData;
      if (this.selectedClient['clientLegalName'] === undefined) {
        this.clientID = this.selectedClient['client'].partyID;
        clientData = this.clientList.find(c =>
          c.partyID === this.selectedClient['client'].partyID);
      }
      let roleData = this.clientRolesService.getRoles().find(role => role.displayName === 'Initiator');
      const roleData1 = this.clientRolesService.getRoles();
      if (this.selectedClient['role'] !== null && this.selectedClient['role'] !== undefined) {
        if (this.selectedClient['role'].length > 0) {
          roleData = this.selectedClient.role.find(role =>
            role.name === 'client-contact-administrator' || role.name === 'client-contact-initiator');
          if (roleData !== undefined) {
            this.addClientContactForm.get('ClientContactRole').setValue(roleData1.find(r =>
              r.roleDescrpition === roleData.name).displayName);
          } else {
            this.addClientContactForm.get('ClientContactRole').setValue('');
          }
        }
      }

      /* Setting the default values for form elements in edit candidate dialog */
      this.addClientContactForm.get('ClientContactFirstName').setValue(this.selectedClient.firstName ? this.selectedClient.firstName : '');
      this.addClientContactForm.get('ClientContactLastName').setValue(this.selectedClient.lastName ? this.selectedClient.lastName : '');
      if (this.selectedClient['clientLegalName'] !== undefined) {
        this.addClientContactForm.get('CompanyName').setValue(this.selectedClient['clientLegalName']);
      } else {
        this.addClientContactForm.get('CompanyName').setValue(clientData.clientLegalName);
      }
      this.addClientContactForm.get('Extension').setValue(this.selectedClient.countryDialingCode);
      this.addClientContactForm.get('ClientContactMobileNumber').setValue(this.selectedClient.phoneNumber);
      this.addClientContactForm.get('ClientContactEmailAddress').setValue(this.selectedClient.emailAddress);
      if (this.selectedClient['role'] !== null && this.selectedClient['role'] !== undefined) {
        if (roleData !== undefined) {
          // console.log('Role Data : ' + JSON.stringify(roleData, null, 4));
          this.addClientContactForm.get('ClientContactRole').setValue(this.selectedClient['roleName']);
        } else {
          roleData = this.clientRolesService.getRoles().find(role => role.displayName === 'Initiator');
          this.addClientContactForm.get('ClientContactRole').setValue(roleData.displayName);
        }
      }
      this.addClientContactForm.get('ClientContactRole').setValue(this.selectedClient['roleName']);
      // this.addClientContactForm.get('ClientContactRole').setValue(roleData.displayName)
      // this.addClientContactForm.get('ClientContactRole').setValue(this.selectedClient.role.roleName);

      if (this.addClientContactForm.controls['ClientContactMobileNumber'].value &&
          this.addClientContactForm.controls['ClientContactEmailAddress'].value) {
        this.isEmailValid = true;
        this.isPhoneNumberValid = true;
      }
      // console.log('Validators : ' + this.addClientContactForm.valid);
      // console.log('Mobile Number : ' + this.isPhoneNumberValid);
      // console.log('Email : ' + this.isEmailValid);
      this.addValidators();

      if (this.selectedClient.isDeleted === true) {
        this.title = addClientContactTitleForDeleteClientContact;
        this.deleteDialog = true;
      }
    }
  }

  /**
   * Used for initializing Client Form
   */
  initializeClientForm(): void {
    this.addClientContactForm = this.formBuilder.group({
      // TODO: need to add pattern for ModelName to avoid special character.
      ClientContactFirstName: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      ClientContactLastName: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      CompanyName: [{value: '',  disabled: true},
        Validators.compose([Validators.required])],
      Extension: ['', Validators.required],
      ClientContactMobileNumber: [''],
      ClientContactEmailAddress: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$'),
          this.noWhitespaceValidator
        ])
      ],
      ClientContactRole:  ['', Validators.required]
    });
  }

  checkSaveDraft() {
    return !this.addClientContactForm.controls['ClientContactFirstName'].valid ||
      !this.addClientContactForm.controls['ClientContactLastName'].valid
    || !this.addClientContactForm.controls['ClientContactMobileNumber'].valid ||
      !this.addClientContactForm.controls['ClientContactEmailAddress'].valid;
  }

  /**
   *  Modified incoming value to lowerCase and assigned to const variable filterValue
   * @param value - start character for filter values
   */
  private _filter(value: string): Location[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Click on Submit button inside the addCstModelForm dialog window
   */
  saveClientContact(): void {
    if (this.addClientContactForm.valid) {
      this.spinner.show();
      this.populateClientContactObject();
      const roleDetails = this.clientRolesService.getRoleID(this.addClientContactForm.value.ClientContactRole);
      const clientDetails = this.clientService.getClientDetails(this.addClientContactForm.value.CompanyName);
      // console.log('clientDetails : ' + JSON.stringify(clientDetails, null, 4));
      if (this.selectedClient) {
        const tempID = this.addClient.clientID;
        const updatedObj = this.clientContactService.addClientContact(
          this.addClientContactForm.value,
          clientDetails.clientID,
          this.selectedClient.clientContactID,
          'update',
          this.roleDetails,
          false
        );
        // console.log('Updated Client Conatct : ' + JSON.stringify(updatedObj, null, 4));
        this.dialogRef.close(updatedObj);
        this.spinner.hide();
      } else {
        this.clientContactService
          .createClientContact(this.addClient)
          .subscribe({
            next: response => {
              if (response.statusCode === 200) {
                this.spinner.hide();
                // console.log('Before Client ID Update : ' + JSON.stringify(this.addClient, null, 4));
                this.clientService.updateExistingClientId(this.addClient.clientID, response.candidateID);
                this.addClient.clientContactID = response.clientContactID;
                // console.log('Created Client Contact: ' + JSON.stringify(this.addClient, null, 4));
                this.flashAndCloseDialog(response.message);
              } else {
                // console.log('Error : ', JSON.stringify(response, null, 4));
              }
            },
            error: () => {
              this.spinner.hide();
            }
          });
      }
    }
  }

  /**
   * Click on Submit button inside the addClientContacteForm dialog window
   */
  sendInvite() {
    this.spinner.show();
    this.populateClientContactObject();
    this.addClient.status = 'Invitation Sent';
    if (this.addClient) {
      this.sendInviteToClientContact();
    } else {
      this.sendInviteToClientContact();
    }
  }

  /**send invite to candidate */
  sendInviteToClientContact() {
    // this.addCandidate.executedFunctionType = 'Invite';
    this.clientContactService
      .sendInviteToClientContact(this.addClient)
      .subscribe(response => {
        this.spinner.hide();
        if (response !== null && response.statusCode === 200) {
          this.addClient.clientContactID = response.candidateID;
          this.addClient.inviteSent = true;
          this.flashAndCloseDialog(response.message);
        } else {
          this.notificationsService.flashNotification(
            'success',
            errorMessage,
            true,
            'dismiss'
          );
        }
      });
  }

  resendInvite() {
    this.spinner.show();
    this.populateClientContactObject();
    this.addClient.status = invitationSentStatus;
    delete this.addClient.executedFunctionType;
    if (this.addClient) { // to call duplicate api
      this.resendInviteToClientContact();
    } else {
      this.resendInviteToClientContact();
    }
  }

  /**method to be called on click of resend button */
  resendInviteToClientContact() {
    // this.addCandidate.executedFunctionType = 'Invite';
    this.clientContactService
      .reSendInviteToClientContact(this.addClient)
      .subscribe(response => {
        this.spinner.hide();
        if (response !== null && response.statusCode === 200) {
          this.addClient.clientContactID = response.candidateID;
          this.flashAndCloseDialog(response.message);
        } else {
          this.notificationsService.flashNotification(
            'success',
            errorMessage,
            true,
            'dismiss'
          );
        }
      });
  }

  flashAndCloseDialog(message: string) {
    switch (message) {
      case createCandidateResponse: {
        this.dialogRef.close(this.addClient);
        // this.notificationsService.flashNotification(
        //   'success',
        //   'Client Contact added successfully');
        break;
      }
      case updateCandidateResponse: {
        this.dialogRef.close(this.addClient);
        this.notificationsService.flashNotification(
          'success',
          'Client Contact updated successfully'
        );
        break;
      }
      case duplicateResponse: {
        this.notificationsService.flashNotification(
          'error',
          'Duplicate Client Contact Found, Please inactivate Client Contact to continue',
          true,
          'dismiss'
        );
        this.addClientContactForm.disable();
        break;
      }
      case sendInviteResponse: {
        this.addClient.status = invitationSentStatus;
        this.addClient.invitationSentDate = new Date().toLocaleString();
        this.addClient.statusDate = new Date().toLocaleString();
        this.dialogRef.close(this.addClient);
        this.notificationsService.confirmNotification(
          'Email'
        );
        break;
      }
      case reSendInviteResponse: {
        this.addClient.status = invitationSentStatus;
        this.addClient.invitationSentDate = new Date().toLocaleString();
        this.addClient.statusDate = new Date().toLocaleString();
        this.dialogRef.close(this.addClient);
        this.notificationsService.confirmNotification(
          'Email'
        );
        break;
      }
    }
  }

  saveDraft(): void {
    if (this.checkSaveDraft) {
      this.spinner.show();
      this.populateClientContactObject();
      const roleDetails = this.clientRolesService.getRoleID(this.addClientContactForm.value.ClientContactRole);
      const clientDetails = this.clientService.getClientDetails(this.addClientContactForm.value.CompanyName);
      if (this.selectedClient['clientContactID'] !== undefined) {
        this.clientContactService.updateClientContact(this.addClient).subscribe({
          next: response => {
          this.spinner.hide();
            if (response.statusCode === 200) {
              this.addClient.executedFunctionType = 'Update';
              // console.log('draft Client Contact: ' + JSON.stringify(this.addClient, null, 4));
              this.flashAndCloseDialog(response.message);
            } else {
              this.notificationsService.flashNotification(
                'success',
                errorMessage,
                true,
                'dismiss'
              );
            }
          },
          error: () => {
            this.spinner.hide();
          }}
        );
      } else {
        this.clientContactService.createClientContact(this.addClient).subscribe({
          next: response => {
            if (response.statusCode === 200) {
              this.spinner.hide();
              this.addClient.executedFunctionType = 'Create';
              // console.log('Before Client ID Update : ' + JSON.stringify(this.addClient, null, 4));
              this.clientService.updateExistingClientId(this.addClient.clientID, response.candidateID);
              this.addClient.clientContactID = response.clientContactID;
              // console.log('Added Client Contact: ' + JSON.stringify(this.addClient, null, 4));
              this.flashAndCloseDialog(response.message);
            } else {
              this.notificationsService.flashNotification(
                'success',
                errorMessage,
                true,
                'dismiss'
              );
            }
          },
          error: () => {
              this.spinner.hide();
          }
        });
      }
    }
  }

  inactivateClient() {
    // this.clientService.deleteClient(this.addClient.clientID)
    // console.log('Data to be sent back' + JSON.stringify(this.addClient, null, 4));
    this.addClient.executedFunctionType = 'Inactivate';
    this.dialogRef.close(this.addClient);
  }

  checkForClientContactFields() {
    return this.addClientContactForm.valid || this.addClientContactForm.controls['ClientContactRole'].valid;
  }

  checkForClientContactRole() {
    const validRole = this.addClientContactForm.controls['ClientContactRole'].value === 'Billing Contact' ? false : true;
    // console.log("Client Contact Role : " + validRole)
    // console.log("Email Validity : " + this.isEmailValid)
    // console.log("Phone Validity : " + this.isPhoneNumberValid)
    // console.log("Form Validation : " + this.checkForClientContactFields())
    return validRole;
  }

  populateClientContactObject() {
    // console.log(this.clientRolesService.getRoleID(this.addClientContactForm.value.ClientContactRole));
    const dateString = this.formatDate(new Date());
    const currentDate = new Date();
    const numberOfDaysToAdd = 30;
    currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);
    const clientDetails = this.clientList.find(cc => cc.clientLegalName === this.addClientContactForm.value.CompanyName);
    this.roleDetails = this.clientRolesService.getRoleDetails(this.addClientContactForm.value.ClientContactRole);
    this.roleDetailList.push(this.roleDetails);
    this.addClient = {
      'firstName': this.addClientContactForm.value.ClientContactFirstName !== undefined ?
        this.addClientContactForm.value.ClientContactFirstName : '',
      'lastName': this.addClientContactForm.value.ClientContactLastName !== undefined ?
        this.addClientContactForm.value.ClientContactLastName : '',
      'status': 'Invitation Not Sent',
      'emailAddress': this.addClientContactForm.value.ClientContactEmailAddress !== undefined ?
        this.addClientContactForm.value.ClientContactEmailAddress : '',
      'phoneNumber': this.addClientContactForm.value.ClientContactMobileNumber !== undefined ?
        this.addClientContactForm.value.ClientContactMobileNumber : '',
      'isBillingContact': false,
      'invitedAsClientContact': true,
      'statusDate': dateString,
      'isDeleted': false,
      'role': this.roleDetailList,
      'countryDialingCode':
        (this.addClientContactForm.controls['ClientContactMobileNumber'].value && this.addClientContactForm.controls.Extension.value) ?
          this.addClientContactForm.controls.Extension.value : this.defaultExtension.countryDialingCode ?
            this.defaultExtension.countryDialingCode : ''
    };
    // console.log('Populate data : ' + JSON.stringify(this.addClient, null, 4));

    if (this.selectedClient) {
      this.addClient.clientID = clientDetails.partyID;
      if (this.selectedClient.clientContactID !== undefined && this.selectedClient.clientContactID !== null) {
        this.addClient.clientContactID = this.selectedClient.clientContactID;
        this.addClient.status = this.selectedClient.status;
      }
      // this.addClient.clientPreferredName = this.addClientContactForm.get('ClientPreferredName').value
      // this.addClient.clientLegalName = this.addClientContactForm.get('clientLegalName').value
      // eslint-disable-next-line max-len
      // this.addClient.clientAddress.fullAddress = `${this.addClientContactForm.get('ClientAddress').value}, ${this.addClientContactForm.get('ClientCity').value}, ${this.addClientContactForm.get('ClientState').value}, ${this.addClientContactForm.get('ClientPostalCode').value}, ${this.addClientContactForm.get('ClientCountry').value}`
      // this.addClient.clientAddress.streetAddress = this.addClientContactForm.get('ClientAddress').value
      // this.addClient.clientAddress.city = this.addClientContactForm.get('ClientCity').value
      // this.addClient.clientAddress.state = this.addClientContactForm.get('ClientState').value
      // this.addClient.clientAddress.zipcode = this.addClientContactForm.get('ClientPostalCode').value
      // this.addClient.clientAddress.country = this.addClientContactForm.get('ClientCountry').value
      // this.addClient.status = "Active"
    }
  }

  formatDate(date) {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  deleteClient(): void {
    this.clientService.deleteClient(this.selectedClient.clientID);
    this.dialogRef.close();
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(): void {
    this.addClient.executedFunctionType = 'Cancel';
    this.dialogRef.close();
    this.initializeClientForm();
  }

  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param field_name - field parameter to check for errors
   */
  getErrorMessage(fieldName): string {
    const charNotAllowed = 'Special characters are not allowed';
    if (fieldName === 'FIRST_NAME') {
      return this.addClientContactForm.get('ClientContactFirstName').hasError('required')
        ? 'You must enter first name'
        : this.addClientContactForm.get('ClientContactFirstName').hasError('pattern')
          ? charNotAllowed
          : '';
    }
    if (fieldName === 'LAST_NAME') {
      return this.addClientContactForm.get('ClientContactLastName').hasError('required')
        ? 'You must enter last name'
        : this.addClientContactForm.get('ClientContactLastName').hasError('pattern')
          ? charNotAllowed
          : '';
    }
    if (fieldName === 'PHONE_NUMBER') {
      return this.addClientContactForm.get('ClientContactMobileNumber').hasError('required')
        ? phoneErrorMessage
        : this.addClientContactForm.get('ClientContactMobileNumber').hasError('specialCharacter')
          ? charNotAllowed
          : this.addClientContactForm.get('ClientContactMobileNumber').hasError('pattern')
            ? phoneErrorMessage
            : this.addClientContactForm.get('ClientContactMobileNumber').hasError('alphabets')
              ? phoneErrorMessage
              : this.addClientContactForm.get('ClientContactMobileNumber').hasError('minlength')
                ? phoneErrorMessage
                : '';
    }
    if (fieldName === 'EMAIL_ADDRESS') {
      return this.addClientContactForm.get('ClientContactEmailAddress').hasError('required')
        ? 'You must enter email address'
        : this.addClientContactForm.get('ClientContactEmailAddress').hasError('pattern')
          ? 'You must enter valid email address'
          : '';
    }

    switch (fieldName === 'clientName') {
      case this.addClientContactForm.get('ClientPreferredName').hasError('required'):
        return 'You must enter Client Name';
      case this.addClientContactForm.get('ClientPreferredName').hasError('pattern'):
        return charNotAllowed;
      case this.addClientContactForm.get('ClientLegalName').hasError('required'):
        return 'YYou must enter Client Legal Name';
      case this.addClientContactForm.get('ClientLegalName').hasError('pattern'):
        return charNotAllowed;
    }
  }

  updateClientContactFlag(): boolean {
    this.inviteAsClientContactFlag = !this.inviteAsClientContactFlag;
    return this.inviteAsClientContactFlag;
  }

  updateClientStatus(): boolean {
    this.inactivateClientFlag = !this.inactivateClientFlag;
    return this.inactivateClientFlag;
  }

  //   onSearchChange(searchValue: string): void {
  //    if(this.checkFirstTimeEntry || this.addClientContactForm.get('ClientPreferredName').value === ''){
  //      this.addClientContactForm.get('ClientPreferredName').setValue(this.addClientContactForm.value['ClientLegalName'])
  //    }
  //  }

  toggleDuplicateFlag() {
    this.checkFirstTimeEntry = false;
  }

  /**
   * destroys the object
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addValidators() {
    if (this.addClientContactForm.controls['ClientContactMobileNumber'].value) {
      this.addClientContactForm.controls['ClientContactMobileNumber'].setValidators(
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          this.regexValidator(new RegExp(/[A-Za-z]/g), { 'alphabets': true }),
          this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\]\[ ]/g), { 'specialCharacter': true })
        ]));
      this.addClientContactForm.controls['ClientContactMobileNumber'].updateValueAndValidity();
      this.isPhoneNumberValid = this.addClientContactForm.controls['ClientContactMobileNumber'].valid;
    } else {
      this.addClientContactForm.controls['ClientContactMobileNumber'].clearValidators();
      this.addClientContactForm.controls['ClientContactMobileNumber'].updateValueAndValidity();
      this.isPhoneNumberValid = false;
    }
    if (this.addClientContactForm.controls['ClientContactEmailAddress'].value) {
      this.addClientContactForm.controls['ClientContactEmailAddress'].setValidators(
        Validators.compose([
          Validators.required,
          Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')
        ]));
      this.addClientContactForm.controls['ClientContactEmailAddress'].updateValueAndValidity();
      this.isEmailValid = this.addClientContactForm.controls['ClientContactEmailAddress'].valid;
    } else {
      this.addClientContactForm.controls['ClientContactEmailAddress'].clearValidators();
      this.addClientContactForm.controls['ClientContactEmailAddress'].updateValueAndValidity();
      this.isEmailValid = false;
    }
  }

  /**validate the pattern for phone number */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      return valid ? error : null;
    };
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit() {
    this.ExtnSvc.extensionList$.subscribe(extension => {
      if (extension) {
        this.extensions = extension;
        this.defaultExtension = this.extensions.find(x => (x.name === 'United States' || x.name === 'Canada'));
        if (this.selectedClient.countryDialingCode) {
          if (this.defaultExtension) {
            this.addClientContactForm.controls.Extension
              .setValue(this.selectedClient.countryDialingCode);
          }
        } else {
          this.addClientContactForm.controls.Extension
            .setValue(this.defaultExtension.countryDialingCode);
        }
      }
    });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-ADD_CLIENT_CONTACT', 'ADD_CLIENT_CONTACT');
      });
  }

}
