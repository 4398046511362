<div class="snack-content" fxLayout="column" fxLayoutAlign="none end">
  <div fxLayout="row" fxLayoutAlign="end none">
    <mat-icon (click)="dismiss()" id="dismiss" class="close-icon">close</mat-icon>
  </div>
  <div *ngIf="isHTML" fxLayout="row" fxLayoutAlign="space-between start">
    <div class="notification-icon" fxLayout="column" fxLayoutAlign="center none">
      <mat-icon class="danger-icon">error</mat-icon>
      <mat-icon class="warning-icon">warning</mat-icon>
      <mat-icon class="success-icon">check_circle</mat-icon>
    </div>
    <div class="notification-message" fxLayout="column" fxLayoutAlign="center none">
      <p>{{data?.header}}</p>
      <ul>
        <ng-container *ngFor="let line of (data.lines ? data.lines : [])">
          <li [innerHTML]="line"></li>
        </ng-container>
      </ul>
      <p>{{data?.footer}}</p>
    </div>
  </div>
  <div *ngIf="!isHTML" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="notification-icon" fxLayout="column" fxLayoutAlign="center none">
      <mat-icon class="danger-icon">error</mat-icon>
      <mat-icon class="warning-icon">warning</mat-icon>
      <mat-icon class="success-icon">check_circle</mat-icon>
    </div>
    <div class="notification-message" fxLayout="column" fxLayoutAlign="center none">
      <p>{{ data }}</p>
    </div>
  </div>
</div>
