import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkflowEnablementService {

  currentlyOpenTransferee = new BehaviorSubject<any>(null);
  constructor() { }

  updateCurrentlyOpenTransferee(windowObj: any) {
    this.currentlyOpenTransferee.next(windowObj);
  }


}
