import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../app/public/project-alpha/authGuard';
import { ProjectAlphaComponent } from '../public/project-alpha/project-alpha.component';
import { HotelLeadsFormComponent } from './components/hotel-leads-form/hotel-leads-form.component';
import { HotelLeadsListComponent } from './components/hotel-leads-list/hotel-leads-list.component';

const routes: Routes = [
  {
    path: 'project-alpha',
    component: ProjectAlphaComponent,
    children: [
      {
        path: 'hotel-leads',
        redirectTo: 'hotel-leads/entryForm',
        pathMatch: 'full'
      },
      {
        path: 'hotel-leads/entryForm',
        component: HotelLeadsFormComponent,
        data: { capabilities: ['MOB - Hotel Leads'] },
        canActivate: [AuthGuard]
      },
      {
        path: 'hotel-leads/lists',
        component: HotelLeadsListComponent,
        data: { capabilities: ['MOB - Hotel Leads'] },
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotelLeadsRoutingModule { }