import { Component, ViewChild } from '@angular/core';
import { ConsultantDetailsComponent } from './consultant-details/consultant-details.component';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss']
})
export class ConsultantsComponent  {

  /** Decorator used to Access EmployeeDetailsComponent elements */
  @ViewChild(ConsultantDetailsComponent, { static: true }) childObj: ConsultantDetailsComponent;
  /** Store the filter value */
  filterText = '';
  /** Variable to store the columns */
  displayedAccManagerColumns: string[] = [
    'name',
    'emailAddress',
    'clientList'
  ];
  /**base constructor */
  constructor() { }

  /**
 * Filtering the Data in Table
 * @param filterValue Holds the Searched value
 */
  applyFilter(filterValue: string) {
    this.childObj.applyFilter(filterValue);
  }
}
