import { Component, OnInit, ViewChild } from '@angular/core';
import { HotelLeadsFormService } from '../../services/hotel-leads-form.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { HotelLeadsFormComponent } from '../hotel-leads-form/hotel-leads-form.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-hotel-leads-list',
  templateUrl: './hotel-leads-list.component.html',
  styleUrls: ['./hotel-leads-list.component.scss']
})
export class HotelLeadsListComponent implements OnInit {

  leadsListData = [];

  displayedColumns = [
    'customerFileId',
    'customerName',
    'clientNumber',
    'clientName',
    'supplierName',
    'enteredBy'
  ];
  isNoRecordsFound = false;

  pageInfo = {
    pageSize: 10,
    totalCount: 0,
    currentPage: 0,
    sortField: null,
    sortFieldDir: null,
    pageSizeOptions: ['10', '20', '25']
  };
  filterDetails: any;
  onlyShowMyOrders: any;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(private hotelLeadsFormService: HotelLeadsFormService,
    public spinner: NgxSpinnerService,
    private readonly notificationsService: NotificationsService,
    public dialog: MatDialog) {

  }

  ngOnInit() {
      this.onlyShowMyOrders = true;
      this.displayLeadsList();
  }

  displayLeadsList() {
    this.spinner.show();
    const params = this.createSearchParams() ? this.createSearchParams() : '';
    this.hotelLeadsFormService.getLeadsListData(params).subscribe({
      next: response => {
      this.spinner.hide();
      if (response.headers.get('x-order-count') && parseInt(response.headers.get('x-order-count'), 10) > 0) {
        if (response?.body?.length) {
          this.pageInfo.totalCount = parseInt(response.headers.get('x-order-count'), 10);
          this.leadsListData = response.body;
          this.isNoRecordsFound = false;
        } else {
          this.isNoRecordsFound = true;
          this.enableNotification('warning', 'No records found.');
        }
      } else {
        this.isNoRecordsFound = true;
        this.enableNotification('warning', 'No records found.');
      }
    }, 
    error: (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.isNoRecordsFound = true;
      this.enableNotification('danger', 'Oops! something went wrong.');
    }});
  }

  getTheLeadDetails(element) {
    if (element.orderId) {
      this.getLeadByOrderId(element.orderId);
    }
  }

  getLeadByOrderId(orderId) {
    this.spinner.show();
    this.hotelLeadsFormService.getLeadByOrderId(orderId).subscribe({
      next: data => {
      this.spinner.hide();
      if (typeof data === 'object' && Object.keys(data).length) {
        this.openLeadForm(data);
      } else {
        this.enableNotification('danger', 'Oops! something went wrong.');
      }
    }, 
    error: (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.enableNotification('danger', 'Oops! something went wrong.');
      console.log(error.message);
    }});
  }

  onPagination(event) {
    if (this.pageInfo.currentPage !== event.pageIndex || this.pageInfo.pageSize !== event.pageSize) {
      this.pageInfo.currentPage = event.pageIndex;
      this.pageInfo.pageSize = event.pageSize;
      this.displayLeadsList();
    }
  }

  sortData(event) {
    switch (event.active) {
      case 'customerFileId':
        this.pageInfo.sortField = 'details.customerFileId'
        break;
      case 'clientNumber':
        this.pageInfo.sortField = 'onBehalfOf.clientNumber'
        break;
      case 'clientName':
        this.pageInfo.sortField = 'onBehalfOf.entityName'
        break;
      default:
        this.pageInfo.sortField = 'bookedWith.entityName'
        break;
    }
    this.pageInfo.sortFieldDir = event.direction.toUpperCase();
    this.displayLeadsList();
  }

  openLeadForm(formData) {
    const dialogRef = this.dialog.open(HotelLeadsFormComponent, {
      disableClose: false,
      height: 'auto',
      width: 'auto',
      panelClass: ['dialogMainContainer'],
      data: {leadData: formData},
      autoFocus: false,
      restoreFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  createSearchParams() {
    const params = new URLSearchParams();
    if (this.pageInfo.currentPage > -1) {
      params.set('skip', (this.pageInfo.currentPage * this.pageInfo.pageSize).toString());
    }
    if (this.pageInfo.pageSize) {
       params.set('limit', this.pageInfo.pageSize.toString());
    }
    if ( this.pageInfo.sortField) {
      params.set('sortField', this.pageInfo.sortField);
    }
    if ( this.pageInfo.sortFieldDir) {
      params.set('sortDir', this.pageInfo.sortFieldDir);
    }
    if (this.filterDetails?.includes('customerFileId|')) {
      params.set('details', this.filterDetails);
    }
    if (this.onlyShowMyOrders) {
      params.set('onlyShowMyOrders', this.onlyShowMyOrders.toString());
    }
    return params.size > 0 ? '?' + params.toString() : null;
  }

  applyFilter(val?) {
    if (val && val.length >= 7) {
      this.filterDetails = 'customerFileId|' + val;
      this.pageInfo.currentPage = 0;
      if (this.paginator) {
        this.paginator.firstPage();
      }
      this.displayLeadsList();
    } else {
      this.filterDetails = val;
    }
    if (!val?.length) {
      this.displayLeadsList();
    }
  }

  submissionValChange() {
    this.pageInfo.currentPage = 0;
    if (!this.isNoRecordsFound) {
      this.paginator.firstPage();
    }
    this.displayLeadsList();
  }

  enableNotification(type: string, msg: string) {
    this.notificationsService.flashNotification(type, msg);
  }

}
