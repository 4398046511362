import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
  OnDestroy
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormGroup } from '@angular/forms';
import { Selection } from '../../../core/models/selection.model';
import { Client } from '../../../core/models/client';
import { ClientServiceService } from '../../../core/services/client-service.service';
import { CandidateProfilesService } from '../../../core/services/candidate-profiles.service';
import { ClientContactColumnsComponent } from '../client-contact/client-contact-columns/client-contact-columns.component';
import { ClientContact } from '../../../core/models/client-contact';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientContactService } from '../../../core/services/client-contact.service';
import { AddClientContactComponent } from '../client-contact/add-client-contact/add-client-contact.component';
import { NotificationsService } from '../../../../../src/app/core/services/notifications.service';
import { Subscription } from 'rxjs';
import { APIResponse } from 'src/app/core/models/response.model';
import { InactivateClientContactsComponent } from '../client-contact/inactivate-client-contacts/inactivate-client-contacts.component';

import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { Extension } from 'src/app/core/models/extension.model';
@Component({
  selector: 'app-client-contact',
  templateUrl: './client-contact.component.html',
  styleUrls: ['./client-contact.component.scss']
})
export class ClientContactComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = [
    'select',
    'lastName',
    'companyName',
    'status'
  ];

  dataSource: any;
  ELEMENT_DATA: ClientContact[] = [];
  billingContacts: any;
  filterText = '';
  deleteFlag = false;
  initialCount = 0;
  selectedClientId = '';

  currentClientCount: number;
  inactivateButtonText: string;

  clientList: Client[];
  selectedCompany: Client;
  clientToBeSent: Client;

  selection = new SelectionModel<any>(true, []);
  columnList: Selection[] = [];
  /** Subscription prop for unsubscribing services */
  private subscription: Subscription = new Subscription();
  /**default extension */
  defaultExtension: any;
  /** to check for readonly access */
  disable = false;

  constructor(
    public dialog: MatDialog,
    private clientService: ClientServiceService,
    private clientContactService: ClientContactService,
    public spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private candidateProfilesService: CandidateProfilesService,
    private Logger: LoggerService,
    private loggedInUserService: UserContextService,
    private ExtnSvc: ExtensionService
  ) { }

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() selectedCols: Selection[];

  ngOnInit() {
    this.selectedCols = [
      {
        displayName: 'Full Name',
        value: 'lastName',
        flag: true,
        disabled: true
      },
      {
        displayName: 'Status',
        value: 'status',
        flag: true,
        disabled: true
      },
      {
        displayName: 'Company',
        value: 'companyName',
        flag: true,
        disabled: false
      }
    ];
    this.updateTable();
    this.spinner.show();
    this.ELEMENT_DATA = [];
    this.currentClientCount = this.ELEMENT_DATA.length;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.sortingDataAccessor = this.getPropertyByPath;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.customFilterPredicate;
    this.calculateActiveClientContacts(this.dataSource);
    this.subscription.add(
      this.clientService.getActiveClients().subscribe(clients => {
        let clientDetails: Client[] = [];
        clients.map(client => {
          clientDetails.push(client);
        });
        clientDetails = this.sortData(clientDetails, 'clientLegalName');
        this.clientService.client$.next(clientDetails);
        this.spinner.hide();
      })
    );
    this.subscription.add(
      this.ExtnSvc.getExtensionDetails().subscribe((extensions: any[]) => {
        this.defaultExtension = extensions.find(x => x.name === 'United States');
        const extensionDetails: any[] = [];
        for (let i = 0; i < extensions.length; i++) {
          const extn = extensions[i];
          const extensionData: Extension = {
            countryDialingCode: `+${extn.dialingCode}`,
            name: extn.name
          };
          extensionDetails.push(extensionData);
        }
        this.ExtnSvc.extension$.next(extensionDetails);
      })
    );
    this.clientService.clientList$.subscribe(result => {
      this.clientList = result;
    });
    this.inactivateButtonText = `Set to Inactive`;
  }
  /* sort all the column in the table with lastName & firstName logic */
  sortDataCustom(data) {
    const clientDetails = this.ELEMENT_DATA;
    const compare = (first, second) => {
      if (data.active === 'lastName') {
        if (data.direction === 'asc') {
          let o1 = first['lastName'].toLowerCase();
          let o2 = second['lastName'].toLowerCase();
          let p1 = first['firstName'].toLowerCase();
          let p2 = second['firstName'].toLowerCase();
          if (o1 === o2) {
            o1 = first['lastName'];
            o2 = second['lastName'];
          }
          if (p1 === p2) {
            p1 = first['firstName'];
            p2 = second['firstName'];
          }
          if (o1 < o2) { return -1; }
          if (o1 > o2) { return 1; }
          if (p1 < p2) { return -1; }
          if (p1 > p2) { return 1; }
          return 0;
        } else if (data.direction === 'desc') {
          let o1 = first['lastName'].toLowerCase();
          let o2 = second['lastName'].toLowerCase();
          let p1 = first['firstName'].toLowerCase();
          let p2 = second['firstName'].toLowerCase();
          if (o1 === o2) {
            o1 = first['lastName'];
            o2 = second['lastName'];
          }
          if (p1 === p2) {
            p1 = first['firstName'];
            p2 = second['firstName'];
          }
          if (o1 < o2) { return 1; }
          if (o1 > o2) { return -1; }
          if (p1 < p2) { return 1; }
          if (p1 > p2) { return -1; }
          return 0;
        }
      } else {
        if (data.direction === 'desc') {
          const a = first && first[data.active] && first[data.active].toLowerCase();
          const b = second && second[data.active] && second[data.active].toLowerCase();
          if (a !== undefined && b !== undefined) {
            if (a.toLowerCase() < b.toLowerCase()) { return -1; }
            if (a.toLowerCase() > b.toLowerCase()) { return +1; }
            if (a.toLowerCase() === b.toLowerCase()) {
              if (a < b) { return -1; }
              if (a > b) { return +1; }
            }
          }
        } else if (data.direction === 'asc') {
          const a = first && first[data.active] && first[data.active].toLowerCase();
          const b = second && second[data.active] && second[data.active].toLowerCase();
          if (a !== undefined && b !== undefined) {
            if (a.toLowerCase() < b.toLowerCase()) { return 1; }
            if (a.toLowerCase() > b.toLowerCase()) { return -1; }
            if (a.toLowerCase() === b.toLowerCase()) {
              if (a < b) { return 1; }
              if (a > b) { return -1; }
            }
          }
        }
      }
      return 0;
    };
    this.dataSource.sortData = (contactData: any[], sort: MatSort) => {
      return contactData.sort(compare);
    };
  }

  sortData(clientDetails, fieldName = '') {

    const compare = (first, second) => {
      const a = first && first.split('->')[0];
      const b = second && second.split('->')[0];
      if (a !== undefined && b !== undefined) {
        if (a.toLowerCase() < b.toLowerCase()) { return -1; }
        if (a.toLowerCase() > b.toLowerCase()) { return +1; }
        if (a.toLowerCase() === b.toLowerCase()) {
          if (a < b) { return -1; }
          if (a > b) { return +1; }
        }
      }
      return 0;
    };

    const filteredClients = clientDetails.filter(c => {
      if (c[fieldName] !== undefined) {
        return c;
      }
    });

    const clientNames = filteredClients.map((c, i) => {
      if (c[fieldName] !== undefined) {
        return c[fieldName] + '->' + i;
      }
    }).sort(compare);

    const sortedClients = [];

    clientNames.forEach((c, i) => {
      const idx = c.split('->')[1];
      sortedClients[i] = filteredClients[idx];
    });

    return sortedClients;
  }

  customFilterPredicate(data, filter): boolean {
    const dataStr =
      data.firstName +
      data.lastName +
      data.client.clientPreferredName +
      data.status;
    return dataStr.search(new RegExp(filter, 'i')) !== -1;
  }

  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.dataSource.filter = filterValue;
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**Function to update the client contact in the table */
  updateDataSource(data: ClientContact) {
    // console.log('Invitation Sent for :' + JSON.stringify(data, null, 4));
    if (data) {
      this.getClientFromAPI(data);
    }
  }

  getClientFromAPI(event) {
    this.spinner.show();
    let company;
    // console.log("This is the value : " + JSON.stringify(this.selectedClientId, null , 4))
    if (event['source'] !== undefined) {
      const target = event.source.selected._element.nativeElement;
      // console.log('Event' + JSON.stringify(target, null, 4));
      const selectedData = {
        value: event.value,
        text: target.innerText.trim()
      };
      // console.log('selectedData' + JSON.stringify(selectedData, null, 4));
      // There is possibility of duplicate client name
      const filteredCompanies = this.clientList.filter(c => c.clientLegalName === selectedData.text);
      if (filteredCompanies.length === 1) {
        company = filteredCompanies[0];
      } else {
        const selectedIndex = parseInt(selectedData.value.toString().split('->')[1], 10);
        company = this.clientList[selectedIndex];
      }
    } else {
      // console.log('selectedData' + JSON.stringify(event['clientID'], null, 4));
      const clientId = event['clientID'] !== undefined ? event['clientID'] : this.selectedClientId !== undefined ?
        this.selectedClientId : event.value;
      company = this.clientList.find(c => c.partyID === clientId);
    }
    // console.log("Company Value" + JSON.stringify(company, null, 4))
    this.clientToBeSent = company;
    // console.log(company);
    company.partyID = company !== undefined ? company['partyID'] : this.selectedClientId;
    this.subscription.add(this.clientContactService
      .getClientContactsfor(company.partyID)
      .subscribe(models => {
        // console.log('Client Contact Models :' + JSON.stringify(models, null, 4));
        const updatedResponse = models.map(clientContact => ({
          ...clientContact,
          client: company,
          roleName: clientContact.role.find(r => r.name === 'client-contact-administrator') !== undefined ? 'Admin' :
            (clientContact.role.find(r => r.name === 'client-contact-initiator') !== undefined ? 'Initiator' : 'Billing Contact'),
          countryDialingCode: clientContact.countryDialingCode ?
            `+${clientContact.countryDialingCode}` : this.defaultExtension.countryDialingCode
        }));
        this.ELEMENT_DATA = updatedResponse;
        this.currentClientCount = this.ELEMENT_DATA.length;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.sortingDataAccessor = this.getPropertyByPath;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = this.customFilterPredicate;
        this.calculateActiveClientContacts(this.dataSource);
        this.spinner.hide();
      }));
  }

  calculateActiveClientContacts(dataSource) {
    const clientContacts = 'Client Contacts';
    if (dataSource === undefined) {
      setTimeout(() => this.candidateProfilesService.testSubject.next({
        type: clientContacts,
        length: 0,
        label: clientContacts
      }), 0);
    } else {
      setTimeout(() => this.candidateProfilesService.testSubject.next({
        type: clientContacts,
        length: dataSource.data.length,
        label: clientContacts
      }), 0);
    }
  }

  /* Method to check if all the rows in the mat table were selected*/
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    if (this.dataSource !== undefined) {
      numRows = this.dataSource.data.length ? this.dataSource.data.length : 0;
    }
    return numSelected === numRows;
  }

  /* Method to toggle select all or clear all for rows inside in the mat table */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
    if (this.selection.selected.length === 1) {
      this.inactivateButtonText = `Set to Inactive`;
    } else {
      this.inactivateButtonText = `Inactivate ${this.selection.selected.length} Client Contact(s)`;
    }
  }

  checkboxLabel(row?: ClientContact): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    if (this.selection.selected.length === 1) {
      this.inactivateButtonText = `Set to Inactive`;
    } else {
      this.inactivateButtonText = `Inactivate ${this.selection.selected.length} Clients`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.firstName + 1}`;
  }

  /* Open the dialog box AddCandidateComponent in EDIT mode when any row is clicked of the mat table*/
  public open(event, data): void {
    // console.log(data);
    const dialogRef = this.dialog.open(AddClientContactComponent, {
      panelClass: 'dialogMainContainer',
      disableClose: true,
      data: {
        selectedClient: data,
        disableButton: this.disable
      }
    });

    this.subscription.add(dialogRef.afterOpened().subscribe(result => {
      // TO DO:
    }));

    this.subscription.add(dialogRef.afterClosed().subscribe((clientContact: ClientContact) => {
      if (clientContact.executedFunctionType === 'Update') {
        this.notificationsService.flashNotification(
          'success',
          'Updated client Contact successfully.',
          true,
          'dismiss'
        );
        this.getClientFromAPI(clientContact);
      } else if (clientContact.executedFunctionType === 'Create') {
        this.notificationsService.flashNotification(
          'success',
          'Added client Contact successfully.',
          true,
          'dismiss'
        );
        this.getClientFromAPI(clientContact);
      } else if (clientContact.executedFunctionType === 'Inactivate') {
        this.selection.selected.push(clientContact);
        // uncomment if needed
        this.inactivateClients(true);
        this.getClientFromAPI(clientContact);
      } else if (clientContact.executedFunctionType === 'Activate') {
        this.notificationsService.flashNotification(
          'success',
          'Activated client Contact successfully.',
          true,
          'dismiss'
        );
        this.getClientFromAPI(clientContact);
      } else {
        this.getClientFromAPI(clientContact);
      }
    }));
  }

  public deleteRow(event, data, deleteFlag): void {
    data.isDeleted = deleteFlag;
    const dialogRef = this.dialog.open(AddClientContactComponent, {
      panelClass: 'dialogMainContainer',
      disableClose: true,
      data: {
        selectedClient: data,
        disableButton: this.disable
      }
    });

    this.subscription.add(dialogRef.afterOpened().subscribe(result => {
      // TO DO:
    }));

    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notificationsService.flashNotification(
          'danger',
          'Client Inactivated Successfully.',
          true,
          'dismiss'
        );
        this.getClientFromAPI(undefined);
      }
    }));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddClientContactComponent, {
      panelClass: 'dialogMainContainer'
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((clientContact: ClientContact) => {
      this.getClientFromAPI(undefined);
    }));
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ClientContactColumnsComponent, {
      panelClass: 'DisplayedColumnsModal',
      data: this.displayedColumns
    });
    this.subscription.add(dialogRef.componentInstance.columnsListUpdated.subscribe(
      (response: Selection[]) => {
        this.columnList = response;
        this.selectedCols = this.columnList;
        this.updateTable();
      }
    ));
  }

  updateTable(): void {
    this.selectedCols.forEach((item, index) => {
      if (this.displayedColumns.indexOf(item.value) < 0) {
        this.displayedColumns.push(item.value);
      } else {
        this.displayedColumns = this.displayedColumns.filter(val => {
          return item.value === val;
        });
      }
    });
    if (this.displayedColumns.findIndex(val => val === 'select') < 0) {
      this.displayedColumns.unshift('select');
    }
  }

  inactivateClients(deleteFlag) {
    const payload = {
      noOfSelected: this.selection.selected.length,
      selectedClients: this.selection.selected,
      deleteDialog: deleteFlag
    };
    // console.log('Payload for Inactivate : ' + JSON.stringify(payload, null, 4))
    const dialogRef = this.dialog.open(InactivateClientContactsComponent, {
      panelClass: 'dialogMainContainer',
      data: payload
    });

    dialogRef.afterOpened().subscribe(result => {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('In the inactivate function...');
      if (result === 'inactivateClients') {
        console.log('Expected Inactivation');
        // this.clientContactService.inActivateClientContact(this.selection.selected);
        this.subscription.add(this.clientContactService.inActivateClientContact(this.selection.selected).subscribe(
          (response: APIResponse) => {
            const clientContact: ClientContact = this.selection.selected.find(item => item.isDeleted === false);
            this.selectedClientId = clientContact['client']['partyID'];
            // console.log("Selection : " + JSON.stringify(this.selection, null, 4))
            // console.log("Selected Client Contact : " + JSON.stringify(clientContact, null, 4))
            if (response.statusCode === 200) {
              // this.selection.selected.forEach(client => this.clientContactService.deleteClientContact(client.clientID));
              this.selection.clear();
              const updatedCount = (this.clientContactService.clientContactList.filter(item => item.isDeleted === false)).length;
              if (updatedCount < this.currentClientCount) {
                this.notificationsService.flashNotification('danger', 'Client Contact inactivated successfully.', true, 'dismiss');
              }
              this.getClientFromAPI(clientContact);
            }
          }));
      } else {
        this.selection.clear();
      }
    });
  }

  /** to check for readonly access */
  isDisabled(flag) {
    this.disable = flag;
    if (flag === true) {
      this.displayedColumns = [
        'select',
        'lastName',
        'companyName',
        'status'
      ];
    }
  }

  /**
      * destroys the object
     */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
