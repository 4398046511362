import { Component, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { AccountManagerDetailsComponent } from './account-manager-details/account-manager-details.component';
import { Observable, Subscription, debounce, distinctUntilChanged, filter, fromEvent, of, tap, timer } from 'rxjs';

/**base class for AccountManager Component */
@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.scss']
})
export class AccountManagerComponent implements AfterViewInit, OnDestroy {
  /** Decorator used to Access EmployeeDetailsComponent elements */
  @ViewChild(AccountManagerDetailsComponent, { static: true }) childObj: AccountManagerDetailsComponent;
  /** Decorator used to access search element */
  @ViewChild("searchInput") searchInput: ElementRef;
  /** Search Input Keyup event source */
  searchInputKeyupEventSource: Observable<KeyboardEvent>;
  /** Search Input Blur event source */
  searchInputBlurEventSource: Observable<FocusEvent>;
  /** Subscription prop for unsubscribing */
  private readonly subscription: Subscription = new Subscription();
  /** Store the filter value */
  filterText = '';
  /** Variable to store the columns */
  displayedAccManagerColumns: string[] = [
    'name',
    'emailAddress',
    'clientList'
  ];
  /**base constructor */
  constructor() { }

  /** Initialization Tasks */
  ngAfterViewInit(): void {
    this.searchInputKeyupEventSource = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'keyup');
    this.searchInputBlurEventSource = fromEvent<FocusEvent>(this.searchInput.nativeElement, 'blur');
    this.subscription.add(
      this.searchInputKeyupEventSource.pipe(
        debounce(evt => evt.key.toLocaleLowerCase() === 'enter' ? of({}) : timer(1000)),
        filter(() => this.searchInput.nativeElement.value !== this.filterText),
        distinctUntilChanged(),
        tap(() => { this.filterText = this.searchInput.nativeElement.value; })
      ).subscribe(() => { this.applyFilter(this.filterText); })
    );
    this.subscription.add(
      this.searchInputBlurEventSource.pipe(
        filter(() => this.searchInput.nativeElement.value !== this.filterText),
        tap(() => { this.filterText = this.searchInput.nativeElement.value; })
      ).subscribe(() => { this.applyFilter(this.filterText); })
    );
  }

  /**
   * Filtering the Data in Table
   * @param filterValue Holds the Searched value
   */
  applyFilter(filterValue: string) {
    this.childObj.applyFilter(filterValue);
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
