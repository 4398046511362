/** MPI-91 - used by notifications.service.ts */
import { Component, OnInit, Output, EventEmitter, Inject, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { CandidateProfilesService } from '../../../../core/services/candidate-profiles.service';
import { Observable, of } from 'rxjs';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

/**
 *  DuplicateCandidate component for candidate
 */
@Component({
  selector: 'app-duplicate-candidate',
  templateUrl: './duplicate-candidate.component.html',
  styleUrls: ['./duplicate-candidate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DuplicateCandidateComponent implements OnInit {

  /**variable to store the status */
 proceed: string;
 /**to load the snackbar based on message sent */
 flag: boolean;
/**
 * Injecting dependencies
 * @param _snackRef _snackRef variable for MatSnackBarRef
 * @param profSvc object for CandidateProfilesService
 * @param data injecting matsnackbar data
 */
  constructor(private readonly _snackRef: MatSnackBarRef<DuplicateCandidateComponent>,
  private readonly profSvc: CandidateProfilesService, @Inject(MAT_SNACK_BAR_DATA) public data: any,
  private readonly Logger: LoggerService,
  private readonly loggedInUserService: UserContextService) {

   }
   /**initialize the component */
  ngOnInit() {
    if (this.data === 'Duplicate') {
      this.flag = true;
    }
    if (this.data === 'Sent') {
      this.flag = false;
    }
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.userId.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-DUPLICATE_CANDIDATE', 'DUPLICATE_CANDIDATE');
    });
  }
  /**method to be called on click of done icon */
  saveDetails() {
    this.proceed = 'Continue';
    this.profSvc.selectedValue$.next(this.proceed);
    this._snackRef.dismiss();
   }
  /**method to be called on click of close icon */
  cancel() {
     this._snackRef.dismiss();
    }
}
