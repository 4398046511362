<ng-template [appReadonly]="['Role Capability Maintenance']" (valueChange)="isDisabled($event)"></ng-template>
<h2>Capabilities</h2>
<section id="capabilities-search">
  <div>
    <span>
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput id="search-input" (input)="applyFilter($event.target.value)" [value]="filterText"
          placeholder="Search by Name">
      </mat-form-field>
    </span>
    <button type="button" id="add-capability" class="button-icon-xl" (click)="openAddCapabilityDialog()" [disabled]="disable">
      <mat-icon matPrefix id="add_icon">add_circle</mat-icon>
    </button>
  </div>
</section>
<section id="capabilities-table">
  <div class="mat-elevation-z8">
    <div *ngIf="ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0">
      <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortBy" matSortDirection="asc" matSortDisableClear>
        <!-- Remove Column -->
        <ng-container matColumnDef="remove" sticky>
          <th mat-header-cell id="remove" *matHeaderCellDef>Remove</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox disableRipple (click)="$event.stopPropagation()" (change)="$event ? selectRemoveCheckBox($event, element) : null" (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)" [disabled]="disableRemoveCheckBoxes && !selection.isSelected(element)"></mat-checkbox>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="capabilityName" sticky>
          <th mat-header-cell id="capabilityName" *matHeaderCellDef mat-sort-header="capabilityName">Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.capabilityName | highlightSearch: filterText"></span>
        </ng-container>
        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell id="description" *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.description | highlightSearch: filterText"></span>
        </ng-container>
        <!-- Application Column -->
        <ng-container matColumnDef="application">
          <th mat-header-cell id="application" *matHeaderCellDef mat-sort-header="application">Application</th>
          <td mat-cell *matCellDef="let element">
                <span [innerHTML]="element.application ? (element.application | highlightSearch: filterText):''"></span>
            <span *ngIf="!element.application">-</span>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell id="actions" *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button disableRipple mat-icon-button class="action-button" [disabled]="disable" (click)="openEditCapsDialog(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div [hidden]="!(ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0)">
      <mat-paginator [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage" [pageSizeOptions]="pageInfo.perPageOptions"
      [pageIndex]="pageInfo.currentPage" [disabled]="pageInfo.itemCount === 0" (page)="onPagination($event)"></mat-paginator>
    </div>
    <div [hidden]="!dataSource || (ELEMENT_DATA?.length > 0 && dataSource.filteredData.length > 0) || processing" class="no-data" role="alert">
      <p>No records found</p>
    </div>
  </div>
</section>
<section id="capabilities-actions">
  <button mat-button type="button" id="cancel" class="button-outline-sm" *ngIf="selection.selected.length > 0" (click)="cancel()" [disabled]="disable">
    <mat-icon>cancel</mat-icon>
    <span>Cancel</span>
  </button>
  <button mat-button type="button" id="remove-capability" class="button-sm" *ngIf="selection.selected.length > 0" (click)="openRemoveCapabilityConfirm()" [disabled]="disable">
    <mat-icon>remove_circle</mat-icon>
    <span>{{
      selection.selected.length === 1 ?
      'Remove ' + selection.selected.length + ' Capability' :
      'Remove ' + selection.selected.length + ' Capabilities'
    }}</span>
  </button>
</section>
<app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing">
</app-progress-spinner>
