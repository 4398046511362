<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between end"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">{{title}}</div>
    <button type="button" class="button-icon" (click)="cancel()" title="close" [disabled]="processing">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form fxLayout="column" class="middle-section" [formGroup]="employeeSearchForm">
    <div class="middle-section">
      <mat-dialog-content>
        <div class="employee-search-container">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start">
            <div *ngIf="multiSelect" fxFlex="50%" fxLayoutAlign="end" fxFlex.lt-md="100%">
              <mat-form-field>
                <mat-label>Role</mat-label>
                <mat-select formControlName="roleName" placeholder="Select Role" (selectionChange)="roleChanged($event)">
                  <mat-option *ngFor="let teamRole of teamRoles" [value]="teamRole">{{teamRole.displayValue}}</mat-option>
                </mat-select>
                <mat-error>{{ getErrorMessage('ROLE_NAME') }}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field>
                <input id="Employee" matInput #employee placeholder="Employee Name" formControlName="employee" spellcheck="false"
                (keyup.enter)="employeeSearchForm.valid && search(1, pageInfo.perPage)">
                <button mat-button type="button" *ngIf="employeeSearchForm.controls.employee.value" matSuffix mat-icon-button
                (click)="employeeSearchForm.controls.employee.reset()" [disabled]="processing">
                  <mat-icon>close</mat-icon>
              </button>
                <mat-error>{{ getErrorMessage('EMPLOYEE') }}</mat-error>
             </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start">
            <div fxFlex="50%" fxFlex.lt-md="100%">
            </div>
            <div fxFlex="50%" fxLayoutAlign="end" fxFlex.lt-md="100%">
              <button mat-button type="button" class="button-sm"
              (click)="search(1, pageInfo.perPage)" [disabled]="!employeeSearchForm.valid || processing">
              <mat-icon *ngIf="!processing">search</mat-icon>
              <mat-icon *ngIf="processing"><mat-spinner diameter="20"></mat-spinner></mat-icon>
              <span>Search</span>
              </button>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="sub-title">Found Employees</div>
        <div class="found-employees-container">
          <div fxLayout="column" fxLayoutGap="0.25em">
            <ng-container *ngFor="let employee of foundEmployees">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" fxLayoutAlign.lt-md="start">
                <div fxFlex="75%" fxLayoutAlign="start">
                  <span>{{employee.name}}</span>
                </div>
                <div fxFlex="25%" fxLayoutAlign="end">
                  <button mat-button type="button" class="button-xs" (click)="addEmployeeToList(employee)"
                  [disabled]="(!multiSelect && selectedEmployeesCount === 1) || (team && (team.owner?.id === employee.id)) ||
                  (employee | teamMember:{ team: this.team, role: this.selectedRole, selectedEmployees: this.selectedEmployees })">
                    <mat-icon>check_circle</mat-icon>
                    <span>Select</span>
                  </button>
                </div>
              </div>
              <mat-divider></mat-divider>
            </ng-container>
          </div>
          <div *ngIf="foundEmployees.length === 0 && formSubmitted && !processing">No Employees found</div>
        </div>
        <div>
          <mat-paginator [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage" [pageSizeOptions]="pageInfo.perPageOptions"
          [pageIndex]="pageInfo.currentPage" (page)="onPagination($event)" [disabled]="pageInfo.itemCount === 0"></mat-paginator>
        </div>
        <mat-divider></mat-divider>
        <div class="sub-title" *ngIf="multiSelect">Selected Employees</div>
        <div class="selected-employees-container" *ngIf="multiSelect">
          <ng-container *ngFor="let role of selectedRoles" >
            <div class="role-title"><span>{{role}}</span></div>
            <mat-divider></mat-divider>
            <div fxLayout="row wrap" fxLayoutAlign="start start">
            <div class="selected-employees-item"
            *ngFor="let employee of selectedEmployees | teamRole:{ role: role }"
            fxFlex="0 0 50%" fxFlex.lt-md="0 0 100%">
              <mat-checkbox [checked]="employee.checked" (change)="removeEmployee(employee, role)">
                {{employee.name}}
              </mat-checkbox>
            </div>
          </div>
          </ng-container>
        </div>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions *ngIf="multiSelect">
      <button mat-button type="button" class="button-outline-sm" (click)="cancel()" [disabled]="processing">
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
      <button mat-button type="button" class="button-sm" *ngIf="selectedEmployeesCount !== 0" (click)="save()"
      [disabled]="selectedEmployeesCount === 0 || processing" >
        <mat-icon>add_circle</mat-icon>
        <span>{{selectedEmployeesCount}} {{selectedEmployeesCount === 1 ? 'Employee' : 'Employees' }}</span>
      </button>
    </mat-dialog-actions>
  </form>
</div>
