<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between end"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">{{ title }}</div>
    <button type="button" class="button-icon" (click)="cancel()" title="close" [disabled]="processing">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form fxLayout="column" class="middle-section" [formGroup]="addTeamForm">
    <div class="middle-section">
      <mat-dialog-content>
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="0 0 calc(50% - 1em)" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input id="TeamName" matInput placeholder="Team Name" formControlName="name">
                <mat-error>{{ getErrorMessage('NAME') }}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="0 0 calc(50% - 1em)" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input id="TeamOwner" matInput placeholder="Team Owner" formControlName="owner" spellcheck="false" [readonly]="true" (click)="openEmployeeSearchDialog()" (keydown.space)="openEmployeeSearchDialog()">
                <button mat-button type="button" *ngIf="!addTeamForm.controls.owner.value" matSuffix mat-icon-button
                (click)="openEmployeeSearchDialog()" (keydown.space)="openEmployeeSearchDialog()" [disabled]="processing">
                  <mat-icon>search</mat-icon>
                </button>
                <button mat-button type="button" *ngIf="addTeamForm.controls.owner.value" matSuffix mat-icon-button
                (click)="addTeamForm.controls.owner.setValue('');addTeamForm.controls.ownerId.setValue('')" [disabled]="processing">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error>{{ getErrorMessage('OWNER') }}</mat-error>
             </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="0 0 calc(50% - 1em)" fxLayout.xs="row" fxFlex.xs="100%" fxLayout="column" fxLayoutAlign="space-between">
              <mat-form-field>
                <mat-label>Team Status</mat-label>
                <mat-select id="TeamStatus" formControlName="status">
                  <mat-option *ngFor="let teamStatus of teamStatuses" [value]="teamStatus">{{teamStatus}}</mat-option>
                </mat-select>
                <mat-error>{{ getErrorMessage('STATUS') }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions>
      <div class="statusBtn add-dialogbtn">
        <button mat-button type="button" class="button-outline-sm" (click)="cancel()" [disabled]="processing">
          <mat-icon>cancel</mat-icon>
          <span>Cancel</span>
        </button>
        <button mat-button type="button" class="button-sm" (click)="createTeam()" [disabled]="!addTeamForm.valid || processing">
          <mat-icon *ngIf="!processing">add_circle</mat-icon>
          <mat-icon *ngIf="processing"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          <span>Team</span>
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
