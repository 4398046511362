<div class="dialog-form-container">
    <div mat-dialog-title disableTypography className={styles.dialogTitle}>
      <div class="dialog-title">{{ title }}</div>
      <button type="button" class="button-icon" (click)="cancel()" title="close" [disabled]="processing">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <form [formGroup]="addEditCapForm">
      <div class="dialog-middle-section">
        <mat-dialog-content>
          <div>
            <div class="row">
              <div class="col100">
                <mat-form-field>
                  <input id="name" matInput placeholder="Capability Name" formControlName="capabilityName">
                  <mat-error>{{ getErrorMessage('CAPABILITY_NAME') }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col100">
                    <mat-form-field>
                        <textarea id="description" matInput placeholder="Description" formControlName="description" rows="3"></textarea>
                        <mat-error>{{ getErrorMessage('DESCRIPTION') }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div class="col50">
                <mat-form-field>
                    <mat-label>Level</mat-label>
                    <mat-select id="level" formControlName="level" (selectionChange)="onLevelChangeEvent($event)">
                      <mat-option *ngFor="let level of levels" [value]="level">{{level}}</mat-option>
                    </mat-select>
                    <mat-error>{{ getErrorMessage('LEVEL') }}</mat-error>
                  </mat-form-field>
              </div>
              <div class="col50">
                <mat-form-field *ngIf="addEditCapForm.controls.level.value && addEditCapForm.controls.level.value === 'UI'">
                    <mat-label>Application</mat-label>
                    <mat-select id="application" formControlName="application" (selectionChange)="onAppChangeEvent($event)">
                      <mat-option *ngFor="let app of apps" [value]="app.name">{{app.displayName}}</mat-option>
                    </mat-select>
                    <mat-error>{{ getErrorMessage('APPLICATION') }}</mat-error>
                    <mat-hint>&#42;Application prefix will prepend capability name</mat-hint>
                  </mat-form-field>
              </div>
            </div>
          </div>
        </mat-dialog-content>
      </div>
      <mat-dialog-actions>
        <button mat-button type="button" class="button-outline-sm" (click)="cancel()" [disabled]="processing">
          <mat-icon>cancel</mat-icon>
          <span>Cancel</span>
        </button>
        <button mat-button type="button" class="button-sm" *ngIf="!editCapability" (click)="createOrUpdateCap(OperationType.create)"
          [disabled]="!addEditCapForm.valid || processing">
          <mat-icon>add_circle</mat-icon>
          <span>Capability</span>
        </button>
        <button mat-button type="button" class="button-sm" *ngIf="editCapability" (click)="createOrUpdateCap(OperationType.update)"
          [disabled]="!addEditCapForm.valid || !addEditCapForm.dirty || processing">
          <mat-icon>check</mat-icon>
          <span>Save Changes</span>
        </button>
      </mat-dialog-actions>
    </form>
  </div>
  <app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing">
  </app-progress-spinner>