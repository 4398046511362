import { Injectable } from '@angular/core';
import { cartusEmployeesMock, cartusEmployeeRoleUpdateResponseMock } from '../../../UnitTest-Support/Mocks/data.mock';
import { of, Observable } from 'rxjs';
import { delay, map, catchError } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { EmployeeDetails, Employees } from '../models/employee-details.model';
import { RemoteLoggingService } from './remote-logging.service';
import { APIResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDetailsService {

  /**
   * Base constructor
   * @param baseClientService Instance of BaseClient Service
   * @param logSvc Instance of log Service
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) { }

  /**
   * Function used to Get All Employee Record
   * @param queryString query string params
   */
  getEmployees(queryString: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/client/employeeDetails?${queryString}`).pipe(
        map(r => r),
        catchError(err => {
          this.logSvc.logger('', 'Failed to get Employee Details', err);
          const emptyArray: Employees = null;
          return of(emptyArray);
        })
      );
  }

  /**
   * Function used to Update Employee Roles
   * @param employeeData Holds Employee update Roles
   */
  updateEmployeeroles(employeeData: EmployeeDetails) {
    return this.baseClientService.put<APIResponse>('/client/employeeDetails', employeeData).pipe(
      map(r => r.body),
      catchError(err => {
        const empty: APIResponse = null;
        this.logSvc.logger('', 'Failed to update employee roles', err);
        return of(err);
      })
    );
  }
}
