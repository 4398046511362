import { Component, OnInit } from '@angular/core';
import {LoggerService} from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.userId.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-PUBLIC_SERVICES', 'SERVICES');
    });

  }

}
