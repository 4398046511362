import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MenuItem } from '../../../models/Menu.model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {

  /** Component css class */
  @HostBinding('class') class = 'app-menu-item';

  /** menu item input */
  @Input() item: MenuItem | undefined;
  
  /** item selected */
  @Output() itemSelected = new EventEmitter<boolean>();
  
  /** is the sub-menu open */
  subMenuIsOpen: boolean = true;

  constructor() { }

  expandSubMenu() {
    this.subMenuIsOpen = true;
  }

  collapseSubMenu() {
    this.subMenuIsOpen = false;
  }

  toggleSubMenu() {
    this.subMenuIsOpen = !this.subMenuIsOpen;
  }
  
  onItemSelected() {
    this.itemSelected.emit(true);
  }

}
