import { Component, OnInit } from '@angular/core';
import { CandidateContactInfo } from '../../../core/models/candidate-contact-info.model';
import { AppConfigService } from '../../../core/services/app-config.service';
import { UserContextService } from '../../../core/services/user-context.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PartySharedService } from '../../../core/services/party-shared.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  /**candidateContactInfo of type CandidateContactInfo */
  candidateContactInfo: CandidateContactInfo;
  candidateCompany: string;
  isNotCartusEmp = true;

  constructor(private readonly appConfig: AppConfigService,
    private readonly loggedInUserService: UserContextService,
    private readonly spinner: NgxSpinnerService,
    private readonly router: Router,
    private readonly partySharedService: PartySharedService
  ) { }

  ngOnInit() {
    this.getLoggedInUserInformation();
  }

   getLoggedInUserInformation() {
    this.spinner.show();
    this.loggedInUserService.getLoggedInUserInformation()
      .subscribe({
        next: async response => {
          this.spinner.hide();
          if (response) {
            response.phoneNumber = response.phoneNumber.replace(/[()-]/g, '').replace(' ', '');
            this.candidateContactInfo = {
              candidateName: response.userName ? response.userName.replace(' ', ', ') : '-',
              emailId: response.emailAddress,
              phoneNumber: response.phoneNumber.substring(response.phoneNumber.length - 10),
              countryDialingCode: response.phoneNumber.substring(0, response.phoneNumber.length - 10).replace('+', '')
            };
            this.candidateCompany = response.clientName;
            const partyId = await this.partySharedService.getPartyId();
            const roleCapabilities = await this.partySharedService.getRoleCapabilities(partyId);
            roleCapabilities.partyRoles.forEach(el => {
              if (el.name === 'cartus-employee') {
                this.isNotCartusEmp = false;
                return false;
              }
            });
          }
        },
        error: () => {
          this.spinner.hide();
        }
      }
    );
  }

  gotoChangePasswordPage() {
    const changePasswordURL = String(this.appConfig.getConfig('changePassword'));
    const url = this.router.url.length > 1 ? this.router.url.slice(1) : this.router.url;
    this.router.navigate(['/externalRedirect', { externalUrl: `${changePasswordURL}&urlPath=${url}` }], {
      skipLocationChange: true
    });
  }

  getPhoneNumber(phoneNumber) {
    const regex = /^[A-Za-z0-9 ]+$/;
    if ( regex.test(phoneNumber) ) {
      return Number(phoneNumber);
    } else {
       return phoneNumber;
    }
  }
}
