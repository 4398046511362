<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="privacy-notice">
    <div class="page-header">
      <img class="logo" src="../../../assets/images/logos/logo_cartus.svg" alt="Cartus">
    </div>

    <div class="main-content contactSection">
      <div class="heading">
        Contact Us: How May We Help You?
      </div>
      <div class="text firstLine">
        The Cartus Help Desk team can assist with any technical support issues.
      </div>
      <div class="text">
        Cartus’ Help Desk is here to help you with all of your technical needs from Sunday 8:00 p.m. through Friday 8:00
        p.m. Eastern U.S. time.
      </div>
      <div class="text">
        For assistance with other questions or issues, please contact your Cartus Representative.
      </div>
      <div class="text">
        <span class="sub-heading">Contact the Cartus Help Desk team</span><br />
        <span class="sub-heading">By Phone:</span> 1.800.316.8070 (within the U.S.) or +1.203.205.1726<br />
        <span class="sub-heading">By Email: </span> <a
          href="mailto:helpdesk-cartus@cartus.com">helpdesk-cartus&#64;cartus.com</a>
      </div>
      <div class="text">
        Please include your name, phone number and a description of your issue.
      </div>
    </div>
    <div class="page-footer" [innerHTML]="copyright"></div>
  </div>
