import { Injectable } from '@angular/core';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { urlType } from 'src/app/core/models/urlType';

@Injectable({
  providedIn: 'root'
})
export class UserAlignmentService {

  /** Base constructor method
   * @param baseClientService BaseClientService injection
   * @param customLogger: LoggerService injection
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly customLogger: LoggerService,
  ) { }
  /**
   * Return the People Data for the specfied partyIds
   * @param partyIds partyIds
   */
  getPeopleData(partyIds: Array<string>): Observable<any> {
    const reqBody = {
      partyIds
    };
    return this.baseClientService
      .post<any>('/user-alignment/getPeopleData', reqBody, 'Get People Data', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to get People data', err);
        })
      );
  }
  /**
   * Update the identityProviderId for the specfied partyIds
   * @param partyIds partyIds
   */
  updateIdentityProviderId(sourcePartyId: string, sourceIdentityProviderId: string, partyIds: Array<string>): Observable<any> {
    const reqBody = {
      sourceIdentityProviderId,
      sourcePartyId,
      partyIds
    };
    return this.baseClientService
      .put<any>('/user-alignment/updateIdentityProviderId', reqBody, 'Update IdentityProviderId', urlType.opdata)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.catchBlock('Failed to update IdentityProviderId', err);
        })
      );
  }
  catchBlock(message: string, error: any) {
    this.customLogger.error(message, error);
    return of(error);
  }
}
