import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { AddAccountManagerComponent } from '../add-account-manager/add-account-manager.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '../../../../../app/core/services/notifications.service';
import { PartySharedService } from '../../../../../app/core/services/party-shared.service';
import { LoggerService } from '../../../../../app/core/services/logger.service';
import { UserContextService } from '../../../../../app/core/services/user-context.service';
import * as querystring from 'querystring';
import { AccountManagerService } from '../../../../../app/core/services/account-manager.service';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AccountManagerDetails } from '../../../../../app/core/models/account-manager.model';
import { apiErrorMessage, skipRecordCount } from '../../../../../app/core/models/constants';
import { AccountManagerClients } from '../../../../core/models/account-manager-clients.model';
/**base class for AccountManagerDetails component */
@Component({
  selector: 'app-account-manager-details',
  templateUrl: './account-manager-details.component.html',
  styleUrls: ['./account-manager-details.component.scss']
})

export class AccountManagerDetailsComponent implements OnInit, OnDestroy {
  /**used to store filter text */
  @Input() filterText;
  /** Used to Sort Mat-table Column */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** Used to paginate in a Mat-table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /** Variable to store the columns */
  displayedAccManagerColumns: string[] = [
    'name',
    'emailAddress',
    'clientList'
  ];
  /** Datasource for Table */
  dataSource: any;
  /** Stores the details of Employees */
  ELEMENT_DATA: AccountManagerDetails[] = [];
  /** Store default column for sorting */
  sortBy = 'name';
  /** sorting direction */
  sortDir = 'ASC';
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Holds Client Contact Id */
  clientContactId: string;
  /** total employees count */
  totalEmployeesCount: number;
  /** page details*/
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 0
  };
  /**variable to store sorting order */
  sortStatement = '';
  /**
   * base constructor
   * @param dialog instance of MatDialog
   * @param spinner instance of ngx-spinner
   * @param accntManagerSvc instance of AccountManagerService
   * @param notificationsService instance of NotificationService
   * @param partySharedSvc instance of PartySharedService
   * @param Logger instance of LoggerService
   * @param loggedInUserService instance of UserContextService
   */
  constructor(public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private readonly accntManagerSvc: AccountManagerService,
    private readonly notificationsService: NotificationsService,
    private readonly partySharedSvc: PartySharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService) { }

  /**method to initialize the component */
  ngOnInit() {
    this.spinner.show();
    this.partySharedSvc.getPartyId().then(id => {
      if (id) {
        this.clientContactId = id;
        this.loadAccountManagers(this.pageInfo.pageSize, this.pageInfo.pageIndex);
      } else {
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
    });
  }

  /**method to set the querystring */
  getQueryString(_searchText?, _sortField?, _sortDir?, _skip?, _limit?): string {
    const _queryString = { role: 'cartus-account-manager' };
    if (_searchText) { _queryString['searchText'] = _searchText; }
    if (_sortField) { _queryString['sortField'] = _sortField; }
    if (_sortDir) { _queryString['sortDir'] = _sortDir; }
    if (_skip) { _queryString['skip'] = _skip; }
    if (_limit) { _queryString['limit'] = _limit; }
    return querystring.stringify(_queryString);
  }

  /**method to load account manager details */
  loadAccountManagers(pageSize: number, skipRecords: number) {
    this.spinner.show();
    this.subscription.add(
      this.accntManagerSvc.getAllAccountManagers(
        this.getQueryString(this.filterText, this.sortBy, this.sortDir, skipRecords, pageSize))
        .subscribe({
          next: response => {
            if (response && response.status === 204) {
              this.totalEmployeesCount = 0;
              this.ELEMENT_DATA = [];
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.spinner.hide();
            } else if (response && response.status === 200 && response.body) {
              this.formatData(response.body.employees);
              this.totalEmployeesCount = response.body.totalEmployees;
              this.pageInfo.pageSize = this.totalEmployeesCount > 10 ? 20 : this.totalEmployeesCount > 5 ? 10 : 5;
              this.ELEMENT_DATA = response.body.employees;
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.dataSource.sort = this.sortOrder;
              setTimeout(() => {
                this.paginator.length = Number(response.body.totalEmployees);
              });
              this.dataSource.sortingDataAccessor = this.getPropertyByPath;
              this.dataSource.filterPredicate = this.customFilterPredicate;
              this.loggedInUserService.getLoggedInUserDetails()
              .subscribe(result => {
                const userId: any = result.userId.replace(/ .*/, '');
                this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-EMPLOYEE_DETAILS', 'EMPLOYEE_DETAILS');
              });
              this.spinner.hide();
            } else {
              this.notificationsService.flashNotification(
                'failed',
                apiErrorMessage,
                false,
                'dismiss'
              );
              this.spinner.hide();
            }
          },
          error: () => {
            this.spinner.hide();
          }
        }
      )
    );
  }

  /**method to format name */
  formatData(data) {
    for (const emp of data) {
      emp.name = `${emp.name.split(',')[1]}, ${emp.name.split(',')[0]}`;
    }
  }

  /**
   * function to get property by path
   * @param obj -object passed
   * @param pathString -path passed
   */
  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**
   * method to filter column values
   * @param data data to be filtered
   * @param filter param to filter data
   */
  customFilterPredicate(data, filter): boolean {
    const searchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const dataStr =
      data.name +
      data.emailAddress;
    return dataStr.search(new RegExp(searchTerm, 'gi')) !== -1;
  }

  /** to open add-clients modal */
  openModal(data): void {
    const dialogRef = this.dialog.open(AddAccountManagerComponent, {
      data,
      panelClass: 'empdialogMainContainer',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      (clients: AccountManagerClients[]) => {
        if (clients) {
          for (const manager of this.ELEMENT_DATA) {
            if (manager.employeeId === data.employeeId) {
              manager.clientList = clients;
            }
          }
          this.notificationsService.flashNotification(
            'success',
            'Updated Client Associations Successfully'
          );
        }
      });
  }

  /**method for server side sort */
  sortData(event) {
    switch (event.direction) {
      case 'asc':
        this.sortStatement = `sorting ${event.active} in ascending order`;
        break;
      case 'desc':
        this.sortStatement = `sorting ${event.active} in descending order`;
        break;
      case '':
        this.sortStatement = `sorting ${event.active} in random order`;
        break;
    }
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'DESC' : 'ASC';
    this.loadAccountManagers(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  /** Mat data table filtering matching wild characters */
  applyFilter(filterValue: string) {
    this.pageInfo.pageSize = 20;
    this.filterText = filterValue.trim();
    if (this.filterText && this.filterText.length >= 3) {
      this.paginator.pageIndex = skipRecordCount;
      return this.loadAccountManagers(this.pageInfo.pageSize, skipRecordCount);
    } else if (this.filterText === '') {
      return this.loadAccountManagers(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
    }
    // if (this.filterText === '' || this.filterText && this.filterText.length >= 3) {
    //   return this.loadAccountManagers(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
    // }
    return false;
  }

  /**method for page event for MatPaginator  */
  onPagination(event: any) {
    this.loadAccountManagers(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  /**
   * Destroys the object
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
