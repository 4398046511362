export const GET_ROLE_DEFINITIONS_ERROR = 'The request to retrieve the Roles failed';
export const GET_CAPABILITY_DEFINITIONS_ERROR = 'The request to retrieve the Capabilities failed';
export const GET_ROLE_CAPABILITY_ERROR = 'The request to retrieve the Role Capabilities failed';
export const REMOVE_ROLE_ERROR = 'The request to delete the role failed';
export const REMOVE_CAPABILITY_ERROR = 'The request to delete the role capability failed';
export const GET_DOES_ROLE_ASSOCIATION_ERROR = 'The request to retrieve the role association failed';
export const GET_ROLE_ASSOCIATION_ERROR = 'You cannot delete this document because role associations exist for the specified role name';
export const REMOVE_ROLES_DIALOG_TITLE = 'Remove Role';
export const REMOVE_ROLES_DIALOG_LINES = ['Are you sure that you want to remove the following role?'];
export const ADD_ROLE_TITLE = 'Add Role';
export const EDIT_ROLE_TITLE = 'Edit Role';
export const ADD_CAP_SUCCESS = 'The capability was created successfully';
export const EDIT_CAP_SUCCESS = 'The capability was updated successfully';
export const ADD_CAP_ERROR = 'The request to create the capability failed';
export const EDIT_CAP_ERROR = 'The request to update the capability failed';
export const ADD_ROLE_SUCCESS = 'The role was created successfully';
export const EDIT_ROLE_SUCCESS = 'The role was updated successfully';
export const REMOVE_ROLE_SUCCESS = 'The role was deleted successfully';
export const REMOVE_ROLECAPS_SUCCESS = 'The role capability was deleted successfully';
export const REMOVE_CAPS_SUCCESS = 'The capability was deleted successfully';
export const ADD_ROLE_ERROR = 'The request to create the role failed';
export const EDIT_ROLE_ERROR = 'The request to update the role failed';
export const ROLE_ALREADY_EXISTS_ERROR = 'The entered role name already exists';
export const CAP_ALREADY_EXISTS_ERROR = 'The entered capability name already exists';
export const ROLE_CAP_ALREADY_EXISTS_ERROR = 'The role capability already exists';
export const GET_DEPENDENT_ROLES_ERROR = 'The request to retrieve the dependent role values failed';
export const GET_ASSOC_ROLECAP_ERROR = 'The request to retrieve the associated role capability failed';
export const GET_CAP_APP_MAP_ERROR = 'The request to retrieve the Capability Applications Map valuelist failed';
export const GET_CAP_KEY_ERROR = 'The request to retrieve the Capabilities valuelist failed';
export const GET_APPS_ERROR = 'The request to retrieve the Applications valuelist failed';
export const GET_ROLECAPS_ERROR = 'The request to retrieve the role capabilities list failed';
export const ROLE_NAME_VAL_PATTERN = '^[a-z]+(-[a-z]+)*$';
export const ROLE_NAME_VAL_PATTERN_ERROR = 'Only lowercase a-z and hyphens are allowed. Words should be separated with a hyphen.';
export const ROLE_NAME_VAL_REQ_ERROR = 'You must enter a Role Name';
export const ROLE_NAME_VAL_MIN_LENGTH_ERROR = 'Role Name should be more than one character';
export const ROLE_NAME_VAL_MAX_LENGTH_ERROR = 'Role Name should be fifty characters or less';
export const FRIENDLY_NAME_VAL_PATTERN = '^(?:[A-Z]+[a-z]*\\s?)*[^\\s\\W0-9_]$';
export const FRIENDLY_NAME_VAL_PATTERN_ERROR = 'Only uppercase A-Z, lowercase a-z and spaces are allowed. The first letter of each word must be uppercase.';
export const FRIENDLY_NAME_VAL_REQ_ERROR = 'You must enter a Friendly Name';
export const FRIENDLY_NAME_VAL_MIN_LENGTH_ERROR = 'Friendly Name should be more than one character';
export const FRIENDLY_NAME_VAL_MAX_LENGTH_ERROR = 'Friendly Name should be fifty characters or less';
export const CONCURRENT_REQUESTS = 2;
export const CAPS_KEY = 'Capabilities';
export const CAPS_APPS_MAP_KEY = 'CapabilitiesApplicationsMap';
export const APPS_KEY = 'Applications';
export const ADD_CAP_TITLE = 'Add Capability';
export const EDIT_CAP_TITLE = 'Edit Capability';
export const CAP_UI_NAME_VAL_PATTERN = '^[A-Z]{1}(?:(?:[A-Z]+[a-z]*( & | | - ){1}\\b)+|(?:[A-Za-z]*( & | | - ){0,1}\\b)+)$';
// eslint-disable-next-line max-len
export const CAP_UI_NAME_VAL_PATTERN_ERROR = 'When Level is UI, only uppercase A-Z, lowercase a-z, spaces and ampersands and hyphens are allowed. Hyphens and Ampersands must have a space before and after.';
export const CAP_CORE_NAME_VAL_PATTERN = '^(?:(?:[A-Z]+[a-z]*( - | & | )?\\b)+|(?:[A-Z]+[a-z]*( - | & | )?\\b)+|(?:[A-Z]{1}[A-Za-z]*( - | & | )?\\b)+)$';
// eslint-disable-next-line max-len
export const CAP_CORE_NAME_VAL_PATTERN_ERROR = 'When Level is Core, only uppercase A-Z, lowercase a-z, spaces, ampersands and hyphens are allowed. The first letter of each word must be uppercase. Hyphens and ampersands must have a space before and after.';
export const CAP_NAME_VAL_REQ_ERROR = 'You must enter a Capability Name';
export const CAP_NAME_VAL_MIN_LENGTH_ERROR = 'Capability Name should be more than one character';
export const CAP_NAME_VAL_MAX_LENGTH_ERROR = 'Capability Name should be fifty characters or less';
export const CAP_DESC_VAL_PATTERN = '^[A-Za-z0-9\-_\\s\\.\\,\\/\\\\]+$';
export const CAP_DESC_VAL_REQ_ERROR = 'You must enter a Description';
export const CAP_DESC_VAL_PATTERN_ERROR = 'Description should not include special characters';
export const CAP_DESC_VAL_MIN_LENGTH_ERROR = 'Description should be more than one character';
export const CAP_DESC_VAL_MAX_LENGTH_ERROR = 'Description Name should be two hundred characters or less';
export const CAP_LEVEL_VAL_REQ_ERROR = 'You must choose a Level';
export const CAP_APP_VAL_REQ_ERROR = 'You must choose an Application when Level is UI';
export const REMOVE_CAP_DIALOG_TITLE = 'Remove Capability';
export const REMOVE_CAP_DIALOG_LINES = ['Are you sure that you want to remove the following capability?'];
export const CANCEL_DIALOG_TITLE = 'Cancel';
export const CANCEL_DIALOG_LINES = ['Are you sure that you want to Cancel and lose any changes that have been made?'];
export const EDIT_ROLE_CAP_DIALOG_TITLE = 'Update Role Capability';
export const EDIT_ROLE_CAP_DIALOG_LINES = ['Are you sure that you want to update the following role capability?'];
export const EDIT_ROLE_CAP_ERROR = 'The request to update the role capability failed';
export const EDIT_ROLE_CAP_SUCCESS = 'The role capability was updated successfully';
export const ROLE_CAP_VERSION_ERROR = 'The role capability update failed due to a document version mismatch';
export const ROLE_CAP_MAPPING_TITLE = 'Role &amp; Capability Mapping';
export const CAP_SEARCH_TITLE = 'Capability Search';
export const ROLE_SEARCH_TITLE = 'Role Search';
export const GENERIC_API_ERROR = 'The request failed due to a generic API error';
export const CAP_ROLE_MAPPING_TITLE = 'Capability To Role Capability Mapping';
export const REMOVE_ROLE_DIALOG_TITLE = 'Remove Capability from Role Capability';
export const REMOVE_ROLE_DIALOG_LINES = ['Are you sure that you want to remove the following capability from the $role role capability?'];
export const EDIT_CAP_ROLE_DIALOG_TITLE = 'Update Role Capabilities';
export const EDIT_CAP_ROLE_DIALOG_LINES = ['Are you sure that you want to make the following changes?'];
export const GET_CAP_ROLE_ERROR = 'The request to retrieve the role capability failed';
export const EDIT_CAP_ROLE_SUCCESS = 'The role capability changes were processed successfully';
export const ROLE_DEF_NOT_FOUND_ERROR = 'The role definition was not found';
export const OPERATION_OPTIONS = ['read', 'write', 'delete'];
