<section id="title">
  <h1>{{title}}</h1>
  <h2>Role: <span>{{capabilityTitle}}</span></h2>
</section>
<section id="cap-search">
  <mat-form-field class="example-full-width">
    <mat-label>Capability Name</mat-label>
    <input type="text" placeholder="Select an option or search" aria-label="Capability Name" matInput
      [formControl]="search" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchCaps()">
      <mat-option *ngFor="let option of filteredCapOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
    <button id="search-reset" type="button" mat-button matSuffix mat-icon-button *ngIf="search.value"
      (click)="resetSearch()" title="Reset Search" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <button id="search" type="button" mat-button class="button-sm" (click)="searchCaps()" (keydown)="searchKeyDown($event)">
    <mat-icon>search</mat-icon>
    <span>Search</span>
  </button>
</section>
<section id="available-cap-table">
  <h4>Found Capabilities</h4>
  <div>
    <table mat-table [dataSource]="filteredDataSource" #filteredSort="matSort" matSort [matSortActive]="sortBy"
      matSortDirection="asc" matSortDisableClear>
      <!-- Name Column -->
      <ng-container matColumnDef="capabilityName" sticky>
        <th mat-header-cell id="capabilityName" *matHeaderCellDef mat-sort-header="capabilityName">Capability Name</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element.capabilityName"></span>
        </td>
      </ng-container>
      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell id="description" *matHeaderCellDef mat-sort-header="description">Description</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element.description"></span>
        </td>
      </ng-container>
      <!-- Operation Column -->
      <ng-container matColumnDef="operation">
        <th mat-header-cell id="operation" *matHeaderCellDef mat-sort-header="operation">Operation&#42;</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field floatLabel="never" appearance="none">
            <mat-select (selectionChange)="onOperationChanges($event, element)">
              <ng-container *ngFor="let opt of operationOptions">
                <mat-option [value]="opt">{{opt}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <!-- Permission Column -->
      <ng-container matColumnDef="permission">
        <th mat-header-cell id="permission" *matHeaderCellDef mat-sort-header="permission">Permission&#42;</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field floatLabel="never" appearance="none">
            <mat-select (selectionChange)="onPermissionChanges($event, element)">
              <ng-container *ngFor="let opt of permissionOptions">
                <mat-option [value]="opt">{{opt}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell id="actions" *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button disableRipple mat-icon-button class="action-button" (click)="addCapToSelected(element)"
            [disabled]="!element.operation || element.operation === ''|| !element.permission || element.permission === ''">
            <mat-icon>add</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</section>
<section id="selected-cap-table">
  <h4>Selected Capabilities</h4>
  <div>
    <table mat-table [dataSource]="selectedDataSource" #selectedSort="matSort" matSort [matSortActive]="sortBy"
      matSortDirection="asc" matSortDisableClear>
      <!-- Name Column -->
      <ng-container matColumnDef="capabilityName" sticky>
        <th mat-header-cell id="capabilityName" *matHeaderCellDef mat-sort-header="capabilityName">Capability Name</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element.capabilityName"></span>
        </td>
      </ng-container>
      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell id="description" *matHeaderCellDef mat-sort-header="description">Description</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element.description"></span>
        </td>
      </ng-container>
      <!-- Operation Column -->
      <ng-container matColumnDef="operation">
        <th mat-header-cell id="operation" *matHeaderCellDef mat-sort-header="operation">Operation</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element.operation"></span>
        </td>
      </ng-container>
      <!-- Permission Column -->
      <ng-container matColumnDef="permission">
        <th mat-header-cell id="permission" *matHeaderCellDef mat-sort-header="permission">Permission</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element.permission"></span>
        </td>
      </ng-container>
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell id="actions" *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button disableRipple mat-icon-button class="action-button" (click)="removeCapFromSelected(element)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</section>
<section id="role-actions">
  <button mat-button type="button" id="cancel" class="button-outline-sm" (click)="cancel()">
    <mat-icon>cancel</mat-icon>
    <span>Cancel</span>
  </button>
  <button mat-button type="button" id="add-caps" class="button-sm" *ngIf="selectedCaps.length > 0"
    (click)="updateRoleCap()" [disabled]="disable">
    <mat-icon>add</mat-icon>
    <span>{{selectedCapsCount}} {{selectedCapsCount === 1 ? 'Capability' : 'Capabilities' }}</span>
  </button>
</section>
<app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing">
</app-progress-spinner>