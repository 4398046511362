<section id="title">
    <h1>{{title}}</h1>
    <h2>Capability: <span>{{capabilityName}}</span></h2>
  </section>
  <section id="role-search">
    <mat-form-field class="example-full-width">
      <mat-label>Role Name</mat-label>
      <input type="text" placeholder="Select an option or search" aria-label="Role Name" matInput
        [formControl]="search" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchRoles()">
        <mat-option *ngFor="let option of filteredRoleOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
      <button id="search-reset" type="button" mat-button matSuffix mat-icon-button *ngIf="search.value"
        (click)="resetSearch()" title="Reset Search" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button id="search" type="button" mat-button class="button-sm" (click)="searchRoles()" (keydown)="searchKeyDown($event)">
      <mat-icon>search</mat-icon>
      <span>Search</span>
    </button>
  </section>
  <section id="available-role-table">
    <h4>Found Roles</h4>
    <div>
      <table mat-table [dataSource]="filteredDataSource" #filteredSort="matSort" matSort [matSortActive]="sortBy"
        matSortDirection="asc" matSortDisableClear>
        <!-- Role Name Column -->
        <ng-container matColumnDef="roleName" sticky>
          <th mat-header-cell id="roleName" *matHeaderCellDef mat-sort-header="roleName">Role Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.roleName"></span>
          </td>
        </ng-container>
        <!-- Friendly Name Column -->
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell id="friendlyName" *matHeaderCellDef mat-sort-header="friendlyName">Friendly Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.friendlyName"></span>
          </td>
        </ng-container>
        <!-- Operation Column -->
        <ng-container matColumnDef="operation">
          <th mat-header-cell id="operation" *matHeaderCellDef mat-sort-header="operation">Capability<br />Operation&#42;</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never" appearance="none">
              <mat-select (selectionChange)="onOperationChanges($event, element)">
                <ng-container *ngFor="let opt of operationOptions">
                  <mat-option [value]="opt">{{opt}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <!-- Permission Column -->
        <ng-container matColumnDef="permission">
          <th mat-header-cell id="permission" *matHeaderCellDef mat-sort-header="permission">Capability<br />Permission&#42;</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never" appearance="none">
              <mat-select (selectionChange)="onPermissionChanges($event, element)">
                <ng-container *ngFor="let opt of permissionOptions">
                  <mat-option [value]="opt">{{opt}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell id="actions" *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button disableRipple mat-icon-button class="action-button" (click)="addRoleToSelected(element)"
              [disabled]="!element.operation || element.operation === ''|| !element.permission || element.permission === ''">
              <mat-icon>add</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </section>
  <section id="selected-role-table">
    <h4>Selected Roles</h4>
    <div>
      <table mat-table [dataSource]="selectedDataSource" #selectedSort="matSort" matSort [matSortActive]="sortBy"
        matSortDirection="asc" matSortDisableClear>
        <!-- Role Name Column -->
        <ng-container matColumnDef="roleName" sticky>
          <th mat-header-cell id="roleName" *matHeaderCellDef mat-sort-header="roleName">Role Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.roleName"></span>
          </td>
        </ng-container>
        <!-- Friendly Name Column -->
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell id="friendlyName" *matHeaderCellDef mat-sort-header="friendlyName">Friendly Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.friendlyName"></span>
          </td>
        </ng-container>
        <!-- Operation Column -->
        <ng-container matColumnDef="operation">
          <th mat-header-cell id="operation" *matHeaderCellDef mat-sort-header="operation">Capability<br />Operation</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.operation"></span>
          </td>
        </ng-container>
        <!-- Permission Column -->
        <ng-container matColumnDef="permission">
          <th mat-header-cell id="permission" *matHeaderCellDef mat-sort-header="permission">Capability<br />Permission</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.permission"></span>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell id="actions" *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button disableRipple mat-icon-button class="action-button" (click)="removeRoleFromSelected(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </section>
  <section id="role-actions">
    <button mat-button type="button" id="cancel" class="button-outline-sm" (click)="cancel()">
      <mat-icon>cancel</mat-icon>
      <span>Cancel</span>
    </button>
    <button mat-button type="button" id="add-roles" class="button-sm" *ngIf="selectedRoles.length > 0"
      (click)="updateRoles()" [disabled]="disable">
      <mat-icon>add</mat-icon>
      <span>{{selectedRolesCount}} {{selectedRolesCount === 1 ? 'Role' : 'Roles' }}</span>
    </button>
  </section>
  <app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing">
  </app-progress-spinner>