import { Injectable } from '@angular/core';
import { ClientRole, roleType } from '../models/client-role';

@Injectable({
  providedIn: 'root'
})
export class ClientRoleService {

  levelList: ClientRole[] = [
    { roleID: 'R1', roleName: roleType.initiator, roleDescrpition: 'client-contact-initiator', displayName: 'Initiator' },
    { roleID: 'R2', roleName: roleType.admin, roleDescrpition: 'client-contact-administrator', displayName: 'Admin' },
  ];

  emptyLevel: ClientRole[] = [
    { roleID: '', roleName: roleType.noRole, roleDescrpition: '' }
  ];

  constructor() { }

  /* Return the candidate json list and loop to display in the table */
  getRoles(): ClientRole[] {
    return this.levelList;
  }

  /* Return the level Id*/
  getRoleID(roleName) {
    for (let i = 0; i < this.levelList.length; i++) {
      // look for the entry with a matching `code` value
      if (this.levelList[i].roleName === roleName) {
        return this.levelList[i];
      }
    }
    return this.emptyLevel[0];
  }

  /* Return the level Id*/
  getRoleDetails(displayName) {
    for (let i = 0; i < this.levelList.length; i++) {
      // look for the entry with a matching `code` value
      if (this.levelList[i].displayName === displayName) {
        return this.levelList[i];
      }
    }
    return this.emptyLevel[0];
  }

}
