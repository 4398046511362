<div class="empsettingsContainer settingsContainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px">
    <div class="candidate-dialogtitle">Employee Details</div>
    <a href="#" class="close-icon" (click)="onNoClick($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-dialog-content>
    <form [formGroup]="employeeForm">
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
        fxLayoutGap.xs="0em">
        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field>
            <input matInput placeholder="Full Name" readonly formControlName="EmployeeName">
          </mat-form-field>
        </div>
      </div>
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
        fxLayoutGap.xs="0em">
        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field fxFlex [floatLabel]="employeeForm.controls['Email'].disabled ? 'never' : 'auto'">
            <input matInput type="text" placeholder="Email" readonly formControlName="Email" >
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="role-txt">Roles</div>
    <div class="settings-tablecol">
      <div class="settings-scrolldiv">
        <mat-grid-list [cols]="gridColumn" rowHeight="35px">
          <mat-grid-tile *ngFor="let selection of data.roles">
            <mat-checkbox [checked]="selection.status" (change)="updateChkbx(selection)">{{selection.roleName}} ({{selection.key}})
            </mat-checkbox>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
    <div class="settings-selectedcount"><span>{{rolesCount}}</span> Selected</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="statusBtn">
      <button class="mat-button text-button" type="button" color="primary" (click)="onNoClick($event)">CANCEL</button>
      <button class="contained-button mat-button" id="save" (click)="save()">OK</button>
    </div>
  </mat-dialog-actions>
</div>
