<ng-template [appReadonly]="['Role Capability Maintenance']" (valueChange)="isDisabled($event)"></ng-template>
<div class="header-access-container">
  <div class="header-text-container">
    <h1 class="heading">Role Capability Maintenance</h1>
  </div>
  <div *ngIf="disable" id="readonly-indicator">
    <span>Read Only</span>
    <mat-icon>edit_off</mat-icon>
  </div>
</div>
<mat-tab-group animationDuration="0ms" disableRipple>
  <mat-tab>
    <ng-template mat-tab-label>Roles</ng-template>
    <ng-template matTabContent>
      <app-rolecaps-roles-list></app-rolecaps-roles-list>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>Capabilities</ng-template>
    <ng-template matTabContent>
      <app-rolecaps-capability-list></app-rolecaps-capability-list>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>Mapping</ng-template>
    <h2>Role &amp; Capability Mappings</h2>
    <ng-template matTabContent>
      <app-rolecaps-mappings></app-rolecaps-mappings>
    </ng-template>
  </mat-tab>
</mat-tab-group>
