import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { urlType } from 'src/app/core/models/urlType';
import { RemoteLoggingService } from './remote-logging.service';
import { PartySharedService } from './party-shared.service';

/** FeatureFlagService to fetch feature flags from Api */
@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   * @param partySharedSvc PartySharedService injection
   */
  constructor(
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly partySharedSvc: PartySharedService
  ) {}

  /** feature flag subjects */
  teamsFeatureFlag$ = new BehaviorSubject<string>('');
  userAlignmentFeatureFlag$ = new BehaviorSubject<string>('');
  entitlementManagerFlag$ = new BehaviorSubject<string>('');

  /** feature flag call subjects */
  teamsFeatureFlagCalled$ = new BehaviorSubject<boolean>(false);
  userAlignmentFeatureFlagCalled$ = new BehaviorSubject<boolean>(false);
  entitlementManagerFlagCalled$ = new BehaviorSubject<boolean>(false);

  /** feature flag subject map */
  subjMap = new Map([
    ['Manage Teams', this.teamsFeatureFlag$],
    ['User Alignment', this.userAlignmentFeatureFlag$],
    ['Entitlement Manager', this.entitlementManagerFlag$]
  ]);

  /** feature flag key map */
  keyMap = new Map([
    ['Manage Teams', 'mobilifyhr-enable-teams'],
    ['User Alignment', 'mobilifyhr-enable-user-alignment'],
    ['Entitlement Manager', 'mobilifyhr-entitlement-manager']
  ]);

  /** feature flag call map */
  callMap = new Map([
    ['Manage Teams', this.teamsFeatureFlagCalled$],
    ['User Alignment', this.userAlignmentFeatureFlagCalled$],
    ['Entitlement Manager', this.entitlementManagerFlagCalled$]
  ]);

  /**
   * To get get a feature flag
   * @param {string} name flag name
   */
  async getFeatureFlag(name: string): Promise<boolean> {
    if (this.subjMap.get(name) && this.keyMap.get(name) && this.callMap.get(name)) {
      const subject$ = this.subjMap.get(name)
      const key = this.keyMap.get(name)
      const call$ = this.callMap.get(name)
      if (!subject$.value) {
        if (call$.value) {
          subject$.subscribe({
            next: response => {
              if (response) {
                return Promise.resolve(response)
              }
            }
          })
        } else {
          await this.partySharedSvc.getPartyId().then(async partyId => {
            const body = {
              partyId: partyId,
              clientId: null
            };
            const response = await lastValueFrom(this.getFeatureFlagFromApi(key, body));
            call$.next(true)
            subject$.next(response)
          })
        }
      }
      return Promise.resolve(subject$.value && subject$.value.toString() === 'true');
    } else {
      console.warn(`WARNING: feature flag '${name}' not found`)
      return Promise.resolve(null)
    }
  }

  /**
   * Feature flag API call
   * @param {string} name flag name
   * @param {object} body request body
   */
  getFeatureFlagFromApi(name: string, body: object): Observable<any> {
    return this.baseClient
      .post<string>(`/v1/featureFlag/${name}`, body, 'get the feature flag', urlType.alpha)
      .pipe(
        map(r => r.body),
        catchError(err => {
          const empty: string = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
    }

}
