import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { LoggerService } from './logger.service';
import { map, catchError, delay } from 'rxjs/operators';
/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class ConsultantService {
  /**
   * base constructor
   * @param baseClientService baseclient service
   * @param logSvc remote logging service
   * @param customLogger logger service
   */
  constructor(private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService) { }
  /**
   * service call to get all consulant details
   * @param queryString query string values
   */
  getAllConsultants(queryString: string): Observable<any> {
    return this.baseClientService
      .get<any>(`/consultant?${queryString}`)
      .pipe(
        map(r => r),
        catchError(err => {
          this.customLogger.error('Failed to fetch clients', err);
          const emptyArray: any = null;
          return of(emptyArray);
        })
      );
  }


  /** call to get client list
   * @param body client associations and search term
   */
  fetchClients(body): Observable<any> {
    return this.baseClientService
      .post<any>(`/client/searchClients`, body)
      .pipe(
        map(r => r.body),
        catchError(er => {
          this.customLogger.error('Failed to fetch clients', er);
          const emptyArray: any = null;
          return of(emptyArray);
        })
      );
  }

  /** service call to update client associations
   * @param body employee id and associated client ids
   */
  saveData(body): Observable<any> {
    return this.baseClientService
      .put<any>(`/consultant/clientAssociation`, body)
      .pipe(
        map(r => r),
        catchError(error => {
          this.customLogger.error('Failed to update associations', error);
          const emptyArray: any = null;
          return of(emptyArray);
        })
      );
  }
}

