import { Injectable } from '@angular/core';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { Observable, of } from 'rxjs';
import { map, catchError, delay, shareReplay } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { urlType } from 'src/app/core/models/urlType';
import { Team, UpdateTeam, Person, Client, UpdateMembers, UpdateClients, TeamRoles } from '../models/teams.model';
import { teamStatuses } from '../models/constants';
import * as querystring from 'querystring';
import { teamsMock, employeesMock, clientsMock, teamRolesMock } from 'src/UnitTest-Support/Mocks/teams.mock';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {

  teamRolesCache: {
    expires?: Date,
    value?: Observable<any>
  } = {};

  CACHE_DURATION = 30 * 60 * 1000;

  /**
   * base constructor
   * @param baseClientService baseclient service
   * @param logSvc remote logging service
   * @param customLogger logger service
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService
  ) { }

  /**
   * Return the team for the specfied id
   * @param teamId team id
   */
  getTeam(teamId: string): Observable<any> {
    return this.baseClientService
    .get<any>(`/team/${teamId}`, 'Get Team by id', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to get team', err);
      })
    );
  }

  /**
   * Create a new team
   * @param team team
   */
  createTeam(team: Team): Observable<any> {
    return this.baseClientService
    .post<any>('/team', team, 'Create Team', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to create team', err);
      })
    );
  }

  /**
   * Return all of the teams for the specfied filter
   * @param queryString querystring values
   */
  getTeams(queryString: string): Observable<any> {
    return this.baseClientService
    .get<any>(`/teams?${queryString}`, 'Get Teams', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to get teams', err);
      })
    );
  }

  /**
   * Update an existing team
   * @param team team
   */
  updateTeam(teamId: string, team: UpdateTeam): Observable<any> {
    return this.baseClientService
    .put<any>(`/team/${teamId}`, team, 'Update Team', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to update team', err);
      })
    );
  }

  /**
   * Return all of the employees for the specfied filter
   * @param queryString querystring values
   */
  getEmployees(queryString: string): Observable<any> {
    return this.baseClientService
    .get<any>(`/employee?${queryString}`, 'Get Employees', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to get employees', err);
      })
    );
  }

  /**
   * Add employees to an existing team
   * @param update UpdateMembers
   */
  addEmployees(teamId: string, update: UpdateMembers): Observable<any> {
    return this.baseClientService
    .post<any>(`/team/${teamId}/addEmployees`, update, 'Add Employees To Team', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to add employees to team', err);
      })
    );
  }

  /**
   * Remove employees from an existing team
   * @param update UpdateMembers
   */
  removeEmployees(teamId: string, update: UpdateMembers): Observable<any> {
    return this.baseClientService
    .put<any>(`/team/${teamId}/removeEmployees`, update, 'Remove Employees From Team', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to remove employees from team', err);
      })
    );
  }

  /**
   * Return all of the clients for the specfied filter
   * @param queryString querystring values
   */
  getClients(queryString: string): Observable<any> {
    return this.baseClientService
    .get<any>(`/clients?${queryString}`, 'Get clients', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to get clients', err);
      })
    );
  }

  /**
   * Add clients to an existing team
   * @param update UpdateClients
   */
  addClients(teamId: string, update: UpdateClients): Observable<any> {
    return this.baseClientService
    .post<any>(`/team/${teamId}/addClients`, update, 'Add Clients To Team', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to add clients to team', err);
      })
    );
  }

  /**
   * Remove clients from an existing team
   * @param update UpdateClients
   */
  removeClients(teamId: string, update: UpdateClients): Observable<any> {
    return this.baseClientService
    .put<any>(`/team/${teamId}/removeClients`, update, 'Remove Clients From Team', urlType.opdata)
    .pipe(
      map(r => r),
      catchError(err => {
        return this.catchBlock('Failed to remove clients from team', err);
      })
    );
  }

  /**
   * Return the team statuses
   */
  getStatuses(): Observable<any> {
    return of(teamStatuses);
  }

  /**
   * Return the team statuses
   */
  getRoles(): Observable<any> {
    if (!this.teamRolesCache.expires || (this.teamRolesCache.expires && this.teamRolesCache.expires < new Date())) {
      this.teamRolesCache = {
        expires: new Date(new Date().getTime() + this.CACHE_DURATION),
        value: this.baseClientService
        .get<any>('/teams/roles', 'Get Roles', urlType.opdata)
        .pipe(
          map(r => r),
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError(err => {
            return this.catchBlock('Failed to get roles', err);
          })
        )
      };
    }
    return this.teamRolesCache.value;
  }

  catchBlock(message: string, error: any) {
    this.customLogger.error(message, error);
    return of(error);
  }

}
