import { Injectable } from '@angular/core';
import * as moment from 'moment';
import  callerPath from 'caller-path';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


export class LoggerService {
  constructor(private readonly http: HttpClient) {
  }


  log(logData: object) {
    // const logStashUrl = '';
    // if (logStashUrl.length) {
    //   const url = logStashUrl;
    //   const body = logData;
    //   this.http.post(`${url}`, body).subscribe(
    //     success => {
    //       console.log(success);
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
    // } else {
      console.log(logData);
    // }
  }

  public error(message: string, errorStackTrace?: object, errorSource?: string, additionalErrorLogData?: object): any {
    let errLoc;

    try {
      errLoc = callerPath();
    } catch (err) {
      errLoc = errorSource;
    }

    const erroLogObject = {
      TimeStamp: moment().format(),
      Message: message,
      Error: errorStackTrace,
      ErrorLocation: errLoc,
      AdditionalErrorInfo: additionalErrorLogData
    };
    this.log(erroLogObject);

  }

  public info(message: string, infoLog?: object, infoSource?: string, additionalInfoLogData?: object): any {
    const infoLogObject = {
      TimeStamp: moment().format(),
      Message: message,
      Info: infoLog,
      InfoSource: infoSource || callerPath(),
      AdditionalInfoLogData: additionalInfoLogData
    };
    this.log(infoLogObject);
  }

  public dataAudit(type: Object, userId: string, event: string, newValue?: object, previousValue?: object): any {
    const dataAuditLogObject = {
      Source: 'ALPHA_UI',
      AuditType: type,
      Event: event,
      UserId: userId,
      PreviousValue: previousValue,
      NewValue: newValue,
      TimeStamp: new Date()
    };
    this.log(dataAuditLogObject);
  }

  public activityAudit(type: Object, userId: string, event: string, activity?: string): any {
    const activityAuditLogObject = {
      Source: 'ALPHA_UI',
      AuditType: type,
      Event: event,
      UserId: userId,
      Activity: activity,
      TimeStamp: new Date()
    };
    this.log(activityAuditLogObject);
  }
}
