import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { PartySharedService } from './party-shared.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private roleCapability: any;
  private readonly encryptedResponse: any;
  private readonly subscription: Subscription = new Subscription();


  constructor(
    private readonly partySharedService: PartySharedService
  ) {

  }

  public async checkAuthorization(path: any) {
    const partyId = await this.partySharedService.getPartyId();
    this.roleCapability = await this.partySharedService.getRoleCapabilities(partyId);
    return this.doCheckAuthorization(path);
  }

  private doCheckAuthorization(path: any): boolean {
    const keys = this.parsePath(path);
    let result = true;
    if (keys.length > 0) {
      result = false;
    }
    if (!!this.roleCapability && !!this.roleCapability.roleCapabilities && this.roleCapability.roleCapabilities.length > 0) {
      for (const pathValue of keys) {
        for (const capability of this.roleCapability.roleCapabilities) {
            if (pathValue === capability.name) {
              return true;
            }
        }
      }
    }
    return result;
  }

  public async doCheckReadAuthorization(path: any) {
    const keys = this.parsePath(path);
    let result = true;
    if (keys.length > 0) {
      result = false;
    }
    if (!!this.roleCapability && !!this.roleCapability.roleCapabilities && this.roleCapability.roleCapabilities.length > 0) {
      for (const pathValue of keys) {
        for (const capability of this.roleCapability.roleCapabilities) {
            if ((pathValue === capability.name) && (capability.operation === 'read')) {
              result = true;
            } else if ((pathValue === capability.name) && (capability.operation === 'write')) {
              // if same capability has write operation, this takes precedence
              return false;
            }
        }
      }
    }
    return result;
  }


  private parsePath(path: any): string[] {
    if (typeof path === 'string') {
      return path.split('.');
    }
    if (Array.isArray(path)) {
      return path;
    }
    return [];
  }

  public async getRolesAndCapabilities() {
    return this.roleCapability;
  }
}
