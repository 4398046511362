import { Component, OnInit, HostBinding } from '@angular/core';
import { MenuSetupService } from '../../services/menu-setup.service';
import { MenuItem } from '../../models/Menu.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  /** component css class */
  @HostBinding('class') class = 'app-menu';

  /** is the menu open */
  menuIsOpen: boolean = false;

  /** menu items */
  menuItems: Array<MenuItem>;

  /** loading */
  loading: boolean = true;

  constructor(
    private readonly menuSetupService: MenuSetupService,
  ) { }

  ngOnInit(): void {
    this.menuSetupService.menuItems.subscribe({
      next: (resp: Array<MenuItem>) => {
        if (resp?.length > 0) {
          this.menuItems = resp;
          this.loading = false;
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }

  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  onItemSelected(selected: boolean) {
    if (selected) {
      this.menuIsOpen = false;
    }
  }

}
