<app-teams-header aria-live="assertive" aria-atomic="true" [loaded]="loaded" [showAddIcon]="showAddIcon" [showItemCount]="showItemCount"
[heading]="heading" [itemCount]="itemCount" [subHeading]="subHeading"></app-teams-header>
<section class="search_table">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex="60%" fxLayoutAlign="start">
      <mat-form-field appearance="outline" class="search-within-table search-box">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput id="searchInput" (input)="applyFilter($event.target.value)" [value]="filterText"
          placeholder="Search within current page for..." width="80%" fxFlex.sm="65">
      </mat-form-field>
      <span fxLayoutAlign="start center">
        <button type="button" class="button-icon" [class.active]="searchActive" (click)="openTeamSearchDialog()"
        matTooltip="Team Search" matTooltipPosition="right">
          <mat-icon>filter_list</mat-icon>
        </button>
      </span>
    </span>
  </div>
</section>
<section class="content_table teams-table">
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0">
        <table mat-table [dataSource]="dataSource" matSort
        [matSortActive]="sortBy" matSortDirection="asc" (matSortChange)="sortData($event)" matSortDisableClear>
          <!-- Team Name Column -->
          <ng-container matColumnDef="teamName" sticky>
            <th mat-header-cell id="name" *matHeaderCellDef mat-sort-header="teamName">Team Name</th>
            <td mat-cell *matCellDef="let element" class="cLinks">
              <span class="text-as-link teams-col-name" [innerHTML]="element.name | highlightSearch: filterText"></span>
            </td>
          </ng-container>
          <!-- Team Owner Column -->
          <ng-container matColumnDef="teamOwner">
            <th mat-header-cell id="owner" *matHeaderCellDef mat-sort-header="teamOwner">Team Owner</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="element.owner !== null ? element.owner : '' | highlightSearch: filterText"></span>
          </ng-container>
          <!-- Team Status Column -->
          <ng-container matColumnDef="teamStatus">
            <th mat-header-cell id="status" *matHeaderCellDef mat-sort-header="teamStatus">Team Status</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="element.status | highlightSearch: filterText"></span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToTeamDetails(row)"></tr>
        </table>
      </div>
      <div [hidden]="!(ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0)">
        <mat-paginator [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage" [pageSizeOptions]="pageInfo.perPageOptions"
        [pageIndex]="pageInfo.currentPage" (page)="onPagination($event)" [disabled]="pageInfo.itemCount === 0"></mat-paginator>
      </div>
      <div [hidden]="!dataSource || (ELEMENT_DATA?.length > 0 && dataSource.filteredData.length > 0) || !loaded" class="no-data" role="alert">
        No records found
      </div>
    </section>
  </div>
</section>
