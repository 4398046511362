import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { EntitlementManagerService } from '../../services/entitlement-manager.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-entitlement-manager-dashboard',
  standalone: false,
  templateUrl: './entitlement-manager-dashboard.component.html',
  styleUrl: './entitlement-manager-dashboard.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class EntitlementManagerDashboardComponent implements OnInit {

  constructor(
    public spinner: NgxSpinnerService,
    private readonly entitlementManagerService: EntitlementManagerService
  ) {}

  ngOnInit() {
    this.spinner.hide();
  }

}
