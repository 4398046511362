<ng-template [appReadonly]="['Manage Teams']" (valueChange)="isDisabled($event)"></ng-template>
<mat-card class="team-card">
  <mat-card-header class="team-card-header">
    <div mat-card-avatar><mat-icon class="team-card-avatar">group_work</mat-icon></div>
    <mat-card-title class="team-card-title">{{title}}</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="team-card-content">
    <form fxLayout="column" [formGroup]="teamProfileForm">
      <div>
        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2em" fxLayout.lt-md="column" fxLayoutGap.lt-md="0em">
          <div fxFlex="0 0 calc(50% - 1em)" fxLayout="column" fxLayoutAlign="start" fxFlex.lt-md="100%">
            <mat-form-field>
              <input matInput placeholder="Team Name" formControlName="name" id="team-name">
              <mat-error>{{ getErrorMessage('NAME') }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="0 0 calc(50% - 1em)" fxLayout="column" fxLayoutAlign="start" fxFlex.lt-md="100%">
            <mat-form-field>
              <input id="TeamOwner" matInput placeholder="Team Owner" id="team-owner" formControlName="owner" spellcheck="false" [readonly]="true" (click)="openEmployeeSearchDialog()" (keydown.space)="openEmployeeSearchDialog()">
              <button mat-button type="button" *ngIf="!teamProfileForm.controls.owner.value" matSuffix mat-icon-button
              (click)="openEmployeeSearchDialog()" (keydown.space)="openEmployeeSearchDialog()" [disabled]="disable">
                <mat-icon>search</mat-icon>
              </button>
              <button mat-button type="button" *ngIf="teamProfileForm.controls.owner.value" matSuffix mat-icon-button
              (click)="teamProfileForm.controls.owner.setValue('');teamProfileForm.controls.ownerId.setValue('')" [disabled]="disable">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error>{{ getErrorMessage('OWNER') }}</mat-error>
           </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="2em" fxLayout.lt-md="column" fxLayoutGap.lt-md="0em">
          <div fxFlex="0 0 calc(50% - 1em)" fxLayout="column" fxLayoutAlign="start" fxFlex.lt-md="100%">
            <mat-form-field>
              <mat-label>Team Status</mat-label>
              <mat-select formControlName="status" id="status" (selectionChange)="statusChange($event)">
                <mat-option *ngFor="let teamStatus of teamStatuses" [value]="teamStatus">
                  {{teamStatus}}
                </mat-option>
              </mat-select>
              <mat-error>{{ getErrorMessage('STATUS') }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="0 0 calc(25% - 1.5em)" fxLayout="column" fxLayoutAlign="start" fxFlex.lt-md="100%">
            <mat-form-field>
              <input matInput id="effectiveDate" [matDatepicker]="effectiveDate"  [max]="maxEffectiveDateFilter()"  placeholder="Effective Date" formControlName="effectiveDate"
              [readonly]="teamProfileForm.get('status').value === 'Inactive'">
              <mat-datepicker-toggle matSuffix [for]="effectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #effectiveDate [disabled]="teamProfileForm.get('status').value === 'Inactive' || disable"></mat-datepicker>
              <mat-error>{{ getErrorMessage('EFFECTIVE_DATE') }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="0 0 calc(25% - 1.5em)" fxLayout="column" fxLayoutAlign="start" fxFlex.lt-md="100%">
           <mat-form-field>
            <input matInput id="expirationDate" [matDatepicker]="expirationDate"  [min]="minExpirationDateFilter()" placeholder="Expiration Date" formControlName="expirationDate"
            [readonly]="teamProfileForm.get('status').value === 'Inactive'">
            <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
            <mat-datepicker #expirationDate [disabled]="teamProfileForm.get('status').value === 'Inactive' || disable"></mat-datepicker>
            <mat-error>{{ getErrorMessage('EXPIRATION_DATE') }}</mat-error>
          </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions class="team-card-actions">
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1em">
      <button mat-button id="cancel" class="button-outline-sm" *ngIf="teamProfileForm.dirty && formChange" (click)="cancel()" [disabled]="disable">
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
      <button mat-button id="save" class="button-sm" *ngIf="teamProfileForm.dirty && teamProfileForm.valid && formChange" (click)="openSaveTeamProfileConfirmDialog()" [disabled]="disable">
        <mat-icon>check_circle</mat-icon>
        <span>Save</span>
      </button>
    </div>
  </mat-card-actions>
</mat-card>
