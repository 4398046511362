import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Team } from '../../models/teams.model';
import { Subscription } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiErrorMessage, apiTeamNotFound } from '../../models/constants';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent implements OnDestroy {

  /** team */
  team: Team;

  /** team name */
  teamName: string;

  /** has the profile form changed */
  profileFormChanged = false;

  /** team not found */
  notFound = false;

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras && navigation.extras.state) {
      this.team = <Team>navigation.extras.state;
    } else {
      this.route.paramMap.subscribe((params: ParamMap) => {
        const teamId = params.get('teamId');
        if (teamId) {
          this.getTeam(teamId);
        } else {
          this.router.navigate(['/project-alpha/teams']);
        }
      });
    }
  }

  /** Get the Team */
  getTeam(teamId: string) {
    this.spinner.show();
    this.subscription.add(
      this.teamsService.getTeam(teamId).subscribe({
        next: response => {
        if (response && response.status === 404) {
          this.team = undefined;
          this.notFound = true;
          this.notificationsService.flashNotification('danger', apiTeamNotFound);
        } else if (response && response.status === 200 && response.body) {
          this.team = response.body;
          this.notFound = false;
          this.teamName = this.team.name;
        } else {
          this.notificationsService.flashNotification('danger', apiErrorMessage);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get team', err);
        this.notificationsService.flashNotification('danger', apiErrorMessage);
      },
      complete: () => {
        this.spinner.hide();
      }})
    );
  }

  /** Reload the team after an update */
  teamChanged(event: { updated: boolean, team: Team }) {
    if (event.updated) {
      this.team = event.team;
    }
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
