<ng-container *ngIf="item">
  <ng-container *ngIf="item && item.children && item.children.length > 0">
    <div class="sub-menu" #subMenu [ngClass]="subMenuIsOpen ? 'open' : ''">
      <span class="sub-menu-heading">
        <ng-container *ngIf="item.path && item.path.length > 0">
          <a class="menu-item-link" [routerLink]="[item.path]" (click)="onItemSelected()" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
            <mat-icon *ngIf="item.icon && item.icon.length > 0" class="menu-item-icon">{{ item.icon }}</mat-icon>
            <span>{{ item.label }}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="!item.path || item.path.length === 0">
          <span class="menu-item-label">
            <mat-icon *ngIf="item.icon && item.icon.length > 0" class="menu-item-icon">{{ item.icon }}</mat-icon>
            <span>{{ item.label }}</span>
          </span>
        </ng-container>
        <mat-icon class="sub-menu-toggle" *ngIf="subMenuIsOpen" aria-hidden="false" (click)="toggleSubMenu()" (keydown.enter)="toggleSubMenu()">expand_less</mat-icon>
        <mat-icon class="sub-menu-toggle" *ngIf="!subMenuIsOpen" aria-hidden="false" (click)="toggleSubMenu()" (keydown.enter)="toggleSubMenu()">expand_more</mat-icon>
      </span>
      <div class="sub-menu-items">
        <ng-container *ngFor="let item of item.children; let index = index">
          <app-menu-item (itemSelected)=onItemSelected() [item]="item"></app-menu-item>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="item && item.path && item.path.length > 0 && (!item.children || item.children.length === 0)">
    <a class="menu-item-link" [routerLink]="[item.path]" (click)="onItemSelected()" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
      <mat-icon *ngIf="item.icon && item.icon.length > 0" class="menu-item-icon">{{ item.icon }}</mat-icon>
      <span>{{ item.label }}</span>
    </a>
  </ng-container>
</ng-container>