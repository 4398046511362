import { Component, OnInit, HostBinding, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RoleCapabilityService } from 'src/app/rolecaps/services/role-capability.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { CapabilityDefinition } from 'src/app/rolecaps/models/definition.model';
import { OperationType } from 'src/app/core/models/OperationType';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, from, lastValueFrom, firstValueFrom } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { ADD_CAP_TITLE, EDIT_CAP_TITLE, CAP_UI_NAME_VAL_PATTERN, CAP_UI_NAME_VAL_PATTERN_ERROR, CAP_NAME_VAL_REQ_ERROR,
         CAP_CORE_NAME_VAL_PATTERN, CAP_CORE_NAME_VAL_PATTERN_ERROR, CAP_NAME_VAL_MIN_LENGTH_ERROR, CAP_NAME_VAL_MAX_LENGTH_ERROR,
         CAP_DESC_VAL_REQ_ERROR, CAP_DESC_VAL_PATTERN, CAP_DESC_VAL_PATTERN_ERROR, CAP_DESC_VAL_MIN_LENGTH_ERROR,
         CAP_DESC_VAL_MAX_LENGTH_ERROR, CAP_LEVEL_VAL_REQ_ERROR, CAP_APP_VAL_REQ_ERROR, APPS_KEY, GET_APPS_ERROR, CONCURRENT_REQUESTS,
         ADD_CAP_SUCCESS, EDIT_CAP_SUCCESS, ADD_CAP_ERROR, EDIT_CAP_ERROR, CAP_ALREADY_EXISTS_ERROR, CAPS_APPS_MAP_KEY,
         GET_CAP_APP_MAP_ERROR, GET_ROLECAPS_ERROR, CAPS_KEY, GET_CAP_KEY_ERROR } from 'src/app/rolecaps/models/constants';
import * as querystring from 'querystring';

@Component({
  selector: 'app-rolecaps-add-edit-cap',
  templateUrl: './rolecaps-add-edit-cap.component.html',
  styleUrls: ['./rolecaps-add-edit-cap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RolecapsAddEditCapComponent implements OnInit, OnDestroy {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-add-edit-cap';

  /** Form group name */
  addEditCapForm: UntypedFormGroup;

  /** capabilityNameValue to display the dialog window page capabilityName */
  capabilityNameValue: string = '';

  /** Title to display the dialog window page title */
  title: string;

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Processing */
  processing = false;

  /** Edit Role Definition */
  editCapability: CapabilityDefinition;

  /** Operation Type enum */
  OperationType = OperationType;

  /** Applications values */
  apps = [];

  /** Level values */
  levels = ['Core', 'UI'];

  /** Application prefix map */
  appPrefixMap = new Map([
    ['default', ''],
    ['movePro360', 'MP - '],
    ['mobilifyHR', ''],
    ['supplierPortal', ''],
    ['benefitsBuilder', 'BB - '],
    ['cartusOnline', ''],
    ['compensationServices', 'COMP - '],
    ['atlas', ''],
    ['atlasReporting', '']
  ]);

  /** Application prefix map getter */
  getAppPrefix = (app: string) => this.appPrefixMap.get(app) || this.appPrefixMap.get('default');

  /** Injecting dependencies */
  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RolecapsAddEditCapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data && data.capability) {
      this.editCapability = this.data.capability;
    }
    this.addEditCapForm = this.formBuilder.group({
      capabilityName: [
        { value: this.editCapability && this.editCapability.capabilityName || '', disabled: this.editCapability },
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50)
        ])
      ],
      description: [
        { value: this.editCapability && this.editCapability.description || '', disabled: false },
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(200),
          Validators.pattern(CAP_DESC_VAL_PATTERN)
        ])
      ],
      level: [
        { value: this.editCapability && this.editCapability.level || '', disabled: false },
        Validators.compose([
          Validators.required
        ])
      ],
      application: [
        {
          value: this.editCapability && this.editCapability.hasOwnProperty('application')
          ? this.editCapability.application
          : '', disabled: false
        },
        Validators.compose([
          Validators.required
        ])
      ],
    });
    const capNameCorePatternValidator = Validators.pattern(CAP_CORE_NAME_VAL_PATTERN)
    const capNameUiPatternValidator = Validators.pattern(CAP_UI_NAME_VAL_PATTERN)
    this.addEditCapForm.controls.level.valueChanges.subscribe(val => {
      if (val && val === 'UI') {
        this.addEditCapForm.controls.application.setValidators(Validators.required);
        this.addEditCapForm.controls.capabilityName.removeValidators(capNameCorePatternValidator)
        this.addEditCapForm.controls.capabilityName.addValidators(capNameUiPatternValidator)
      } else if (val && val === 'Core') {
        this.addEditCapForm.controls.application.clearValidators();
        this.addEditCapForm.controls.capabilityName.removeValidators(capNameUiPatternValidator)
        this.addEditCapForm.controls.capabilityName.addValidators(capNameCorePatternValidator)
      } else {
        this.addEditCapForm.controls.application.clearValidators();
        this.addEditCapForm.controls.capabilityName.removeValidators(capNameUiPatternValidator)
        this.addEditCapForm.controls.capabilityName.removeValidators(capNameCorePatternValidator)
      }
      this.addEditCapForm.controls.application.updateValueAndValidity();
      this.addEditCapForm.controls.capabilityName.updateValueAndValidity();
    });
    this.addEditCapForm.controls.level.updateValueAndValidity();
  }

  /** To initialize the component */
  ngOnInit() {
    this.title = this.editCapability ? EDIT_CAP_TITLE : ADD_CAP_TITLE;
    setTimeout(() => this.loadApplications());
  }

  /** Get the application options */
  loadApplications() {
    this.processing = true;
    const queryString = {};
    queryString['key'] = APPS_KEY;
    this.roleCapabilityService.getValuelist(querystring.stringify(queryString)).subscribe({
      next: response => {
        if (response && response.status === 200) {
          this.apps = response.body.values.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
          this.apps.forEach(app => {
            const prefix = this.getAppPrefix(app.name);
            const displaySuffix = prefix.length > 0 ? ` (${prefix})` : '';
            app.displayName = `${app.displayName}${displaySuffix}`;
          });
        } else {
          this.notificationsService.flashNotification('danger', GET_APPS_ERROR);
        }
      },
      error: error => {
        this.loggerService.error('Failed to get applications', error);
        this.notificationsService.flashNotification('danger', GET_APPS_ERROR);
      },
      complete: () => {
        this.processing = false;
      }
    });
  }

  /**
   * Custom error messages
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(fieldName) {
    const levelValue = this.addEditCapForm.controls.level.value
    if (fieldName === 'CAPABILITY_NAME') {
      return this.addEditCapForm.get('capabilityName').hasError('required')
        ? CAP_NAME_VAL_REQ_ERROR
        : this.addEditCapForm.get('capabilityName').hasError('pattern') && levelValue === 'Core'
          ? CAP_CORE_NAME_VAL_PATTERN_ERROR 
          : this.addEditCapForm.get('capabilityName').hasError('pattern') && levelValue === 'UI'
            ? CAP_UI_NAME_VAL_PATTERN_ERROR
            : this.addEditCapForm.get('capabilityName').hasError('minlength')
              ? CAP_NAME_VAL_MIN_LENGTH_ERROR
              : this.addEditCapForm.get('capabilityName').hasError('maxlength')
                ? CAP_NAME_VAL_MAX_LENGTH_ERROR
                : '';
    }
    if (fieldName === 'DESCRIPTION') {
      return this.addEditCapForm.get('description').hasError('required')
        ? CAP_DESC_VAL_REQ_ERROR
        : this.addEditCapForm.get('description').hasError('pattern')
          ? CAP_DESC_VAL_PATTERN_ERROR
          : this.addEditCapForm.get('description').hasError('minlength')
            ? CAP_DESC_VAL_MIN_LENGTH_ERROR
            : this.addEditCapForm.get('description').hasError('maxlength')
              ? CAP_DESC_VAL_MAX_LENGTH_ERROR
              : '';
    }
    if (fieldName === 'LEVEL') {
      return this.addEditCapForm.get('level').hasError('required')
        ? CAP_LEVEL_VAL_REQ_ERROR
        : '';
    }
    if (fieldName === 'APPLICATION') {
      return this.addEditCapForm.get('application').hasError('required')
        ? CAP_APP_VAL_REQ_ERROR
        : '';
    }
    return '';
  }

    /**
   * capabilityNameBasedOnUILevel check
   */
    assigningCapNameBasedOnAppSelection(app) {
      if (this.addEditCapForm.controls.capabilityName.value.includes(' - ')) {
        this.capabilityNameValue = `${this.getAppPrefix(app)}${this.addEditCapForm.controls.capabilityName.value.split(' - ')[1]}`;
      } else {
        this.capabilityNameValue = `${this.getAppPrefix(app)}${this.addEditCapForm.controls.capabilityName.value}`;
      }    
    }
  
    capabilityNameBasedOnUILevel() {
      // assigning capabilityName based on application selection
      const app = this.addEditCapForm.controls.application.value;
      this.assigningCapNameBasedOnAppSelection(app);
    }

  /**
   * capabilityNameBasedOnCoreLevel check
   */
  capabilityNameBasedOnCoreLevel() {
    if (this.addEditCapForm.controls.capabilityName.value.includes(' - ')) { 
      this.capabilityNameValue = this.addEditCapForm.controls.capabilityName.value.split(' - ')[1];
    } else {
      this.capabilityNameValue = this.addEditCapForm.controls.capabilityName.value;
    }
  }

  /**
   * change event for Level field
   * @param event - event parameter
   */
  onLevelChangeEvent(event: any) {
    if (event && event.value === "UI" && this.editCapability !== undefined) {
      this.capabilityNameBasedOnUILevel();
    }
    if (event && event.value === "Core" && this.editCapability !== undefined) {
      this.capabilityNameBasedOnCoreLevel();
    }
    if (this.editCapability !== undefined) this.addEditCapForm.controls.capabilityName.setValue(this.capabilityNameValue);
  }  

  /**
   * change event for Application field
   * @param event - event parameter
   */
  onAppChangeEvent(event: any) {
    if (event && this.addEditCapForm.controls.level.value === "UI" && this.editCapability !== undefined) {
      this.capabilityNameBasedOnUILevel();
    }
    if (event && this.addEditCapForm.controls.level.value === "Core" && this.editCapability !== undefined) {
      this.capabilityNameBasedOnCoreLevel();
    }
    if (this.editCapability !== undefined) this.addEditCapForm.controls.capabilityName.setValue(this.capabilityNameValue);
  }

  /** Create or Update the Capability definition / Role Capability  */
  async createOrUpdateCap(operationType: OperationType) {
    let completed = 0;
    let allResults = [];
    const allErrors = [];
    try {
      this.processing = true;
      const requests = [];
      const capability: CapabilityDefinition = {
        definitionType: 'capability',
        capabilityName: this.capabilityNameValue ? this.capabilityNameValue : this.addEditCapForm.controls.capabilityName.value,
        description: this.addEditCapForm.controls.description.value,
        level: this.addEditCapForm.controls.level.value,
      };
      const level = this.addEditCapForm.controls.level;
      if (level.value && level.value === 'UI') {
        const app = this.addEditCapForm.controls.application.value;
        if (this.editCapability === undefined) capability.capabilityName = `${this.getAppPrefix(app)}${this.addEditCapForm.controls.capabilityName.value}`;
        capability.application = app;
      }
      if (level.value && level.value === 'Core') {
        if (this.editCapability === undefined) capability.capabilityName = this.addEditCapForm.controls.capabilityName.value;
        if (this.editCapability?.application) capability.application = "";
      }
      if (operationType === OperationType.update) {
        // get role capabilities call
        const queryString = {};
        queryString['capabilityName'] = this.editCapability.capabilityName;
        await firstValueFrom(this.roleCapabilityService.getRoleCapabilities(querystring.stringify(queryString))).then(async response => {
          if ((response && response.status === 200 && response.body.length > 0) || (response.toString().indexOf('Role Capability not found.') > -1)) {
            capability.__v = this.editCapability.hasOwnProperty('__v') ? this.editCapability.__v : 0;
            // update definition call
            requests.push(this.roleCapabilityService.updateDefinition(this.editCapability._id ,capability));
            if (response.body) {
              const getRoleCaps = response.body;
              getRoleCaps.forEach(roleCap => {
                let capabilityFromRoleCap = roleCap.capabilities;
                let foundIndex: number = capabilityFromRoleCap.findIndex(roleCapFromCapbilities => 
                  roleCapFromCapbilities.name == this.editCapability.capabilityName);
                capabilityFromRoleCap[foundIndex].name = capability.capabilityName;
                capabilityFromRoleCap[foundIndex].description = capability.description;
                capabilityFromRoleCap[foundIndex].level = capability.level;
                roleCap.capabilities = capabilityFromRoleCap;
                // Updating role capabilities in role capabilities collection
                requests.push(this.roleCapabilityService.updateRoleCapability(roleCap._id, roleCap));
              });              
            }
            if (capability.application) {
              const queryString = {};
              queryString['key'] = CAPS_APPS_MAP_KEY;
              // get value list call using querystring as CapabilitiesApplicationsMap
              await lastValueFrom(this.roleCapabilityService.getValuelist(querystring.stringify(queryString))).then(async resp => {
                if (!resp || (resp && resp.status !== 200)) {
                  throw new Error(GET_CAP_APP_MAP_ERROR);
                } else {
                  const capsAppsMap = resp.body;
                  const capsAppsMapId = capsAppsMap._id;
                  delete capsAppsMap._id;
                  delete capsAppsMap.dataType;
                  let foundIndex: number = capsAppsMap.values.findIndex(value => value.Capabilities === this.editCapability.capabilityName);
                  if (foundIndex !== -1) capsAppsMap.values.splice(foundIndex, 1);
                  if (capsAppsMap.values.findIndex(value => value.Capabilities === capability.capabilityName) === -1) {
                    const mapping = {
                      Capabilities: capability.capabilityName,
                      Applications: capability.application
                    };
                    capsAppsMap.values.push(mapping);
                  }
                  const values = [...capsAppsMap.values].sort((a, b) => a.Capabilities.toLocaleLowerCase()
                    .localeCompare(b.Capabilities.toLocaleLowerCase()));
                  capsAppsMap.values = values;
                  // updating value list based on key as CapabilitiesApplicationsMap
                  requests.unshift(this.roleCapabilityService.updateValuelist(capsAppsMapId, capsAppsMap));
                }
              });
            } else {
              const queryString = {};
              queryString['key'] = CAPS_APPS_MAP_KEY;
              // get value list call using querystring as CapabilitiesApplicationsMap
              await lastValueFrom(this.roleCapabilityService.getValuelist(querystring.stringify(queryString))).then(async resp => {
                if (!resp || (resp && resp.status !== 200)) {
                  throw new Error(GET_CAP_APP_MAP_ERROR);
                } else {
                  const capsAppsMap = resp.body;
                  const capsAppsMapId = capsAppsMap._id;
                  delete capsAppsMap._id;
                  delete capsAppsMap.dataType;
                  let foundIndex: number = capsAppsMap.values.findIndex(value => value.Capabilities === this.editCapability.capabilityName);
                  if (foundIndex !== -1) capsAppsMap.values.splice(foundIndex, 1);
                  const values = [...capsAppsMap.values].sort((a, b) => a.Capabilities.toLocaleLowerCase()
                    .localeCompare(b.Capabilities.toLocaleLowerCase()));
                  capsAppsMap.values = values;
                  // removing capability name object for level core and updating value list based on key as CapabilitiesApplicationsMap
                  requests.push(this.roleCapabilityService.updateValuelist(capsAppsMapId, capsAppsMap));
                }
              });
            }
            const queryStringValueList = {};
            queryStringValueList['key'] = CAPS_KEY;
            // get value list call using querystring as capability
            await firstValueFrom(this.roleCapabilityService.getValuelist(querystring.stringify(queryStringValueList))).then(async resp => {
              if (!resp || (resp && resp.status !== 200)) {
                throw new Error(GET_CAP_KEY_ERROR);
              } else {
                const capsKeyRes = resp.body;
                const capsKeyResId = capsKeyRes._id;
                delete capsKeyRes._id;
                let foundIndex: number = capsKeyRes.values.findIndex(value => value === this.editCapability.capabilityName);
                if (foundIndex !== -1) capsKeyRes.values.splice(foundIndex, 1);
                if (capsKeyRes.values.findIndex(value => value === capability.capabilityName) === -1) {
                  capsKeyRes.values.push(capability.capabilityName);
                }
                const values = [...capsKeyRes.values].sort((a, b) => a.toLocaleLowerCase()
                  .localeCompare(b.toLocaleLowerCase()));
                  capsKeyRes.values = values;
                // updating value list based on key as capabilities
                requests.unshift(this.roleCapabilityService.updateValuelist(capsKeyResId, capsKeyRes));
              }
            });           
          } else {
            throw new Error(GET_ROLECAPS_ERROR);
          }
        });
      } else {
        requests.push(this.roleCapabilityService.createDefinition(capability));
        if (capability.application) {
          const queryString = {};
          queryString['key'] = CAPS_APPS_MAP_KEY;
          await lastValueFrom(this.roleCapabilityService.getValuelist(querystring.stringify(queryString))).then(async resp => {
            if (!resp || (resp && resp.status !== 200)) {
              throw new Error(GET_CAP_APP_MAP_ERROR);
            } else {
              const capsAppsMap = resp.body;
              const capsAppsMapId = capsAppsMap._id;
              delete capsAppsMap._id;
              delete capsAppsMap.dataType;
              if (capsAppsMap.values.findIndex(value => value.Capabilities === capability.capabilityName) === -1) {
                const mapping = {
                  Capabilities: capability.capabilityName,
                  Applications: capability.application
                };
                capsAppsMap.values.push(mapping);
              }
              const values = [...capsAppsMap.values].sort((a, b) => a.Capabilities.toLocaleLowerCase()
                .localeCompare(b.Capabilities.toLocaleLowerCase()));
              capsAppsMap.values = values;
              requests.unshift(this.roleCapabilityService.updateValuelist(capsAppsMapId, capsAppsMap));
            }
          });
        }
        const queryStringValueList = {};
        queryStringValueList['key'] = CAPS_KEY;
        // get value list call using querystring as capability
        await firstValueFrom(this.roleCapabilityService.getValuelist(querystring.stringify(queryStringValueList))).then(async resp => {
          if (!resp || (resp && resp.status !== 200)) {
            throw new Error(GET_CAP_KEY_ERROR);
          } else {
            const capsKeyRes = resp.body;
            const capsKeyResId = capsKeyRes._id;
            delete capsKeyRes._id;
            if (capsKeyRes.values.findIndex(value => value === capability.capabilityName) === -1) {
              capsKeyRes.values.push(capability.capabilityName);
            }
            const values = [...capsKeyRes.values].sort((a, b) => a.toLocaleLowerCase()
              .localeCompare(b.toLocaleLowerCase()));
              capsKeyRes.values = values;
            // updating value list based on key as capabilities
            requests.unshift(this.roleCapabilityService.updateValuelist(capsKeyResId, capsKeyRes));
          }
        });        
      }
      from(requests)
        .pipe(mergeMap(observable => observable, CONCURRENT_REQUESTS), tap(() => {
          completed++;
        }))
        .subscribe({
          next: partialResults => {
            allResults = allResults.concat(partialResults);
          },
          error: error => {
            this.loggerService.error(operationType === OperationType.update ? EDIT_CAP_ERROR : ADD_CAP_ERROR, error);
            this.notificationsService.flashNotification('danger', this.processError(error, operationType));
          },
          complete: () => {
            this.processing = false;
            allResults.forEach(result => {
              if (result && result.status !== 200) {
                allErrors.push(result);
              }
            });
            if (allErrors.length === 0) {
              this.notificationsService.flashNotification('success',
                operationType === OperationType.update ? EDIT_CAP_SUCCESS : ADD_CAP_SUCCESS);
              const respBody = allResults[0].body;
              this.dialogRef.close(respBody);
            } else {
              this.notificationsService.flashNotification('danger', this.processError(allErrors[0], operationType));
            }
          }
        });
    } catch (error) {
      this.processing = false;
      this.loggerService.error(operationType === OperationType.update ? EDIT_CAP_ERROR : ADD_CAP_ERROR, error);
      this.notificationsService.flashNotification('danger', this.processError(error.toString(), operationType));
    }
  }

  /** Translate API errors into user friendly messages */
  processError(err: any, operationType: OperationType): string {
    if (err.toString().indexOf('already exists') > -1) {
      return CAP_ALREADY_EXISTS_ERROR;
    }
    if (err.toString().indexOf('Capability Applications Map') > -1) {
      return GET_CAP_APP_MAP_ERROR;
    }
    if (err.toString().indexOf('modifiedPaths __v') > -1) {
      const errorText = err.toString();
      const word = 'id';
      const result = errorText.slice(errorText.indexOf(word) + word.length);
      const roleCapId = result.split(" ")[1];
      return `The request to update the role capability id ${roleCapId} failed due to version number error`;
    }
    if (operationType === OperationType.update) {
      return EDIT_CAP_ERROR;
    } else {
      return ADD_CAP_ERROR;
    }
  }

  // /** Closes the dialog and clears the form */
  cancel() {
    this.addEditCapForm = this.formBuilder.group({
      capabilityName: [''],
      description: [''],
      level: [''],
      application: ['']
    });
    this.dialogRef.close();
  }


  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
