<ng-template [appReadonly]="['Role Capability Maintenance']" (valueChange)="isDisabled($event)"></ng-template>
<h1 innerHTML={{title}}></h1>
<section id="role-name">
  <h2>Role: <span>{{capabilityTitle}}</span></h2>
  <button type="button" id="add-cap" class="button-icon-xl" (click)="openAddCapDialog()" [disabled]="disable">
    <mat-icon matPrefix id="add_icon">add_circle</mat-icon>
  </button>
</section>
<section id="role-table">
  <h4>Capabilities</h4>
  <div class="mat-elevation-z8">
    <div>
      <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortBy" matSortDirection="asc"
        matSortDisableClear>
        <!-- Name Column -->
        <ng-container matColumnDef="name" sticky>
          <th mat-header-cell id="name" *matHeaderCellDef mat-sort-header="name">Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.name"></span>
        </ng-container>
        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell id="description" *matHeaderCellDef mat-sort-header="description">Description</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.description"></span>
        </ng-container>
        <!-- Operation Column -->
        <ng-container matColumnDef="operation">
          <th mat-header-cell id="operation" *matHeaderCellDef mat-sort-header="operation">Operation</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never" appearance="none">
              <mat-select [(value)]="element.operation" (openedChange)="onMatSelectOpen(element.operation)"
                (selectionChange)="onMatSelectValueChanges($event)">
                <ng-container *ngFor="let opt of operationOptions">
                  <mat-option [value]="opt">{{opt}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <!-- Permission Column -->
        <ng-container matColumnDef="permission">
          <th mat-header-cell id="permission" *matHeaderCellDef mat-sort-header="permission">Permission</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never" appearance="none">
              <mat-select [(value)]="element.permission" (openedChange)="onMatSelectOpen(element.permission)"
                (selectionChange)="onMatSelectValueChanges($event)">
                <ng-container *ngFor="let opt of permissionOptions">
                  <mat-option [value]="opt">{{opt}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell id="actions" *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button disableRipple mat-icon-button class="action-button" [disabled]="disable"
              (click)="openRemoveCapConfirm(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div [hidden]="!(ELEMENT_DATA.length > 0 && dataSource.filteredData.length > 0)">
      <mat-paginator [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage"
        [pageSizeOptions]="pageInfo.perPageOptions" [pageIndex]="pageInfo.currentPage"
        [disabled]="pageInfo.itemCount === 0" (page)="onPagination($event)"></mat-paginator>
    </div>
    <div [hidden]="!dataSource || (ELEMENT_DATA?.length > 0 && dataSource.filteredData.length > 0)" class="no-data" role="alert">
      <p>No Capabilities</p>
    </div>
  </div>
</section>
<section id="role-actions">
  <button mat-button type="button" id="cancel" class="button-outline-sm" (click)="openCancelConfirm()">
    <mat-icon>cancel</mat-icon>
    <span>Cancel</span>
  </button>
  <button mat-button type="button" id="save-role-cap" class="button-sm" *ngIf="hasChanges" (click)="openUpdateRoleCapConfirm()"
    [disabled]="disable">
    <mat-icon>done</mat-icon>
    <span>Save Changes</span>
  </button>
</section>
<app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing">
</app-progress-spinner>
