import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { urlType } from 'src/app/core/models/urlType';

@Injectable({
  providedIn: 'root'
})
export class HotelLeadsFormService {

  constructor(private readonly baseClientService: BaseClientService) { }

  fetchCustomerData(fileId) {
    return this.baseClientService.get(`/vendor/customerFilesById?fileId=${fileId}`, '', urlType.vendor).pipe(
      map(response => response.body)
    );
  }

  sendFormData(formData) {
    return this.baseClientService.post('/vendor/lead', formData, 'submitForm', urlType.vendor).pipe(
        map(response => response.body)
      );
  }

  getLeadByOrderId(orderId) {
    return this.baseClientService.get(`/vendor/leadByOrderId?orderId=${orderId}`, '', urlType.vendor).pipe(
      map(response => response.body)
    );
  }

  getLeadsListData(params?) {
    return this.baseClientService.get(`/vendor/leads${params}`, '', urlType.vendor).pipe(
      map(response => response)
    );
  }

}
