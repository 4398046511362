export interface ClientRole {
    roleID?: string;
    roleName?: roleType;
    displayName?: string;
    name?: string;
    fromDate?: Date;
    roleDescrpition?: string;
}

/** enumeration containing role names */
export enum roleType {
    alpha = 'alpha',
    client = 'client-contact',
    initiator = 'client-contact-initiator',
    admin = 'client-contact-administrator',
    noRole = ''
}
