import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../public/project-alpha/authGuard';
import { ProjectAlphaComponent } from '../public/project-alpha/project-alpha.component';
import { RolecapsDashboardComponent } from './components/rolecaps-dashboard/rolecaps-dashboard.component';

const routes: Routes = [
  {
    path: 'project-alpha',
    component: ProjectAlphaComponent,
    children: [
      {
        path: 'rolecaps',
        component: RolecapsDashboardComponent,
        data: { capabilities: ['Role Capability Maintenance'] },
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolecapsRoutingModule { }
