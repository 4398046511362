import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NgxSpinnerService } from 'ngx-spinner';
import { of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ConsultantDetails } from 'src/app/core/models/consultant.model';
import { apiErrorMessage } from 'src/app/core/models/constants';
import { ConsultantService } from 'src/app/core/services/consultants.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { ConsultantClients } from '../../../../core/models/consultant-clients.model';
import { NotificationsService } from '../../../../../app/core/services/notifications.service';
/** base component for client association popup */
@Component({
  selector: 'app-add-consultant-modal',
  templateUrl: './add-consultant-modal.component.html',
  styleUrls: ['./add-consultant-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddConsultantModalComponent implements OnInit, OnDestroy {
  /** used to store fetched clients  for Consultant Clients*/
  ELEMENT_DATA: ConsultantClients[] = [];
  /** form for displaying Consultant details */
  ConsultantForm: UntypedFormGroup;
  /** columns of client table */
  displayedColumns = ['entityName', 'clientNumber'];
  /** data source for client table */
  dataSource: any;
  /** spinner for search */
  showClientSpinner = false;
  /** flag to check display for search bar */
  displayRow = false;
  /** maintaining 3 arrays-clientRows associated deassociated */
  clientRows: ConsultantClients[] = [];
  associatedArray: ConsultantClients[] = [];
  deAssociatedArray: ConsultantClients[] = [];
  /** flag to check client table display */
  canLoad = false;
  /** for accessibility */
  announceStmt: string;
  /**store the filter value */
  filterText = '';
  /** to search for clients */
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**
   * base constructor
   * @param data Consultant data received from parent component
   * @param dialogRef mat dialog reference
   * @param formBuilder form builder instance
   * @param Logger logger service instance
   * @param loggedInUserService logged in user service instance
   * @param ConsultantSvc Consultant service
   * @param spinner ngx-spinner instance
   * @param snackBar mat-snack instance
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConsultantDetails,
    public dialogRef: MatDialogRef<AddConsultantModalComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService,
    private readonly ConsultantSvc: ConsultantService,
    private readonly spinner: NgxSpinnerService,
    private readonly snackBar: MatSnackBar,
    private readonly notificationsService: NotificationsService,
  ) {
  }
  /** function to be called on init */
  ngOnInit() {
    this.clientRows = this.data.associatedClients.slice();
    this.initializeConsultantForm();
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-ADD_CLIENTS', 'ADD_CLIENT_ASSOCIATIONS');
      });
  }
  /** used to initialise Consultant form for the modal */
  initializeConsultantForm(): void {
    this.ConsultantForm = this.formBuilder.group({
      consultantName: this.data.name,
      Email: this.data.emailAddress,
      Search: ['']
    });
  }
  /**
   * function called on close/cancel button click
   * @param evt click event
   */
  onNoClick(evt): void {
    evt.preventDefault();
    this.dialogRef.close();
  }
  /** used to save client associations clientids need to pass to api to save */
  saveAssociations() {
    this.spinner.show();
    const associatedIds = [];
    const associatedRoleId = [];

    this.associatedArray.forEach(client => {
      associatedIds.push(client.clientId);
    });
    if (this.deAssociatedArray.length > 0) {
      this.deAssociatedArray.forEach(client => {
        if (client.associatedRoleId) {
          associatedRoleId.push(client.associatedRoleId);
        }
      });
    }
    const body = {
      'consultantId': this.data.employeeId,
      'associatedList': associatedIds,
      'deAssociatedList': associatedRoleId
    };
    this.subscription.add(
      this.ConsultantSvc.saveData(body)
        .subscribe(response => {
          if (response) {
            this.dialogRef.close(this.clientRows);
            if (this.associatedArray.length > 0 || this.deAssociatedArray.length > 0) {
              this.notificationsService.flashNotification(
                'success',
                'Updated Client Associations Successfully'
              );
            }
            this.spinner.hide();
          } else {
            this.snackBar.open(
              apiErrorMessage,
              undefined,
              { duration: 5000 }
            );
            this.spinner.hide();
          }
        })
    );
  }
  /**
   * called when search term is input
   * @param search search term
   */
  onClientInput(search) {
    of(search)
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          this.filterText = searchTerm.trim();
          this.announceStmt = '';
          if (this.filterText && this.filterText.length >= 2 &&
            this.ConsultantForm.controls['Search'].value.length > 2) {
              const clientIds = [];
              this.clientRows.forEach(client => {
                clientIds.push({ 'clientId': client.clientId });
            });
            this.announceStmt = 'Loading please wait';
            const body = {
              'searchText': this.filterText,
              'existingClients': clientIds
            };
            this.showClientSpinner = true;
            return this.ConsultantSvc.fetchClients(body);
          }
          if (this.filterText.length <= 2) {
            return of({ clients: [] });
          }
        })
      )
      .subscribe(response => {
        if (this.filterText.length >= 2 && response.clients.length >= 0 &&
          this.ConsultantForm.controls['Search'].value.length > 2) {
            this.canLoad = true;
            this.ELEMENT_DATA = response.clients;
        }
        if ((this.filterText.length < 2 && response.clients.length === 0) ||
          this.ConsultantForm.controls['Search'].value.length < 2) {
            this.canLoad = false;
            this.ELEMENT_DATA = [];
        }
        this.showClientSpinner = false;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.announceStmt = this.ELEMENT_DATA.length > 0 ?
          this.ELEMENT_DATA.length + ' clients found' : 'No records found';
      });
  }

  /**
   * called when a client is selected from the list
   * @param element selected client details
   */
  onClientSelected(element) {
    this.announceStmt = 'Selected' + element.clientName;
    const existingClientRow = this.clientRows.filter(clientRow => {
      if (clientRow.clientId === element.clientId) {
        return clientRow;
      }
    });
    if (!(existingClientRow.length > 0 && this.clientRows && this.clientRows.length > 0)) {
      this.clientRows.push(element);
      this.associatedArray.push(element);
    }
    this.changeStatus();
  }
  /**
   * delete client association
   * @param clientId client to be removed
   */
  removeClient(clientId) {
    this.data.associatedClients.forEach(ele => {
      if (ele.clientId === clientId) {
        this.deAssociatedArray.push(ele);
      }
    });
    this.associatedArray = this.associatedArray.filter(data => data.clientId !== clientId);
    this.clientRows = this.clientRows.filter(data => data.clientId !== clientId);
  }
  /** funtion called on click of ADD button */
  add() {
    this.displayRow = true;
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0);
  }
  /** function called on click of close icon in search term
   * @param evt click event
   */
  closeSearchBar(evt) {
    evt.preventDefault();
    this.changeStatus();
  }
  /** used to clear search bar and hide client list */
  changeStatus() {
    this.ConsultantForm.controls['Search'].setValue(['']);
    this.displayRow = false;
    this.canLoad = false;
  }
  /** destroys the object */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
