import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeeDetails } from '../../../../core/models/employee-details.model';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';
import { Observable, Subscription, debounce, distinctUntilChanged, filter, fromEvent, of, tap, timer } from 'rxjs';

@Component({
  selector: 'app-cartus-employee',
  templateUrl: './cartus-employee.component.html',
  styleUrls: ['./cartus-employee.component.scss']
})
export class CartusEmployeeComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Decorator used to Access EmployeeDetailsComponent elements */
  @ViewChild(EmployeeDetailsComponent, { static: true }) childObj: EmployeeDetailsComponent;
  /** Decorator used to access search element */
  @ViewChild("searchInput") searchInput:ElementRef;
  /** Search Input Keyup event source */
  searchInputKeyupEventSource: Observable<KeyboardEvent>;
  /** Search Input Blur event source */
  searchInputBlurEventSource: Observable<FocusEvent>;
  /** Subscription prop for unsubscribing */
  private readonly subscription: Subscription = new Subscription();
  /** Store the filter value */
  filterText = '';

  /** Base Constructor for dependencies Injection */
  constructor(private  readonly Logger: LoggerService, private readonly loggedInUserService: UserContextService) { }

  /** Initial Initialization */
  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CARTUS_EMPLOYEE', 'CARTUS_EMPLOYEE');
      });
  }

  /** Additional Initialization Tasks */
  ngAfterViewInit(): void {
    this.searchInputKeyupEventSource = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'keyup');
    this.searchInputBlurEventSource = fromEvent<FocusEvent>(this.searchInput.nativeElement, 'blur');
    this.subscription.add(
      this.searchInputKeyupEventSource.pipe(
        debounce(evt => evt.key.toLocaleLowerCase() === 'enter' ? of({}) : timer(1000)),
        filter(() => this.searchInput.nativeElement.value !== this.filterText),
        distinctUntilChanged(),
        tap(() => { this.filterText = this.searchInput.nativeElement.value; })
      ).subscribe(() => { this.applyFilter(this.filterText); })
    );
    this.subscription.add(
      this.searchInputBlurEventSource.pipe(
        filter(() => this.searchInput.nativeElement.value !== this.filterText),
        tap(() => { this.filterText = this.searchInput.nativeElement.value; })
      ).subscribe(() => { this.applyFilter(this.filterText); })
    );
  }

  /**
  * Filtering the Data in Table
  * @param filterValue Holds the Searched value
  */
  applyFilter(filterValue: string) {
    this.childObj.applyFilter(filterValue);
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
