import { Component, OnInit, OnDestroy, HostBinding, ViewEncapsulation, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RoleCapabilityService } from 'src/app/rolecaps/services/role-capability.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { RoleDefinition } from 'src/app/rolecaps/models/definition.model';
import { RoleCapability } from 'src/app/rolecaps/models/rolecapability.model';
import { OperationType } from 'src/app/core/models/OperationType';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Observable, Subscription, from, of, lastValueFrom } from 'rxjs';
import { ADD_ROLE_TITLE, EDIT_ROLE_TITLE, ADD_ROLE_SUCCESS, EDIT_ROLE_SUCCESS, ADD_ROLE_ERROR, EDIT_ROLE_ERROR, ROLE_ALREADY_EXISTS_ERROR,
         GET_DEPENDENT_ROLES_ERROR, GET_ASSOC_ROLECAP_ERROR, ROLE_NAME_VAL_PATTERN, ROLE_NAME_VAL_PATTERN_ERROR, ROLE_NAME_VAL_REQ_ERROR,
         ROLE_NAME_VAL_MIN_LENGTH_ERROR, ROLE_NAME_VAL_MAX_LENGTH_ERROR, FRIENDLY_NAME_VAL_PATTERN, FRIENDLY_NAME_VAL_PATTERN_ERROR,
         FRIENDLY_NAME_VAL_REQ_ERROR, FRIENDLY_NAME_VAL_MIN_LENGTH_ERROR,
         FRIENDLY_NAME_VAL_MAX_LENGTH_ERROR, CONCURRENT_REQUESTS } from 'src/app/rolecaps/models/constants';
import { map, mergeMap, startWith, tap } from 'rxjs/operators';
import * as querystring from 'querystring';


@Component({
  selector: 'app-rolecaps-add-edit-role',
  templateUrl: './rolecaps-add-edit-role.component.html',
  styleUrls: ['./rolecaps-add-edit-role.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RolecapsAddEditRoleComponent implements OnInit, OnDestroy {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-add-edit-role';

  /** Form group name */
  addEditRoleForm: UntypedFormGroup;

  /** Title to display the dialog window page title */
  title: string;

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Dependent Role options */
  dependentRoles: string[] = [];

  /** Processing */
  processing = false;

  /** Dependent roles search view child */
  @ViewChild('search', { static: false }) searchTextBox: ElementRef;

  /** Dependent roles search form control */
  searchTextboxControl = new FormControl();

  /** Dependent roles selected values */
  selectedValues = [];

  /** Dependent roles filtered options*/
  filteredOptions: Observable<any[]> = of([]);

  /** Edit Role Definition */
  editRole: RoleDefinition;

  /** Operation Type enum */
  OperationType = OperationType;

  /** Injecting dependencies */
  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RolecapsAddEditRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.role) {
      this.editRole = this.data.role;
    }
    this.addEditRoleForm = this.formBuilder.group({
      roleName: [
        { value: this.editRole && this.editRole.roleName || '', disabled: this.editRole },
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(ROLE_NAME_VAL_PATTERN)
        ])
      ],
      friendlyName: [
        { value: this.editRole && this.editRole.friendlyName || '', disabled: this.editRole },
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(FRIENDLY_NAME_VAL_PATTERN)
        ])
      ],
      dependencies: [
        { value: this.editRole && this.editRole.dependencies && this.editRole.dependencies.map(dep => dep.roleName) || [], disabled: false }
      ],
      bypassAssociation: [
        { value: this.editRole && this.editRole.hasOwnProperty('bypassAssociation') ?
          this.editRole.bypassAssociation :
            '', disabled: false }
      ],
    });
  }

  /** To initialize the component */
  ngOnInit() {
    this.title = this.editRole ? EDIT_ROLE_TITLE : ADD_ROLE_TITLE;
    setTimeout(() => this.loadDependentRoles());
  }

  /** Get the dependent role options */
  async loadDependentRoles() {
    this.processing = true;
    this.roleCapabilityService.getRoleDefinitions().subscribe({
      next: response => {
        if (response && response.status === 200) {
          const roleDefinitions: RoleDefinition[] = response.body;
          // The user should not be able to select the role itself as its own dependency
          let roleNameFoundIndex: number = roleDefinitions.findIndex(roleDef => roleDef.roleName === this.addEditRoleForm.controls.roleName.value);
          if (roleNameFoundIndex !== -1)  roleDefinitions.splice(roleNameFoundIndex, 1);
          this.dependentRoles = roleDefinitions.map(item => item.roleName).sort();
        } else {
          this.notificationsService.flashNotification('danger', GET_DEPENDENT_ROLES_ERROR);
        }
      },
      error: error => {
        this.loggerService.error('Failed to get dependent roles', error);
        this.notificationsService.flashNotification('danger', GET_DEPENDENT_ROLES_ERROR);
      },
      complete: () => {
        this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(startWith<string>(''), map(name => this.filter(name)));
        this.processing = false;
      }
    });
  }

  /**
   * Custom error messages
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(fieldName) {
    if (fieldName === 'ROLE_NAME') {
      return this.addEditRoleForm.get('roleName').hasError('required')
        ? ROLE_NAME_VAL_REQ_ERROR
        : this.addEditRoleForm.get('roleName').hasError('pattern')
          ? ROLE_NAME_VAL_PATTERN_ERROR
          : this.addEditRoleForm.get('roleName').hasError('minlength')
            ? ROLE_NAME_VAL_MIN_LENGTH_ERROR
            : this.addEditRoleForm.get('roleName').hasError('maxlength')
              ? ROLE_NAME_VAL_MAX_LENGTH_ERROR
              : '';
    }
    if (fieldName === 'FRIENDLY_NAME') {
      return this.addEditRoleForm.get('friendlyName').hasError('required')
        ? FRIENDLY_NAME_VAL_REQ_ERROR
        : this.addEditRoleForm.get('friendlyName').hasError('pattern')
          ? FRIENDLY_NAME_VAL_PATTERN_ERROR
          : this.addEditRoleForm.get('friendlyName').hasError('minlength')
            ? FRIENDLY_NAME_VAL_MIN_LENGTH_ERROR
            : this.addEditRoleForm.get('friendlyName').hasError('maxlength')
              ? FRIENDLY_NAME_VAL_MAX_LENGTH_ERROR
              : '';
    }
    return '';
  }

  /** Create or Update the Role definition */
  async createOrUpdateRole(operationType: OperationType) {
    let completed = 0;
    let allResults = [];
    const allErrors = [];
    try {
      this.processing = true;
      const requests = [];
      const role: RoleDefinition = {
        definitionType: 'role',
        roleName: this.addEditRoleForm.controls.roleName.value,
        friendlyName: this.addEditRoleForm.controls.friendlyName.value
      };
      const dependencies = this.addEditRoleForm.controls.dependencies;
      if (dependencies.value && dependencies.value.length > 0) {
        role.dependencies = dependencies.value.map((item: string) => ({ roleName: item }));
        role.bypassAssociation = this.addEditRoleForm.controls.bypassAssociation.value || false;
      }
      if (operationType === OperationType.update) {
        role.__v = this.editRole.__v;
        requests.push(this.roleCapabilityService.updateDefinition(this.editRole._id, role));
        const queryString = {};
        queryString['roleName'] = role.roleName;
        await lastValueFrom(this.roleCapabilityService.getRoleCapByRoleName(querystring.stringify(queryString))).then(async resp => {
          if (!resp || resp && resp.status !== 200 && !(resp.toString().indexOf('Role Capability not found.') > -1)) {
            throw new Error(GET_ASSOC_ROLECAP_ERROR);
          } else if (resp && resp.body && resp.status === 200) {
              const roleCapability: RoleCapability = resp.body;
              const roleCapabilityId = roleCapability._id;
              delete roleCapability._id;
              roleCapability.dependencies = role.dependencies || [];
              roleCapability.bypassAssociation = role.bypassAssociation || false;
              requests.push(this.roleCapabilityService.updateRoleCapability(roleCapabilityId, roleCapability));
          }
        });
      } else {
        requests.push(this.roleCapabilityService.createDefinition(role));
      }
      from(requests)
        .pipe(mergeMap(observable => observable, CONCURRENT_REQUESTS), tap(() => {
          completed++;
        }))
        .subscribe({
          next: partialResults => {
            allResults = allResults.concat(partialResults);
          },
          error: error => {
            this.loggerService.error(operationType === OperationType.update ? EDIT_ROLE_ERROR : ADD_ROLE_ERROR, error);
            this.notificationsService.flashNotification('danger', this.processError(error, operationType));
          },
          complete: () => {
            allResults.forEach(result => {
              if (result && result.status !== 200) {
                allErrors.push(result);
              }
            });
            if (allErrors.length === 0) {
              this.notificationsService.flashNotification('success',
                operationType === OperationType.update ? EDIT_ROLE_SUCCESS : ADD_ROLE_SUCCESS);
              const respBody = allResults[0].body;
              this.dialogRef.close(respBody);
            } else {
              this.notificationsService.flashNotification('danger', this.processError(allErrors[0], operationType));
            }
            this.processing = false;
          }
        });
      
    } catch (error) {
      this.processing = false;
      this.loggerService.error(operationType === OperationType.update ? EDIT_ROLE_ERROR : ADD_ROLE_ERROR, error);
      this.notificationsService.flashNotification('danger', this.processError(error, operationType));
    }
  }

  /** Translate API errors into user friendly messages */
  processError(err: any, operationType: OperationType): string {
    if (err.toString().indexOf('already exists') > -1) {
      return ROLE_ALREADY_EXISTS_ERROR;
    }
    if (operationType === OperationType.update) {
      return EDIT_ROLE_ERROR;
    } else {
      return ADD_ROLE_ERROR;
    }
  }

  /** Closes the dialog and clears the form */
  cancel() {
    this.addEditRoleForm = this.formBuilder.group({
      roleName: [''],
      friendlyName: [''],
      dependencies: [''],
      bypassAssociation: ['']
    });
    this.dialogRef.close();
  }

  /**
   * Filter dependent role options by search input
   * @param name search input
   */
  filter(name: string): string[] {
    const filterValue = name.toLowerCase();
    this.setSelectedValues();
    this.addEditRoleForm.controls['dependencies'].patchValue(this.selectedValues);
    const filteredList = this.dependentRoles.filter(option => option.toLowerCase().indexOf(filterValue) > -1);
    return filteredList;
  }

  /** Remove from selected values based on uncheck */
  selectionChange(event) {
    if (event.isUserInput && event.source.selected === false) {
      const index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
  }

  /** Set search textbox value as empty while opening selectbox */
  openedChange(e) {
    this.searchTextboxControl.patchValue('');
    if (e === true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /** Clear search textbox value */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /** Set selected values */
  setSelectedValues() {
    if (this.addEditRoleForm.controls['dependencies'].value && this.addEditRoleForm.controls['dependencies'].value.length > 0) {
      this.addEditRoleForm.controls['dependencies'].value.forEach(e => {
        if (this.selectedValues.indexOf(e) === -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /** Set the friendly name on blur when role name is valid */
  roleNameBlur() {
    const roleName = this.addEditRoleForm.controls.roleName;
    const friendlyName = this.addEditRoleForm.controls.friendlyName;
    if (roleName.value && roleName.valid) {
      const newValue = roleName.value.split('-')
        .map((s: string) => s[0].toUpperCase() + s.slice(1).toLowerCase())
        .join(' ');
      friendlyName.patchValue(newValue);
    }
  }

  /** Set the role name on blur when friendly name is valid */
  friendlyNameBlur() {
    const roleName = this.addEditRoleForm.controls.roleName;
    const friendlyName = this.addEditRoleForm.controls.friendlyName;
    if (friendlyName.value && friendlyName.valid) {
      const newValue = friendlyName.value.split(' ')
        .map((s: string) => s.toLowerCase())
        .join('-');
      roleName.patchValue(newValue);
    }
  }

}
