import { Component, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';
import * as constants from '../../models/constants';
import { AppConfigService } from './../../services/app-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  public privacyNotice: string;
  /**property to print copyright string */
  readonly copyright = constants.copyright;

  @HostBinding('class') class = 'app-footer';

  constructor(private readonly appConfig: AppConfigService) { }

  ngOnInit() {
    const loginurl = (this.appConfig.getConfig('login') as string).substring
      (0, (this.appConfig.getConfig('login') as string).indexOf('login'));
    this.privacyNotice = loginurl + 'privacyNotice';
  }

}
