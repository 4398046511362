import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-rolecaps-dashboard',
  templateUrl: './rolecaps-dashboard.component.html',
  styleUrls: ['./rolecaps-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RolecapsDashboardComponent {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-dashboard';

  /** To check for readonly access */
  disable = false;

  constructor() { }

  /** To check for readonly access */
  isDisabled(flag) {
    this.disable = flag;
  }

}
