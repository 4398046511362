import { Injectable } from '@angular/core';
import { UserContext } from '../models/user-context.model';

@Injectable({
    providedIn: 'root'
})
export class BrowserStorageService {
    /**
     * base constructor
     */
    constructor() { }

    /**
    * get service method for a session storage key
    */
     getSessionStorageValue(key: string): any {
        return sessionStorage.getItem(key);
    }

    /**
    * set service method for a session storage key
    */
    setSessionStorageValue(key: string, value: any): any {
        sessionStorage.setItem(key, value);
    }

    /**
    * set service method for a local storage key
    */
    getLocalStorageValue(key: string): any {
        return (JSON.parse(localStorage.getItem(key)));
    }

    /**
    * set service method for a local storage key
    */
    setLocalStorageValue(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
}
