import { Pipe, PipeTransform } from '@angular/core';
import { Person, SelectedEmployee } from '../models/teams.model';

@Pipe({
  name: 'teamMember',
  pure: false
})
export class TeamMemberPipe implements PipeTransform {
  transform(item: Person, filter: Object): any {
    if (!item || !filter) {
        return false;
    }
    const teamOwnerId = filter['team'] && filter['team'].owner ? filter['team'].owner.id : null;
    if (filter['team'] && filter['team'].members && filter['role'] && filter['selectedEmployees']) {
      return (
        filter['team'].members.find((employee: Person) =>
          employee.id === item.id && employee.roles.find(role => role.name === filter['role']) !== undefined
        ) !== undefined ||
        filter['selectedEmployees'].find((employee: SelectedEmployee) =>
          employee.id === item.id && employee.roles.find(role => role.name === filter['role']) !== undefined
        ) !== undefined ||
        teamOwnerId === item.id
      );
    } else if (filter['team'] && !filter['team'].members && filter['role'] && filter['selectedEmployees']) {
      return (
        filter['selectedEmployees'].find((employee: SelectedEmployee) =>
          employee.id === item.id && employee.roles.find(role => role.name === filter['role']) !== undefined
        ) !== undefined ||
        teamOwnerId === item.id
      );
    } else {
      return false;
    }
  }
}
