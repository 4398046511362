<div class="duplicate-candidate">
  <div fxLayout="row" class="snack-container" *ngIf="flag">
    <div fxLayout="column" class="message" fxFlex>
      <div fxLayout="column" class="duplicate" >Duplicate candidate found</div>
      <div fxLayout="column" class="question" >Do you want to continue?</div>
    </div>
    <div class="snack-icons">
      <span>
        <a (click)="cancel()" class="close">
        <mat-icon>close</mat-icon>
        </a>
      </span>
      <span> 
        <a (click)="saveDetails()" class="save">
        <mat-icon >done</mat-icon>
        </a>
      </span>
    </div>
  </div>
  <div fxLayout="row" class="invitation" *ngIf="!flag">
  <app-invitation-sent></app-invitation-sent>
  </div>